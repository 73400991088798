import Axios from "./axios";

export const GetResourceCategoryListAPI = async (onSuccess, onError) => {
    try {
        const response = await Axios.get("/api/resources/get-resource-category", {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const CreateResourceCategoryAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post("/api/resources/create-resource-category", data, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const UpdateCategoryNameAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/api/resources/update-resource-category", data, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const DeleteResourceCaetgoryAPI = async (pdf_category_id, onSuccess, onError) => {
    try {
        const response = await Axios.delete("/api/resources/delete-resource-category?pdf_category_id=" + pdf_category_id, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetCategoryGroupDataAPI = async (pdf_category_id, onSuccess, onError) => {
    try {
        const response = await Axios.get("/api/resources/get-category-group?pdf_category_id=" + pdf_category_id, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const CreateCategoryGroupAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post("/api/resources/create-category-group", data, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const UpdateCategoryGroupNameAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/api/resources/update-category-group", data, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const DeleteResourceGroupCaetgoryAPI = async (params, onSuccess, onError) => {
    try {
        const response = await Axios.delete(
            "/api/resources/delete-resource-category-group?pdf_group_category_id=" + params.pdf_group_category_id + "&flag_delete_all=" + params.flag_delete_all, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetResourceTypeListAPI = async (onSuccess, onError) => {
    try {
        const response = await Axios.get("/api/resources/get-resource-type-list", {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const UploadFileToBucketAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post("/api/resources/upload-resource", data, {
            headers: {
                // "Content-Type": "application/json",
                "Content-Type": "multipart/form-data"
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const AddResourceDataAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post("/api/resources/add-resource-data", data, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetResourceConfigDataAPI = async (onSuccess, onError) => {
    try {
        const response = await Axios.get("/api/resources/get-resource-config-data", {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const RemoveFileAPI = async (google_storage_id, onSuccess, onError) => {
    try {
        const response = await Axios.delete("/api/resources/remove-file?google_storage_id=" + google_storage_id, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const DeleteResourceAPI = async (resource_sub_category_id, onSuccess, onError) => {
    try {
        const response = await Axios.delete(
            "/api/resources/delete-resource?resource_sub_category_id=" + resource_sub_category_id, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const UpdateResourceDataAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/api/resources/update-resource-data", data, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const DragAndDropAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/api/resources/update-resource-sequence", data, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};
export const getResourceFileAPI = async (id, onSuccess, onError) => {
    try {
        const response = await Axios.get(`${process.env.REACT_APP_FILE_URL}?google_storage_id=${id}`, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};