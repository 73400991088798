import React, { Fragment, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Checkbox,
  Grid,
} from "@mui/material";
import { Context } from "../../../../context/V3/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import "./annuityDisclosureForm.css";

const AnnuityDisclosureFormV2 = (props) => {
  const { SetAnnuityDisclosureForm, SetAnnuityDisclosureFormV2 } = useContext(Context);
  const {
    disableAllInputs,
    annuityDisclosureForm,
    annuityDisclosureFormV2,
    flagShowProducerDisclosure
  } = useContext(Context).state;

  const CommonContextUpdate = (type, value) => {
    SetAnnuityDisclosureFormV2({
      type: type,
      value: value,
    });
  };

  const ConditionToShowTransferExchangeForm = () => {

  };

  return (
    <Fragment>
      <Box className="transfer_exchange_request_page_root">
        <Box className="application_form">
          <Box className="application_form_card">
            <Box className="application_form_head">
              <Box>
                <Typography variant="span">
                  {window.location.href?.indexOf('fia_application') > -1 ? "Harvest® Fixed Index Annuity Disclosure" : "Safeguard Plus® Annuity Disclosure"}
                </Typography>
              </Box>
              <Box className="application_form_head_bottom_line">
                <Divider />
              </Box>
            </Box>
            <Box className="section_head_root">
              <Box className="section_open_close">
                <Typography variant="span">
                  Please check the appropriate box: <span style={{ color: "red", fontWeight: "normal" }}>*</span>
                </Typography>
              </Box>
            </Box>
            <Box className="disclosure_form_radio_button">
              <RadioGroup
                name="question_b"
                className="radio_group_shift_right"
                row={true}
                value={annuityDisclosureFormV2.flagAnnuityClosureV2}
                onChange={(e) => {
                  CommonContextUpdate("flagAnnuityClosureV2", e.target.value);
                }}
              >
                <FormControlLabel
                  disabled={disableAllInputs === true ? true : false}
                  control={
                    <Radio
                      value={"qualified annuity"}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  label="Qualified Annuity"
                />
                <FormControlLabel
                  disabled={disableAllInputs === true ? true : false}
                  control={
                    <Radio
                      value={"non - qualified annuity"}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  label="Non - Qualified Annuity"
                />
              </RadioGroup>
            </Box>
            <Box className="annuity_disclosure_desc">
              <Typography className="annuity_disclosure_desc_1" variant="span">
                The {window.location.href?.indexOf('fia_application') > -1 ? "Harvest" : "Safeguard"} Plus Annuity is an individual fixed single-premium
                deferred annuity. You purchase the annuity with a single premium
                payment. Interest is earned during the accumulation phase and
                annuity benefit payments are deferred until the Maturity Date or
                upon Annuitization. Under current tax law: (a) the principal and
                earnings are not subject to income taxes until funds are
                withdrawn or distributed; and (b) a 10% IRS early-withdrawal
                penalty may apply to withdrawals or distributions prior to age
                591⁄2. Tax law is subject to change. Please consult your
                financial or tax professional for any exceptions to the
                early-withdrawal penalty.
              </Typography>
              <Typography className="annuity_disclosure_desc_2_v2" variant="span">
                The main purposes of a deferred annuity are: (a) to save money
                for retirement; and (b) to receive retirement income for life.
                It is not meant for short-term financial goals.
              </Typography>
            </Box>
            <Box className="section_head_root">
              <Box className="section_open_close" sx={{ marginTop: "10px" }}>
                <Typography variant="span">
                  Substitute From W-9
                </Typography>
              </Box>
            </Box>
            <Box className="annuity_disclosure_desc">
              <Typography className="annuity_disclosure_desc_1" sx={{ marginTop: "10px" }} variant="span">
                Under penalties of perjury, I certify that:
              </Typography>
              <Grid container sx={{ marginTop: "5px" }} className="annuity_disclosure_desc_1">
                <Grid item xl={0.4} lg={0.4} md={0.4} sm={0.4} xs={1} marginBottom={"5px"}>
                  <Typography>1.</Typography>
                </Grid>
                <Grid item xl={11.3} lg={11.3} md={11.3} sm={11.3} xs={11} marginBottom={"5px"}>
                  <Typography textAlign="justify">The number shown on the application form is my correct taxpayer identification number (or I am waiting for a number
                    to be issued to me); and</Typography>
                </Grid>
                <Grid item xl={0.4} lg={0.4} md={0.4} sm={0.4} xs={1} marginBottom={"5px"}>
                  <Typography>2.</Typography>
                </Grid>
                <Grid item xl={11.3} lg={11.3} md={11.3} sm={11.3} xs={11} marginBottom={"5px"}>
                  <Typography textAlign="justify"> I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified
                    by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest
                    or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and </Typography>
                </Grid>
                <Grid item xl={0.4} lg={0.4} md={0.4} sm={0.4} xs={1} marginBottom={"5px"}>
                  <Typography>3.</Typography>
                </Grid>
                <Grid item xl={11.3} lg={11.3} md={11.3} sm={11.3} xs={11} marginBottom={"5px"}>
                  <Typography textAlign="justify">I am a U.S. citizen or other U.S. person (as defined by the W-9 instructions).</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className="disclosure_form_radio_button">
              <FormControlLabel
                label={
                  <>
                    <span>
                      Check the box to confirm that the IRS has notified you that you are currently subject to backup withholding because you failed to report interest and dividends on your tax return.
                    </span><span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </>
                }
                sx={{ marginLeft: "4px !important", display: "flex", alignItems: "flex-start" }}
                control={
                  <Checkbox
                    value={annuityDisclosureFormV2.flagAnnuityClosureBackupWithout}
                    checked={annuityDisclosureFormV2.flagAnnuityClosureBackupWithout == "yes" ? true : false}
                    disabled={disableAllInputs === true ? true : false}
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    onChange={(e) => {
                      let newValue
                      if (e.target.checked) {
                        newValue = "yes"
                      } else {
                        newValue = "no"
                      }
                      CommonContextUpdate("flagAnnuityClosureBackupWithout", newValue);
                    }}
                    sx={{ paddingTop: "2px" }}
                  // className="kvlCheck"
                  />
                }
              />
            </Box>
            <Box className="previous_next_btn" style={{ margin: "10px 0" }}>
              <Button
                startIcon={<KeyboardDoubleArrowLeftIcon />}
                onClick={() => {
                  if (ConditionToShowTransferExchangeForm() === false) {
                    props.suitabilityAnalysisPage();
                  } else {
                    props.previousPage();
                  }
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Previous
              </Button>
              {flagShowProducerDisclosure && <Button
                endIcon={<DoubleArrowIcon />}
                className="btn_next"
                onClick={() => {
                  props.nextPage();
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Next - Producer Disclosure
              </Button>}
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default AnnuityDisclosureFormV2;
