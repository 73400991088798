import React, { useEffect } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { DialogTitle, IconButton, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { FocusTrap } from '@mui/base';

const BootstrapDialog = styled(Dialog)(({ theme, borderRadius, overflow, paperMaxwidth, paperMinWidth, height, titleAlignment, titlePadding }) => ({

    "& .MuiPaper-root": {
        borderRadius: borderRadius ?? "4px", // Apply borderRadius
        overflow: overflow ?? "",
        maxWidth: paperMaxwidth ?? "",
        minWidth: paperMinWidth ?? "",
        height: height ?? ""
    },
    // "& .MuiPaper-root button": {
    //     backgroundColor: "unset !important", // Apply borderRadius
    //     color: "unset ",
    //     margin: "unset "
    // },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: 0,
    },

}));



function BootstrapDialogTitle(props) {
    const { title, titleAlignment, flagShowCloseIcon, handleClose, flagBackdrop, titlePadding } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, textAlign: titleAlignment ? titleAlignment : "center", padding: titlePadding ? titlePadding : "" }}>
            <span style={{ color: "rgb(217, 162, 46)" }}>{title}</span>
            {flagShowCloseIcon && handleClose ? (
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute !important',
                        right: title && title.length ? 8 : 0,
                        top: title && title.length ? 8 : 0,
                        color: "black !important",
                        backgroundColor: "unset !important",
                        margin: "unset !important"
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const GlobalPopUp = ({
    title,
    open,
    flagShowCloseIcon,
    handleClose,
    children,
    flagBackdrop,
    borderRadius,
    titleAlignment,
    titlePadding,
    ...dialogProps
}) => {
    const handleBackdropClick = (event) => {
        // Prevent closing when clicking on the backdrop
        if (event.target === event.currentTarget) {
            return;
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        };

        if (open) {
            document.addEventListener('keydown', handleKeyDown);
        }

        // Remove the event listener when the component is unmounted or the dialog is closed
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [open, handleClose]);

    return (
        <FocusTrap open={true} disableEnforceFocus={true}>
            <BootstrapDialog
                open={open}
                onClose={handleClose}
                {...dialogProps}
                disableEscapeKeyDown
                borderRadius={borderRadius}
                titleAlignment={titleAlignment}
                disableEnforceFocus={true}
            >
                {flagBackdrop &&
                    <Backdrop
                        open={open}
                        onClick={handleBackdropClick}
                        sx={{ opacity: "-1 !important" }}
                    >
                        {/* Backdrop content here */}
                    </Backdrop>}
                <BootstrapDialogTitle
                    open={open}
                    title={title}
                    flagShowCloseIcon={flagShowCloseIcon}
                    handleClose={handleClose}
                    titleAlignment={titleAlignment}
                    disableEscapeKeyDown={true}
                    titlePadding={titlePadding}
                />
                {children}
            </BootstrapDialog>
        </FocusTrap>
    );
};

export default GlobalPopUp;
