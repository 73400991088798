import React, { Fragment } from "react";
import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Header from "./components/header/header";
import Register from "./pages/register/register";
import Login from "./pages/login/login";
import AnnuityApplication from "./pages/annuityApplication/annuityApplication";
import Agents from "./pages/agents/agents";
import Home from "./pages/home/home";

import { Provider as NewApplicationFormV1Provider } from "./context/V1/newApplicationFormContext";
import { Provider as ErrorValidationV1Provider } from "./context/V1/errorValidationContext";
import ApplicationV1Form from "./pages/V1/applicationForm/applicationForm";
import ApplicationV3Form from "./pages/V3/applicationForm/applicationForm";
import FiaApplication from "./pages/V3/fiaApplicationForm/FiaApplication";
import FiaAppHistory from "./pages/V3/fiaAnnuityApplication/fiaAnnuityApplication";
import { Provider as NewApplicationFormV3Provider } from "./context/V3/newApplicationFormContext";
import { Provider as ErrorValidationV3Provider } from "./context/V3/errorValidationContext";
import "./app.css";
import UploadTool from "./pages/uploadTool/uploadTool";
import Calculator from "./pages/product/calculator";
import Calc_history from "./pages/product/calc_history";

import Admin from "./pages/product/admin_history/admin";
import PdfConfig from "./pages/product/pdfCondfig/pdf_config";
import Category from "./pages/product/pdfCondfig/category";
import Group from "./pages/product/pdfCondfig/group";
import View_Calculation from "./pages/product/calculator_view";
import ResourceOrder from "./pages/product/pdfCondfig/resource_order";
import ViewResource from "./components/V3/ViewResource";
import { Announcement } from "./pages/announcement/announcement";
import AgentProfile from "./pages/agents/profile";

const Routes1 = (props) => {

  if (props === undefined) {
    return (
      <Fragment>
        <Header />
      </Fragment>
    );
  }

  const CheckFormTypeToRenderOnQuryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const flagNewapplication = searchParams.get("flag_new_application");
    const searchParamsId = searchParams.get("id");

    if (flagNewapplication !== null && searchParamsId !== null) {
      if (flagNewapplication == "true") {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  const userNotLoggedIn = [
    {
      id: 1,
      path: "/register",
      exact: true,
      element: <Register />,
    },
    {
      id: 2,
      path: "/login",
      exact: true,
      element: <Login />,
    },
    {
      id: 3,
      path: "/forgot-password",
      exact: true,
      element: <Login />,
    },
    {
      id: 4,
      path: "/resetPassword",
      element: <Login />,
    },
  ];

  const AdminRoutes = [
    {
      id: 5,
      path: "/myga_application_history",
      exact: true,
      element: <AnnuityApplication role={props.userTypeState} />,
    },
    {
      id: 6,
      path: "/agents",
      exact: true,
      element: <Agents />,
    },
    {
      id: 7,
      path: "/myga_application",
      element: CheckFormTypeToRenderOnQuryParams() ? <ApplicationV3Form /> : CheckFormTypeToRenderOnQuryParams() === false ?
        <ApplicationV1Form /> : <Navigate to="/myga_application_history" />,
    },
    {
      id: 8,
      path: "/myga_application_history",
      exact: true,
      element: <AnnuityApplication role={props.userTypeState} />,
    },
    {
      id: 9,
      path: "/",
      exact: true,
      element: <Home />,
    },
    {
      id: 10,
      path: "/upload-tool",
      exact: true,
      element: <UploadTool />,
    },
    {
      id: 11,
      path: "/Product-Illustration",
      exact: true,
      element: <Calculator />,
    },
    {
      id: 12,
      path: "/history",
      exact: true,
      element: <Calc_history />,
    },
    {
      id: 13,
      path: "/calculator-config",
      exact: true,
      element: <Admin role={props.userTypeState} />,
    },
    {
      id: 14,
      path: "/pdf-config",
      exact: true,
      element: <PdfConfig />,
    },
    {
      id: 15,
      path: "/category",
      exact: true,
      element: <Category />,
    },
    {
      id: 14,
      path: "/View_calculation",
      exact: true,
      element: <View_Calculation />,
    },
    {
      id: 15,
      path: "/Group",
      exact: true,
      element: <Group />,
    },
    {
      id: 16,
      path: "/resource_order",
      exact: true,
      element: <ResourceOrder />,
    },
    {
      id: 17,
      path: "/fia_application",
      exact: true,
      element: <FiaApplication />,
    },
    {
      id: 18,
      path: "/fia_application_history",
      exact: true,
      element: <FiaAppHistory />,
    },
    {
      id: 19,
      path: "/view-resource",
      // exact: "true",
      element: <ViewResource />,
    },
    {
      id: 20,
      path: "/announcement",
      // exact: "true",
      element: <Announcement />
    },
    {
      id: 21,
      path: "/profile",
      // exact: "true",
      element: <AgentProfile />,
    },
    {
      id: 22,
      path: "*",
      exact: "true",
      element: <Navigate to="/" />,
    },

  ];

  const AgentRoute = [
    {
      id: 5,
      path: "/myga_application_history",
      exact: true,
      element: <AnnuityApplication role={props.userTypeState} />,
    },
    {
      id: 7,
      path: "/myga_application",
      element: CheckFormTypeToRenderOnQuryParams() ? <ApplicationV3Form /> : CheckFormTypeToRenderOnQuryParams() === false ?
        <ApplicationV1Form /> : <Navigate to="/myga_application_history" />,
    },
    {
      id: 9,
      path: "/",
      exact: true,
      element: <Home />,
    },
    {
      id: 10,
      path: "/upload-tool",
      exact: true,
      element: <UploadTool />,
    },
    {
      id: 11,
      path: "/Product-Illustration",
      exact: true,
      element: <Calculator />,
    },
    {
      id: 12,
      path: "/history",
      exact: true,
      element: <Calc_history />,
    },
    {
      id: 14,
      path: "/View_calculation",
      exact: true,
      element: <View_Calculation />,
    },
    {
      id: 17,
      path: "/fia_application",
      exact: true,
      element: <FiaApplication />,
    },
    {
      id: 18,
      path: "/fia_application_history",
      exact: true,
      element: <FiaAppHistory />,
    },
    {
      id: 19,
      path: "/view-resource",
      // exact: "true",
      element: <ViewResource />,
    },
    {
      id: 20,
      path: "/profile",
      // exact: "true",
      element: <AgentProfile />,
    },
    {
      id: 13,
      path: "*",
      exact: "true",
      element: <Navigate to="/" />,
    },
  ];

  const IsHeaderVisible = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("google_storage_id")) {
      return false
    } else {
      return true
    }
  }

  if (props.flagAuthorised === false) {
    return (
      <>
        <Header />
        <NewApplicationFormV1Provider>
          <ErrorValidationV1Provider>
            <Routes>
              {userNotLoggedIn.map((v) => {
                return (
                  <Route
                    path={v?.path}
                    exact={v?.exact}
                    key={v?.id}
                    element={v?.element}
                  />
                );
              })}
            </Routes>
          </ErrorValidationV1Provider>
        </NewApplicationFormV1Provider>
      </>
    );
  } else if (
    props.flagAuthorised === true &&
    props.userTypeState.includes("Admin")
  ) {
    return (
      <>
        {IsHeaderVisible() ? <Header /> : ""}
        <NewApplicationFormV1Provider>
          <NewApplicationFormV3Provider>
            <ErrorValidationV1Provider>
              <ErrorValidationV3Provider>
                <Routes>
                  {AdminRoutes.map((v1) => {
                    return (
                      <Route
                        path={v1?.path}
                        exact={v1?.exact}
                        key={v1?.id}
                        element={v1?.element}
                      />
                    );
                  })}
                </Routes>
              </ErrorValidationV3Provider>
            </ErrorValidationV1Provider>
          </NewApplicationFormV3Provider>
        </NewApplicationFormV1Provider>
      </>
    );
  } else if (
    props.flagAuthorised === true &&
    props.userTypeState.includes("Agent")
  ) {
    return (
      <>
        {IsHeaderVisible() ? <Header /> : ""}
        <NewApplicationFormV1Provider>
          <NewApplicationFormV3Provider>
            <ErrorValidationV1Provider>
              <ErrorValidationV3Provider>
                <Routes>
                  {AgentRoute.map((v2) => {
                    return (
                      <>
                        <Route
                          path={v2?.path}
                          exact={v2?.exact}
                          key={v2?.id}
                          element={v2?.element}
                        />
                      </>
                    );
                  })}
                </Routes>
              </ErrorValidationV3Provider>
            </ErrorValidationV1Provider>
          </NewApplicationFormV3Provider>
        </NewApplicationFormV1Provider>
      </>
    );
  }
};

export default Routes1;
