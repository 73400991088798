import createDataContext from './createDataContext';

const UserLoaderReducer = (state, action) => {
    // console.log("@@@@@@@",userType)
    switch (action.type) {
        case "changeUser": {
            return { ...state, user: action.payload }
        }

        case "changeFlagLoader": {
            return { ...state, flagLoader: action.payload }
        }

        case "changeUserType": {
            return { ...state, userType: action.payload }
        }

        case "announcementData": {
            return { ...state, announcementData: action.payload }
        }

        case "selectedAnmtData": {
            return { ...state, selectedAnmtData: action.payload }
        }

        default: {
            return state;
        }
    }
};

const SetUser = dispatch => (data) => {
    console.log("Printing data for setUser", data);
    dispatch({
        type: "changeUser",
        payload: data
    })
};

const SetFlagLoader = dispatch => (data) => {
    dispatch({
        type: "changeFlagLoader",
        payload: data
    })
};

const SetUserType = dispatch => (data) => {
    console.log("Printing user type before updating it", data);
    // debugger;
    dispatch({
        type: "changeUserType",
        payload: data
    })
}

const SetAnnouncementData = dispatch => (data) => {
    // debugger;
    dispatch({
        type: "announcementData",
        payload: data
    })
}

const SetSelectedAnmtData = dispatch => (data) => {
    dispatch({
        type: "selectedAnmtData",
        payload: data
    })
}

export const { Context, Provider } = createDataContext(
    UserLoaderReducer,
    { SetUser, SetFlagLoader, SetUserType, SetAnnouncementData, SetSelectedAnmtData },
    { user: {}, flagLoader: false, userType: "", announcementData: [], selectedAnmtData : {} }
)