import { Box, Button } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    sliderContainer: {
        position: 'relative',
        paddingBottom: '20px',
        '& .slick-dots': {
            bottom: '-25px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'auto',
            '& li': {
                margin: '0 -2px',
                borderLeft: "none",
                '& button:before': {
                    fontSize: '35px',
                    color: '#D3D3D3',
                    opacity: 1
                },
                '&.slick-active button:before': {
                    color: '#D9A22E',
                    opacity: 1
                }
            }
        },
        '& .slick-prev, & .slick-next': {
            display: 'none !important'
        }
    },
    slideContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        padding: '0 10px'
    },
    imageContainer: {
        borderRadius: '5px',
        overflow: 'hidden',
        position: 'relative'
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        // marginTop: '10px'
    },
    customButton: {
        backgroundColor: '#D9A22E !important',
        color: 'white !important',
        textTransform: 'none !important',
        padding: '8px 16px !important',
        borderRadius: '5px !important',
        fontWeight: "400 !important",
        fontSize: "15px !important"
    }
});

const Item = ({ item }) => {
    const classes = useStyles();

    return (
        <div className={classes.slideContent}>
            <Box className={classes.imageContainer}>
                <img
                    style={{
                        width: '100%',
                        height: '15vh',
                        // objectFit: 'cover'
                    }}
                    src={`${process.env.REACT_APP_API_CALL_URL}/${item?.file_name}`}
                    alt={item?.announcement_title}
                />
            </Box>
            <div className={classes.buttonContainer}>
                <Button
                    disableElevation
                    variant='contained'
                    className={classes.customButton}
                    onClick={() => { window.open(item?.announcement_link, '_blank') }}
                    endIcon={<ArrowForwardIcon />}
                >
                    {item?.announcement_title}
                </Button>
            </div>
        </div>
    );
};

const AnnouncementSlider = ({ announcementData }) => {
    const classes = useStyles();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        adaptiveHeight: true,
        className: "center",
        centerMode: true,
        centerPadding: "0px"
    };

    return (
        <Box className={classes.sliderContainer}>
            <Slider {...settings}>
                {announcementData.map((item, i) => (
                    <Item key={i} item={item} />
                ))}
            </Slider>
        </Box>
    );
};

export default AnnouncementSlider;