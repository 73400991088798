import { Alert, Box, Button, Grid, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Delete from "../../assets/delete.svg";
import Edit from "../../assets/edit.svg";
import './announcement.css'
import plusIcon from "../../assets/plusIcon.svg"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AnnouncementAddEditDialog } from '../../components/Announcement/AnnouncementAddEditDialog';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/userLoaderContext';
import { GetAllAnnouncementsAPI, CreateNewAnnouncementAPI, UpdateAnnouncementAPI, DeleteAnnouncementAPI } from '../../api/announcementAPI';
import AnnouncementDeleteDialog from '../../components/Announcement/AnnouncementDeleteDialog';

export const Announcement = () => {
    const navigate = useNavigate()
    const [flagOpenAddAnnouncement, setFlagOpenAddAnnouncement] = useState(false);
    const [flagDeleteAnnouncement, setFlagDeleteAnnouncement] = useState(false);
    const { state, SetAnnouncementData, SetFlagLoader, SetSelectedAnmtData } = useContext(Context);
    // const [selectedAnmtData, setSelectedAnmtData] = useState({});
    const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
    const [errors, setErrors] = useState({
        errorMessage: "",
        errorStatus: false
    });

    useEffect(() => {
        GetAllAnnouncementsAPI((res) => {
            if (res?.data?.data?.length > 0) {
                SetAnnouncementData(res?.data?.data)
            }
        }, (err) => {
            SetFlagLoader(false);
            console.log("errr", err)
        })
    }, [])

    const handleAnnouncementPopupClose = () => {
        setFlagOpenAddAnnouncement(false)
        SetSelectedAnmtData({})
        setErrors({
            errorMessage: "",
            errorStatus: false
        })
    }

    const handleDeleteAnnouncementPopupClose = () => {
        setFlagDeleteAnnouncement(false)
        SetSelectedAnmtData({})
    }

    const handleCreateNewAnnouncement = (formData) => {
        if (!formData?.file || !formData?.announcement_title || !formData?.announcement_link) {
            setErrors({
                errorMessage: "Please fill in all the fields!",
                errorStatus: true
            })
            return
        }
        const payload = new FormData();
        payload.append('file', formData?.file);
        payload.append('announcement_title', formData?.announcement_title);
        payload.append('announcement_link', formData?.announcement_link);
        SetFlagLoader(true)
        CreateNewAnnouncementAPI(payload, (res) => {
            if (res?.status) {
                setSnackbar({
                    open: true,
                    message: "Announcement created successfully!",
                    severity: "success"
                });
                GetAllAnnouncementsAPI((res) => {
                    if (res?.status) {
                        if (res?.data?.data?.length > 0) {
                            SetAnnouncementData(res?.data?.data)
                        }
                    } else {
                        setSnackbar({
                            open: true,
                            message: res?.message || "Something went wrong!",
                            severity: "error"
                        });
                    }
                }, (err) => {
                    console.log("errr", err);
                })
            } else {
                setSnackbar({
                    open: true,
                    message: res?.message || "Something went wrong!",
                    severity: "error"
                });
            }
            SetFlagLoader(false)
            setFlagOpenAddAnnouncement(false)
        },
            (err) => {
                console.log(err);
            }
        )
    }

    const handleEditAnnouncement = (formData) => {
        const payload = new FormData();
        if (formData.file) {
            payload.append('file', formData?.file);
        }
        payload.append('announcement_title', formData?.announcement_title);
        payload.append('announcement_link', formData?.announcement_link);
        payload.append('announcement_id', formData?.announcement_id);
        const obj = {
            id: formData?.announcement_id,
            data: payload
        }
        SetFlagLoader(true)
        UpdateAnnouncementAPI(obj, (res) => {
            if (res?.status) {
                GetAllAnnouncementsAPI((res) => {
                    if (res?.status) {
                        if (res?.data?.data?.length > 0) {
                            SetAnnouncementData(res?.data?.data);
                        }
                    } else {
                        setSnackbar({
                            open: true,
                            message: res?.message || "Something went wrong!",
                            severity: "error"
                        });
                    }
                }, (err) => {
                    console.error("Error fetching announcements:", err);
                });
                setSnackbar({
                    open: true,
                    message: "Announcement updated successfully!",
                    severity: "success"
                });
            } else {
                setSnackbar({
                    open: true,
                    message: res?.message || "Something went wrong!",
                    severity: "error"
                });
            }
            SetFlagLoader(false);
            setFlagOpenAddAnnouncement(false);
        }, (err) => {
            SetFlagLoader(false)
            console.error("Error updating announcement:", err);
        });
    };

    const handleDeleteAnnouncement = (formData) => {
        SetFlagLoader(true)
        DeleteAnnouncementAPI(formData, (res) => {
            if (res?.status) {
                SetAnnouncementData(res?.data?.data)
                setSnackbar({
                    open: true,
                    message: res?.message || "Announcement deleted successfully!",
                    severity: "success"
                });
            } else {
                setSnackbar({
                    open: true,
                    message: res?.message || "Something went wrong!",
                    severity: "error"
                });
            }
            SetFlagLoader(false)
            setFlagDeleteAnnouncement(false);

        },
            (err) => {
                SetFlagLoader(false)
                console.log(err)
            }
        )
    }

    const handleCloseSnackbar = () => {
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    return (
        <>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={1500}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <AnnouncementAddEditDialog
                handleClose={handleAnnouncementPopupClose}
                handleCreateNewAnnouncement={handleCreateNewAnnouncement}
                handleEditAnnouncement={handleEditAnnouncement}
                flagOpenAddAnnouncement={flagOpenAddAnnouncement}
                // selectedAnmtData={selectedAnmtData}
                errors={errors}
                setErrors={setErrors}
            />
            <AnnouncementDeleteDialog
                handleClose={handleDeleteAnnouncementPopupClose}
                handleDeleteAnnouncement={handleDeleteAnnouncement}
                flagDeleteAnnouncement={flagDeleteAnnouncement}
            // selectedAnmtData={selectedAnmtData}
            />
            <Box className="page_title_annuity_application page_title">
                <Typography variant="span">Announcement</Typography>
            </Box>
            <Box sx={{ marginTop: "20px" }} className="main_container_announcement">
                <Box className="Grid_box_Pdf_config">
                    <Grid container>
                        <Grid item xs={12} marginTop={"10px"}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        disableElevation

                                        startIcon={<ArrowBackIcon />}
                                        onClick={() => {
                                            navigate(-1)
                                        }}
                                        className="announcement_back_btn"
                                    >
                                        Back
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        startIcon={<img src={plusIcon} />}
                                        onClick={() => {
                                            setFlagOpenAddAnnouncement(true);
                                            SetSelectedAnmtData({})
                                        }}
                                        className="resource_btns announcement_btn"
                                    >
                                        New Announcement
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer style={{ maxHeight: "70vh" }} >
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" className="resourceTable" stickyHeader>
                                    <TableHead>
                                        <TableRow className="AnnResourceTableHeaderRow">
                                            <TableCell align="left">Image</TableCell>
                                            <TableCell align="left">Title</TableCell>
                                            <TableCell align="left">Website Link</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state?.announcementData?.length ?
                                            state?.announcementData?.map((imgData, i) => {
                                                return (
                                                    <>
                                                        <TableRow key={i} className="AnnResourceTableRow">
                                                            <TableCell align="left">
                                                                <img width={"150px"} height={"30px"} src={`https://api.farmers.instabrain.io/${imgData?.file_name}`} />
                                                            </TableCell>
                                                            <TableCell align="left">{imgData.announcement_title}</TableCell>
                                                            <TableCell align="left">
                                                                <span
                                                                    style={{ color: "rgba(45, 90, 205, 1)", textDecoration: "underline", cursor: "pointer" }}
                                                                    onClick={() => { window.open(imgData.announcement_link) }}
                                                                >
                                                                    Link
                                                                </span>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <img
                                                                    src={Edit}
                                                                    width="20px"
                                                                    height="20px"
                                                                    style={{
                                                                        marginRight: "7px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => { setFlagOpenAddAnnouncement(true); SetSelectedAnmtData(imgData) }}
                                                                />

                                                                <img
                                                                    src={Delete}
                                                                    width="20px"
                                                                    height="20px"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => { setFlagDeleteAnnouncement(true); SetSelectedAnmtData(imgData) }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                )
                                            })
                                            :
                                            <TableRow className="resourceTableRow">
                                                <TableCell align="center" colSpan={6}>No data available</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}
