import { Button, Dialog, DialogContent, Grid, TextField } from '@mui/material'
import React, { useContext } from 'react'
import warningIcon from "../../assets/warningIcon.png";
import { Context } from '../../context/userLoaderContext';

const AnnouncementDeleteDialog = ({ handleDeleteAnnouncement, handleClose, flagDeleteAnnouncement }) => {
    const {state} = useContext(Context)
    return (
        <>
            <Dialog
                open={flagDeleteAnnouncement}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "10px", padding: "12px" } }}
            >
                <DialogContent>
                    <Grid container justifyContent={"center"} gap={2}>
                        <Grid item xs={10} sx={{ fontWeight: 500 }}>
                            Delete Announcement
                        </Grid>
                        <Grid item xs={10}>
                            <img
                                src={warningIcon}
                                alt='warning icon'
                            />
                        </Grid>
                        <Grid item xs={10}>
                            Are you sure you want to delete <span style={{ color: "#d9a22e", fontWeight: 500 }}>{state?.selectedAnmtData?.announcement_title}</span>?
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container justifyContent="center">
                                <Grid item xs={4} textAlign={"end"} mr={1}>
                                    <Button
                                        variant='outlined'
                                        sx={{ backgroundColor: "white !important", color: "#d9a22e !important", borderColor: "#d9a22e !important" }}
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>
                                </Grid>
                                <Grid item xs={4} textAlign={"start"}>
                                    <Button
                                        onClick={() => handleDeleteAnnouncement(state?.selectedAnmtData)}
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AnnouncementDeleteDialog
