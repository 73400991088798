import React, { useEffect, useState, useContext } from "react";
import "./FileUpload.css";
import axios from "axios";
import PDF2Icon from "../../../assets/pdf_2.svg";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PropTypes from "prop-types";
import { Context as userLoaderContext } from "../../../context/userLoaderContext";
import { RemoveFileAPI, UploadFileToBucketAPI } from "../../../api/resourceConfig";

const FileUpload = (props) => {
  const { setAddEditPopupDetails, addEditPopupDetails, setRemovedFileIds, setShowMsg } = props
  const { state } = useContext(userLoaderContext);
  const { SetFlagLoader } = useContext(userLoaderContext);
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({ filename: "", percent: 0 });

  const uploadHandler = (event) => {
    SetFlagLoader(true);

    const file = event.target.files[0];
    if (!file) return;
    console.log("1");
    file.isUploading = true;

    const formData = new FormData();
    // setProgress({filename:file.name, percent:0})
    formData.append("resource", file, file.name);
    const config = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        var percent = Math.floor((loaded * 100) / total);
        setProgress({ ...progress, percent: percent, filename: file.name });
        console.log("progress", [progress, setProgress]);
      },
    };

    UploadFileToBucketAPI(formData, (res) => {
      if (res.data.status) {
        console.log("upload res::", res)
        let fileName = file?.name?.split(".")[0]
        setFiles([file]);
        setAddEditPopupDetails(old => {
          let tempList = { ...old }
          tempList.google_storage_id = res.data.data[0].google_storage_id
          tempList.google_storage_original_file_name = fileName
          return tempList
        })
        setShowMsg({
          status: false,
          error: false,
          msg: "",
        });
      } else {
        setShowMsg({
          status: false,
          error: true,
          msg: res?.data?.error?.message,
        });
      }
      SetFlagLoader(false);
    }, (err) => {
      SetFlagLoader(false);
      setShowMsg({
        status: false,
        error: true,
        msg: "Something went wrong!",
      });
      console.log("upload err::", err)
    })

  };

  var finalObj = [];
  return (
    <>
      <Grid container>
        <Grid item className="file-card ">
          {addEditPopupDetails.google_storage_id == "" || addEditPopupDetails.google_storage_id == null ? (
            <Grid container>
              <Grid item className="file-inputs">
                <input
                  type="file"
                  // multiple
                  accept=".pdf"
                  className="file-inputs"
                  onChange={(e) => {
                    uploadHandler(e);
                  }}
                ></input>

                <button
                  className="file-inputs"
                  style={{
                    color: "#000000",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => { }}
                >
                  <FileUploadOutlinedIcon />
                  <p className="main">
                    Drag & Drop files here, or{" "}
                    <span style={{ color: "#2765FF" }}>Browse</span>
                  </p>
                </button>
              </Grid>
            </Grid>
          ) : null}

          <ul className="file-list" style={{ marginTop: "-20px" }}></ul>

          {(addEditPopupDetails.google_storage_id != "" && addEditPopupDetails.google_storage_id != null && addEditPopupDetails.popupType == "Add") &&
            files.map((a, i) => {
              const obj = {
                lastModified: a.lastModified,
                name: a.name,
                size: a.size,
                type: a.type,
                isUploading: true,
                progressPerc: 0.2,
              };
              finalObj.push(obj);

              return (
                <Grid Container spacing={2}>
                  <Grid item xs={12} className="file_item">
                    <Grid Container>
                      <Grid item direction="column">
                        <Grid container className="delete_container">
                          <Grid item xs={2} className="pdf_icon">
                            <img src={PDF2Icon} width="80px" height="30px" />
                          </Grid>

                          <Grid item xs={9} className="file_name">
                            <span
                              className="file_name_txt"
                              style={{ color: "black" }}
                            >
                              {a.name}{" "}
                            </span>
                          </Grid>

                          <Grid item xs={1} className="delete_icon">
                            <CloseIcon
                              className="delete_onclick"
                              onClick={() => {
                                SetFlagLoader(true)
                                RemoveFileAPI(addEditPopupDetails?.google_storage_id,
                                  (res) => {
                                    console.log("res", res);
                                    if (res.data.status) {
                                      setAddEditPopupDetails(old => {
                                        let tempList = { ...old }
                                        tempList.google_storage_id = ""
                                        return tempList
                                      })
                                    }
                                    SetFlagLoader(false)
                                  },
                                  (err) => {
                                    console.log("err", err);
                                    SetFlagLoader(false)
                                  }
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Box sx={{ width: "100%" }}></Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          {(addEditPopupDetails?.google_storage_id != "" && addEditPopupDetails?.google_storage_id != null && addEditPopupDetails?.popupType == "Edit") &&
            <Grid Container spacing={2}>
              <Grid item xs={12} className="file_item">
                <Grid Container>
                  <Grid item direction="column">
                    <Grid container className="delete_container">
                      <Grid item xs={2} className="pdf_icon">
                        <img src={PDF2Icon} width="80px" height="30px" />
                      </Grid>

                      <Grid item xs={9} className="file_name">
                        <span
                          className="file_name_txt"
                          style={{ color: "black" }}
                        >
                          {addEditPopupDetails.google_storage_original_file_name}
                        </span>
                      </Grid>

                      <Grid item xs={1} className="delete_icon">
                        <CloseIcon
                          className="delete_onclick"
                          onClick={() => {
                            // SetFlagLoader(true)
                            setRemovedFileIds(old => {
                              let temp = [...old]
                              temp.push(addEditPopupDetails?.google_storage_id)
                              return temp
                            })
                            setAddEditPopupDetails(old => {
                              let tempList = { ...old }
                              tempList.google_storage_id = ""
                              return tempList
                            })
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Box sx={{ width: "100%" }}></Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </>
  );
};

export default FileUpload;