import { Alert, Button, Dialog, DialogContent, Grid, TextField } from '@mui/material'
import React, { useContext } from 'react'
import FileUploadComponent from './FileUploadComponent';
import './FileUpload.css'
import { Context } from '../../context/userLoaderContext';

export const AnnouncementAddEditDialog = ({
  handleClose,
  flagOpenAddAnnouncement,
  handleEditAnnouncement,
  handleCreateNewAnnouncement,
  errors,
  setErrors
}) => {
  const { state, SetSelectedAnmtData } = useContext(Context);

  const handleInputChange = (key, value) => {
    SetSelectedAnmtData({
      ...state.selectedAnmtData,
      [key]: value,
    });
  };

  const handleFileClear = () => {
    SetSelectedAnmtData({
      ...state.selectedAnmtData,
      file: null,
      file_name: null // Also clear the file_name if it exists
    });
  };

  const handleSubmit = () => {
    // if (Object.keys(state?.selectedAnmtData).length > 0) {
    //   handleEditAnnouncement(state.selectedAnmtData);
    // }
    if (state?.selectedAnmtData?.announcement_id) {
      handleEditAnnouncement(state.selectedAnmtData);
    }
    else {
      handleCreateNewAnnouncement(state.selectedAnmtData);
    }
  };

  return (
    <Dialog
      open={flagOpenAddAnnouncement}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { borderRadius: "10px", padding: "12px", flexDirection: "row" } }}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container justifyContent={"center"} gap={2}>
          {errors.errorMessage && (
            <Grid item xs={8}>
              <Alert severity="error" className='announcementAlert'>{errors.errorMessage}</Alert>
            </Grid>
          )}
          <Grid item xs={8} sx={{ color: "#d9a22e !important", fontWeight: 500 }}>
            {state?.selectedAnmtData?.announcement_id ? "Edit Announcement" : 'Add New Announcement'}
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Title"
              variant="standard"
              value={state?.selectedAnmtData?.announcement_title || ''}
              onChange={(e) => handleInputChange('announcement_title', e.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Website Link"
              variant="standard"
              value={state?.selectedAnmtData?.announcement_link || ''}
              onChange={(e) => handleInputChange('announcement_link', e.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container direction={"column"}>
              <Grid item textAlign={"start"}>
                <span style={{ color: '#666666' }}>Image</span>
                <span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid item marginTop={"5px"}>
                <FileUploadComponent
                  onFileSelect={(e) => handleInputChange('file', e.target.files?.[0])}
                  handleFileClear={handleFileClear}
                  setErrors={setErrors}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container justifyContent="center">
              <Grid item xs={4} textAlign={"end"} mr={1}>
                <Button
                  variant='outlined'
                  sx={{ backgroundColor: "white !important", color: "#d9a22e !important", borderColor: "#d9a22e !important" }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs={4} textAlign={"start"}>
                <Button onClick={handleSubmit}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};