import { Grid } from "@mui/material";
import "./FileUpload.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ClearIcon from '@mui/icons-material/Clear';
import { useContext } from "react";
import { Context } from "../../context/userLoaderContext";

const FileUploadComponent = ({ onFileSelect, handleFileClear, selectedAnmtData, formData, setErrors }) => {
    const { state } = useContext(Context)

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;

        if (files?.length > 0) {
            const file = files[0];
            // Validate file type
            if (!file.type.startsWith("image/")) {
                setErrors({
                    errorMessage: "Please select a valid file type!",
                    errorStatus: true
                })
                return;
            } else {
                setErrors({
                    errorMessage: "",
                    errorStatus: false
                })
            }
            onFileSelect({ target: { files } }); // Simulate input change event
        }
    };

    return (
        <>
            <div className="file-upload-container">
                {state?.selectedAnmtData && (state?.selectedAnmtData?.file_name || state?.selectedAnmtData?.file?.name) ?
                    <>
                        <Grid container justifyContent="space-between" alignItems="center" padding={"20px 5px"}>
                            <Grid item>
                                <Grid container direction="column" width={"50px"}>
                                    <Grid item>
                                        {state?.selectedAnmtData?.file_name && <img width={"150px"} height={"30px"} src={`https://api.farmers.instabrain.io/${state?.selectedAnmtData?.file_name}`} />}
                                    </Grid>
                                    <Grid item>
                                        <div style={{ width: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {state?.selectedAnmtData?.file_name || state?.selectedAnmtData?.file?.name}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <ClearIcon onClick={handleFileClear} />
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        <div
                            className="file-drop-zone"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleDrop}
                        >
                            <input
                                type="file"
                                id="file-input"
                                className="file-input"
                                onChange={onFileSelect}
                                accept="image/*"
                            />
                            <label htmlFor="file-input" className="file-upload-label">
                                <FileUploadOutlinedIcon className="upload-icon" />
                                <p className="upload-text">
                                    Drag & Drop files here, or <span className="browse-link">Browse</span>
                                </p>
                            </label>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default FileUploadComponent;