import axiosInstance from "./axios";


export const GetAllAnnouncementsAPI = async (onSuccess, onError) => {
    try {
        const response = await axiosInstance.get("/api/global/announcements", {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}

export const CreateNewAnnouncementAPI = async (data, onSuccess, onError) => {
    try {
        const response = await axiosInstance.post("/api/global/announcements", data, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}

export const UpdateAnnouncementAPI = async (data, onSuccess, onError) => {
    try {
        const response = await axiosInstance.put(`/api/global/announcements/?id=${data?.id}`, data?.data, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}

export const DeleteAnnouncementAPI = async (data, onSuccess, onError) => {
    try {
        const response = await axiosInstance.delete(`/api/global/announcements/?id=${data?.announcement_id}`, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}