import React, { Fragment, useState, useContext, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import IndividualAnnuityApplication from "../../../components/V3/individualAnnuityApplication/individualAnnuityApplication";
import SuitabilityAnalysis from "../../../components/V3/suitabilityAnalysis/suitabilityAnalysis";
import FormSiteMap from "../../../components/V3/individualAnnuityApplication/formSiteMap";
import { ApplicationStatusId } from "../../../api/applicationStatusIdAndIdAPI";
import TransferExchangeRequest from "../../../components/V3/transferOrExchangeRequest/transferExchangeRequest";
import RolloverTransferExchangeRequest from "../../../components/V3/rolloverTransferOrExchangeRequest/rolloverTransferExchangeRequest";
import { Context } from "../../../context/V3/newApplicationFormContext";
import SuccessDdialog from "../../../components/successDialog/successDialog";
import { Context as UserLoaderContext } from "../../../context/userLoaderContext";
import ErrorValidationSubmitDialog from "../../../components/V3/errorValidationSubmitDialog/errorValidationSubmitDialog";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Context as ErrorValidationContext } from "../../../context/V3/errorValidationContext";
import AnnuityDisclosureForm from "../../../components/V3/annuityDisclosureForm/annuityDisclosureForm";
import SomethingWentWrongDialog from "../../../components/somethingWentWrong/somethingWentWrong";
import ReplacementForm from "../../../components/V3/replacementForm/replacementForm";
import { GetSingleIaaDataAPI, SubmitSaveDraftIaaApplication } from "../../../api/V3/iaaApplicationAPI";
import VerifyPhoneDialog from "../../../components/VerifyPhoneDialog/VerifyPhoneDialog";
import AnnuityDisclosureFormV2 from "../../../components/V3/annuityDisclosureForm/V2/annuityDisclosureFormV2";
import ProducerDisclosureForm from "../../../components/V3/producerDisclosureForm/producerDisclosureForm";

const ApplicationForm = () => {
  const { state } = useContext(Context);
  console.log(
    "useContext -------------------------------------------- state",
    state
  );
  const {
    individualAnnuityApplication,
    suitabilityAnalysis,
    transferOrExchangeRequest,
    // bankDetails,
    noticeOfReplacement,
    annuityDisclosureForm,
    annuityDisclosureFormV2,
    transferExchangeVersion,
    disclosureFormVersion,
    rolloverTransferExchange,
    producerDisclosureForm,
    flagShowProducerDisclosure
  } = state;

  const location = useLocation();
  const [statusFalse, setStatusFalse] = useState(false);
  const [applicationEditable, setApplicationEditable] = useState(false);

  const { SetPageIndex, SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );

  const {
    pageIndex,

    flagFirstPageFirstSection,
    flagFirstPageSecondSection,
    flagFirstPageThirdSection,
    flagFirstPageFourthSection,
    flagFirstPageFifthSection,
    flagFirstPageSixthSection,
    flagFirstPageSeventhSection,
    flagFirstPageEighthSection,

    flagSecondPageFirstSection,
    flagSecondPageSecondSection,
    flagSecondPageThirdSection,
    flagSecondPageFourthSection,
    flagSecondPageFifthSection,
    flagSecondPageSixthSection,

    flagFourthPageFirstSection,
    // flagFourthPageSecondSection
  } = useContext(ErrorValidationContext).state;

  const [flagSuccessDialog, setFlagSuccessDialog] = useState(false);
  const [flagVerifyPhonePopup, setFlagVerifyPhonePopup] = useState(false);

  const { SetFlagLoader } = useContext(UserLoaderContext);
  const { user } = useContext(UserLoaderContext).state;
  const [submitApplicationStatusId, setSubmitApplicationStatusId] = useState(
    {}
  );
  const [saveAsDraftApplicationStatusId, setSaveAsDraftApplicationStatusId] =
    useState({});
  const navigate = useNavigate();
  const {
    SetWholeIndividualAnnuityApplication,
    SetWholeSuitabilityAnalysis,
    SetWholeTransferExchangeRequest,
    SetWholeRolloverTransferExchangeRequest,
    SetDisableAllInputs,
    disableAllInputs,
    SetApplicationId,
    SetWholeAnnuityDisclosureForm,
    SetWholeAnnuityDisclosureFormV2,
    SetWholeNoticeOfReplacement,
    SetTransferExchangeVersion,
    SetDisclosureFormVersion,
    SetWholeProducerDisclosureForm,
    SetFlagShowProducerDisclosure
  } = useContext(Context);
  const [applicationId, setApplicationId] = useState(null);
  const [flagErrorValidtionDialog, setFlagErrorValidationDialog] =
    useState(false);
  const [applicationStatusName, setApplicationStatusName] = useState("");
  const [beneficiariesPercentageTotal, setBeneficiariesPercentageTotal] =
    useState(0);

  const [errorFieldsArray, setErrorFieldsArray] = useState([]);
  const [searchParams] = useSearchParams();
  const { flagRequestYield } = noticeOfReplacement;

  const ReturnBeneficiariesPrimary = (data) => {
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i]?.beneficiaries_flag_primary == 1) {
        temp.push({
          id: data[i]?.beneficiaries_id,
          primaryField: data[i]?.beneficiaries_name,
          percentageField:
            data[i]?.beneficiaries_percentage == -1 || data[i]?.beneficiaries_percentage == null
              ? ""
              : data[i]?.beneficiaries_percentage,
          ssnField: data[i]?.beneficiaries_ssn_tin_no,
          genderField: data[i]?.beneficiaries_gender,
          relationshipField: data[i]?.beneficiaries_relationship,
        });
      }
    }

    if (data.length == 0) {
      for (let i = 0; i < 6; i++) {
        temp.push({
          id: i + 1,
          primaryField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: ""
        })
      }
    }

    if (temp.length === 0) {
      temp = [
        {
          id: 1,
          primaryField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
        {
          id: 2,
          primaryField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
        {
          id: 3,
          primaryField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
      ];
    } else if (temp.length === 1) {
      temp.push({
        id: Date.now(),
        primaryField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
      temp.push({
        id: Date.now(),
        primaryField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
    } else if (temp.length === 2) {
      temp.push({
        id: Date.now(),
        primaryField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
    }
    return temp;
  };

  const ReturnBeneficiariesContigent = (data) => {
    let temp = [];
    data.map((v) => {
      if (v?.beneficiaries_flag_primary === 0) {
        temp.push({
          id: v?.beneficiaries_id,
          contigentField: v?.beneficiaries_name,
          percentageField:
            v?.beneficiaries_percentage === -1 || v?.beneficiaries_percentage == null
              ? ""
              : v?.beneficiaries_percentage,
          ssnField: v?.beneficiaries_ssn_tin_no,
          genderField: v?.beneficiaries_gender,
          relationshipField: v?.beneficiaries_relationship,
        });
      }
    });

    if (data.length == 0) {
      for (let i = 0; i < 6; i++) {
        temp.push({
          id: i + 1,
          contigentField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: ""
        })
      }
    }

    if (temp.length === 0) {
      temp = [
        {
          id: 1,
          contigentField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
        {
          id: 2,
          contigentField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
        {
          id: 3,
          contigentField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
      ];
    } else if (temp.length === 1) {
      temp.push({
        id: Date.now(),
        contigentField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
      temp.push({
        id: Date.now(),
        contigentField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
    } else if (temp.length === 2) {
      temp.push({
        id: Date.now(),
        contigentField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
    }

    return temp;
  };

  const ReturnTempInsurerPolicyDetailFieldArray = (data) => {
    if (data === undefined) {
      return [
        {
          id: 1,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
        {
          id: 2,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
        {
          id: 3,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
        {
          id: 4,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
      ];
    }

    let temp = [];
    data.map((v) => {
      temp.push({
        id: v?.existing_policy_id,
        existingCarrierName:
          (v.nor_existing_policy_insurer_name && v.nor_existing_policy_insurer_name != "undefined") ?
            v.nor_existing_policy_insurer_name : "",
        existingCarrierAddress: (v.nor_existing_policy_insurer_name_address && v.nor_existing_policy_insurer_name_address != "undefined") ?
          v.nor_existing_policy_insurer_name_address : "",
        contractOrPolicyNumber: (v.nor_existing_policy_contract_number && v.nor_existing_policy_contract_number != "undefined") ?
          v.nor_existing_policy_contract_number : "",
        nameOfInsuredOrAnnuitant: (v.nor_existing_policy_name_of_insured && v.nor_existing_policy_name_of_insured != "undefined") ?
          v.nor_existing_policy_name_of_insured : "",
        amount: (v.nor_existing_policy_face_amount && v.nor_existing_policy_face_amount != "undefined") ?
          v.nor_existing_policy_face_amount : "",
        effectiveDate: (v.nor_existing_policy_effective_date && v.nor_existing_policy_effective_date != "undefined") ?
          v.nor_existing_policy_effective_date : "",
      });
    });
    return temp;
  };

  const PersonsAssistedToPurchaseProduct = (matchString, data) => {
    let i;
    let other;
    let temp = data;
    for (i = 0; i < temp.length; i++) {
      if (temp[i] === "none") {
        continue;
      } else if (temp[i] === "accountant") {
        continue;
      } else if (temp[i] === "attorney") {
        continue;
      } else if (temp[i] === "family member") {
        continue;
      } else if (temp[i] === "financial planner") {
        continue;
      } else if (data[i] === "") {
        continue;
      } else if (data[i] === matchString) {
        continue;
      } else {
        other = true;
        break;
      }
    }
    other === true && temp.push(matchString);
    return temp;
  };

  const ReturnTransferExchangeRequestPlan = (qualifiedSection, nonQualifiedSection) => {
    if (nonQualifiedSection?.length) {
      return "Non-Qualified Plans";
    } else if (qualifiedSection?.length) {
      return "Qualified Plans";
    } else {
      return ""
    }
  };

  const GetPhoneNumberPayloadFormate = (phoneNumber) => {
    if (!phoneNumber) {
      return ""
    }
    let gg = phoneNumber?.split(" ");
    let countryCode = gg[0];
    gg.shift();
    let restOfTheNumber = gg.join();

    countryCode = countryCode.replace(/[^\d+]/g, '');
    restOfTheNumber = restOfTheNumber.replace(/[^\d+]/g, '');
    if (countryCode.startsWith('+')) {
      countryCode = countryCode.substring(1);
    }

    return `${countryCode}-${restOfTheNumber}`;
  }

  const formatPhoneNumberForContext = (phoneNumber) => {
    if (!phoneNumber) {
      return ""
    }
    if (phoneNumber?.replace(/[^\d+]/g, '').length > 10) {
      let gg = phoneNumber.split("-")
      if (gg[0] != 1) {
        return "+1 " + gg[1]
      } else {
        return "+" + phoneNumber.replace("-", " ");
      }
    } else {
      let formatedPhone = phoneNumber?.replace(/[^\d+]| /g, '')
      formatedPhone = "+1 " + formatedPhone
      return formatedPhone
    }
  }


  const SetDataToContext = (data, flagNewTransferForm, flagNewDisclosure, flagProducerDisclosureForm) => {
    try {
      SetWholeIndividualAnnuityApplication({
        annuitantFullName: data?.iaa_section1_annuitant_full_name,
        annuitantSSN: data?.iaa_section1_annuitant_ssn,
        annuitantGender: data?.iaa_section1_annuitant_gender?.value[0],
        annuitantDOB: data?.iaa_section1_annuitant_dob,
        annuitantStreetAddress: data?.iaa_section1_annuitant_address,
        annuitantZip: data?.iaa_section1_annuitant_zip,
        annuitantCity: data?.iaa_section1_annuitant_city,
        annuitantState: data?.iaa_section1_annuitant_state,
        annuitantPhone: formatPhoneNumberForContext(data?.iaa_section1_annuitant_phone),
        annuitantEmail: data?.iaa_section1_annuitant_mail,
        ownerFullName: data?.iaa_section2_owner_full_name,
        ownerSSN: data?.iaa_section2_owner_ssn,
        ownerGender: data?.iaa_section2_owner_gender?.value[0],
        ownerDOB: data?.iaa_section2_owner_dob,
        ownerStreetAddress: data?.iaa_section2_owner_address,
        ownerZip: data?.iaa_section2_owner_zip,
        ownerCity: data?.iaa_section2_owner_city,
        ownerState: data?.iaa_section2_owner_state,
        ownerEmail: data?.iaa_section2_owner_mail,
        ownerRelationToAnnuitant: data?.iaa_section2_owner_relationship_to_annuitant,
        ownerPhone: formatPhoneNumberForContext(data?.iaa_section2_owner_phone),
        jointOwnerFullName: data?.iaa_section3_joint_owner_full_name,
        jointOwnerSSN: data?.iaa_section3_joint_owner_ssn,
        jointOwnerGender: data?.iaa_section3_joint_owner_gender?.value[0],
        jointOwnerDOB: data?.iaa_section3_joint_owner_dob,
        jointOwnerStreetAddress: data?.iaa_section3_joint_owner_address,
        jointOwnerZip: data?.iaa_section3_joint_owner_zip,
        jointOwnerCity: data?.iaa_section3_joint_owner_city,
        jointOwnerState: data?.iaa_section3_joint_owner_state,
        jointOwnerEmail: data?.iaa_section3_joint_owner_mail,
        jointOwnerPhone: formatPhoneNumberForContext(data?.iaa_section3_joint_owner_phone),
        jointOwnerRelationToAnnuitant: data?.iaa_section3_joint_owner_relationship_to_annuitant,
        annuitantPlan: data?.iaa_section4_planriders_annuitant_plans?.value[0] != "" ? data?.iaa_section4_planriders_annuitant_plans?.value[0] : "",
        planRiders: data?.iaa_section4_plan_riders?.value[0] != "" ? data?.iaa_section4_plan_riders?.value : [],
        beneficiariesPrimary: ReturnBeneficiariesPrimary(data?.beneficiaries),
        beneficiariesContigent: ReturnBeneficiariesContigent(data?.beneficiaries),
        flagQualifiedOrNonQualifiedSelected: data?.iaa_section6_premium_tax_qualification_status?.value[0],
        selectedNonQualifiedOptions: data?.iaa_section6_premium_tax_qualification_status?.value[0] == "qualified" ?
          data?.iaa_section6_qualified?.value : data?.iaa_section6_non_qualified?.value,
        selectedQualifiedOptionsGroup2: data?.iaa_section6_type_of_plan?.value[0] == "sep" ? "" : data?.iaa_section6_type_of_plan?.value[0],
        otherSelectedQualifiedOptions: data?.iaa_section6_type_of_plan?.extra_value,
        stateToShowNoticeOfReplacement: data?.iaa_section7_state_to_show_notice_of_replacement,
        amountPaidWithApplication: data?.iaa_section6_ammount_paid_application,
        expectedPremiumField: data?.iaa_section6_expected_premium_amount,
        flagHaveExistingLifeInsurance: data?.iaa_section7_owner_replacement_question1?.value[0],
        reasonOfApplicationToChangeLifeInsurance: data?.iaa_section7_owner_replacement_question2?.value[0],
        applicationReplacementFieldsArray: replaceMentArrayConvert(data?.replacement_question_contact_data),
      });

      SetWholeSuitabilityAnalysis({
        ownerFullName: data?.suitability_analysis_section1_owner_full_name,
        ownerCurrentAge: data?.suitability_analysis_section1_owner_age,
        ownerGender: data?.suitability_analysis_section1_owner_gender?.value[0],
        ownerEmploymentStatus:
          data?.suitability_analysis_section1_owner_emp_status?.value[0],
        ownerCurrentOccupation: data?.suitability_analysis_section1_owner_occupation,
        ownerDependents: data?.suitability_analysis_section1_owner_depedents,
        spouseFullName: data?.suitability_analysis_section1_spouse_full_name,
        spouseCurrentAge: data?.suitability_analysis_section1_spouse_age,
        spouseGender: data?.suitability_analysis_section1_spouse_gender?.value[0],
        spouseEmploymentStatus:
          data?.suitability_analysis_section1_spouse_emp_status?.value[0],
        spouseCurrentOccupation: data?.suitability_analysis_section1_spouse_occupation,
        spouseDependents: data?.suitability_analysis_section1_spouse_depedents,
        ownerAnnualHouseholdIncome:
          data?.suitability_analysis_section2_annual_income,
        ownerSourceOfIncome: data?.suitability_analysis_section2_source_income,
        ownerIncomeTaxBracket: data?.suitability_analysis_section2_income_tax,
        ownerTotalOutstandingDebt:
          data?.suitability_analysis_section2_total_outstanding_debt,
        ownerTotalAssets: data?.suitability_analysis_section2_total_assets,
        ownerTotalLiquidAssets: data?.suitability_analysis_section2_total_liquid_assets,
        flagOwnerIncomeCoverLivingAndMedicalExpenses:
          data?.suitability_analysis_section3_q1_medical_expenses?.value[0],
        explainOwnerIncomeCoverLivingAndMedicalExpenses:
          data?.suitability_analysis_section3_q1_medical_expenses.extra_value,
        flagOwnerExpectChangesInLivingExpenses:
          data?.suitability_analysis_section3_q2_living_expenses?.value[0],
        explainOwnerExpectChangesInLivingExpenses:
          data?.suitability_analysis_section3_q2_living_expenses.extra_value,
        flagOwnerAnticipateChangesInMedicalExpenses:
          data?.suitability_analysis_section3_q3_pocket_expenses?.value[0],
        explainOwnerAnticipateChangesInMedicalExpenses:
          data?.suitability_analysis_section3_q3_pocket_expenses.extra_value,
        flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod:
          data?.suitability_analysis_section3_q4_surrender_charge_expenses?.value[0],
        explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod:
          data?.suitability_analysis_section3_q4_surrender_charge_expenses.extra_value,
        flagOwnerHaveEmergencyFundForUnexpectedExpenses:
          data?.suitability_analysis_section3_q5_emergency_fund?.value[0],
        explainOwnerHaveEmergencyFundForUnexpectedExpenses:
          data?.suitability_analysis_section3_q5_emergency_fund.extra_value,
        ownerOtherInvestments:
          data?.suitability_analysis_section3_q6_own_investment?.value[0] == "" ?
            []
            :
            data?.suitability_analysis_section3_q6_own_investment?.value,
        otherOwnerInvestmentField:
          data?.suitability_analysis_section3_q6_own_investment.extra_value,
        ownerRiskTolerance:
          data?.suitability_analysis_section3_q7_risk_tolerance?.value[0],
        reasonForPurchasingProduct:
          data?.suitability_analysis_section4_q_reason_for_purchase_product?.value[0] == ""
            ?
            []
            :
            data?.suitability_analysis_section4_q_reason_for_purchase_product?.value,
        otherRasonForPurchasingThisProduct:
          data?.suitability_analysis_section4_q_reason_for_purchase_product?.value.length === 0
            ? ""
            : data?.suitability_analysis_section4_q_reason_for_purchase_product.extra_value,
        flagOwnerPlanToWithdrawFundInSurrenderPeriod:
          data?.suitability_analysis_section4_q1_surrender_charge_period?.value[0],
        explainOwnerPlanToWithdrawFundInSurrenderPeriod:
          data?.suitability_analysis_section4_q1_surrender_charge_period.extra_value,
        ownerAnticipateNeedingAccessToFunds:
          data?.suitability_analysis_section4_q2_anticipate_need?.value[0],
        sourceOfFundsToPurchaseThisProduct: data?.suitability_analysis_section4_q3_source_of_fund_purchase_product?.value[0] == ""
          ?
          []
          :
          data?.suitability_analysis_section4_q3_source_of_fund_purchase_product?.value,
        otherSourceOfFundsToPurchaseThisProduct:
          data?.suitability_analysis_section4_q3_source_of_fund_purchase_product.extra_value,
        flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct:
          data?.suitability_analysis_section5_q1_existing_account_information?.value[0],
        howLongHasTheLifeInsuranceBeenInForce:
          data?.suitability_analysis_section5_q2_life_insurance_or_annuity_force_year,

        flagSurrenderChargeOnExistingProduct:
          data?.suitability_analysis_section5_q3_surrender_charge_product?.value[0],
        currentSurrenderChargeOnExistingProduct:
          data?.suitability_analysis_section5_q3_surrender_charge_product?.extra_value?.split(",")[0],
        expirationOfCurrentSurrenderCharge: data?.suitability_analysis_section5_q3_surrender_charge_product?.extra_value?.split(",")[1],
        typeOfExistingProduct: data?.suitability_analysis_section5_q4_type_of_existed_product?.value[0],
        interestRateForExistingProduct: data?.suitability_analysis_section5_q5_interest_rate_existing_product,
        flagTakingRequiredMinimumDistributionsFromExistingProduct:
          data?.suitability_analysis_section5_q6_tax_qualified_annuity?.value[0],
        flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution:
          data?.suitability_analysis_section5_q7_tax_periodic_distributions?.value[0],
        explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution:
          data?.suitability_analysis_section5_q7_tax_periodic_distributions.extra_value,
        personsThatAssistedOwnerToPurchaseThisProduct:
          data?.suitability_analysis_section6_assist_purchase_product?.value[0] == ""
            ? []
            : data?.suitability_analysis_section6_assist_purchase_product?.value,
        otherPersonsThatAssistedOwnerToPurchaseThisProduct: data?.suitability_analysis_section6_assist_purchase_product.extra_value,
        additionalRelaventInformationForSuitabilityAnalysis:
          data?.suitability_analysis_section6_relevant_information,
        agentAcknowledgment: data?.suitability_analysis_section7_agent_acknowledgement
          ? data?.suitability_analysis_section7_agent_acknowledgement?.value[0]
          : "",
      });
      if (flagNewTransferForm) {
        SetWholeRolloverTransferExchangeRequest({
          transferV2Section1CompanyName: data?.transfer_v2_section1_company_name,
          transferV2Section1CompanyPhone: data?.transfer_v2_section1_company_phone,
          transferV2Section1CompanyPolicyNumber: data?.transfer_v2_section1_company_policy_number,
          transferV2Section1CompanyAddress: data?.transfer_v2_section1_company_address,
          transferV2Section1CompanyCity: data?.transfer_v2_section1_company_city,
          transferV2Section1CompanyState: data?.transfer_v2_section1_company_state,
          transferV2Section1CompanyZip: data?.transfer_v2_section1_company_zip,
          transferV2Section1OwnerName: data?.transfer_v2_section1_owner_name,
          transferV2Section1OwnerSsn: data?.transfer_v2_section1_owner_ssn,
          transferV2Section1JoinOwnerName: data?.transfer_v2_section1_join_owner_name,
          transferV2Section1JoinOwnerSsn: data?.transfer_v2_section1_join_owner_ssn,
          transferV2Section1AnnuitantName: data?.transfer_v2_section1_annuitant_name,
          transferV2Section1AnnuitantSsn: data?.transfer_v2_section1_annuitant_ssn,
          transferV2Section1JoinAnnuitantName: data?.transfer_v2_section1_join_annuitant_name,
          transferV2Section1JoinAnnuitantSsn: data?.transfer_v2_section1_join_annuitant_ssn,
          transferV2Section1BeneficiaryName: data?.transfer_v2_section1_beneficiary_name,
          transferV2Section1BeneficiarySsn: data?.transfer_v2_section1_beneficiary_ssn,
          transferV2Section1InvestmentVehicle: data?.transfer_v2_section1_investment_vehicle?.value[0],
          transferV2Section1InvestmentVehicleOther: data?.transfer_v2_section1_investment_vehicle?.extra_value,
          transferV2Section1ExistingCertificate: data?.transfer_v2_section1_existing_certificate?.value[0],

          transferV2Section2Plan: data?.transfer_v2_section2_plan,
          transferV2Section2NonQualified1035Exchange: data?.transfer_v2_section2_non_qualified_1035_exchange?.value[0],
          transferV2Section2NonQualified1035ExchangeOtherPercentage:
            (Number(data?.transfer_v2_section2_non_qualified_1035_exchange?.extra_value?.replace(/$|,/gi, ''))) <= 100 ?
              data?.transfer_v2_section2_non_qualified_1035_exchange?.extra_value.replace(/$|,/gi, '') : "",


          // data?.transfer_v2_section2_non_qualified_1035_exchange?.extra_value?.split(",")[0],
          transferV2Section2NonQualified1035ExchangeOtherAmount:
            (Number(data?.transfer_v2_section2_non_qualified_1035_exchange?.extra_value?.replace(/$|,/gi, ''))) > 100 ?
              (data?.transfer_v2_section2_non_qualified_1035_exchange?.extra_value?.indexOf(",") === 0 ?
                data?.transfer_v2_section2_non_qualified_1035_exchange?.extra_value.substring(1) : data?.transfer_v2_section2_non_qualified_1035_exchange?.extra_value) : "",
          transferV2Section2QualifiedTypeOfAccount: data?.transfer_v2_section2_qualified_type_of_account?.value[0],
          transferV2Section2QualifiedTypeOfAccountOther: data?.transfer_v2_section2_qualified_type_of_account?.extra_value,
          transferV2Section2QualifiedLiquidateTransfer:
            data?.transfer_v2_section2_qualified_liquidate_transfer_all?.value[0] == "all"
              ? "all" : "partial",
          transferV2Section2QualifiedLiquidateTransferAmount: data?.transfer_v2_section2_qualified_liquidate_transfer_partial_amount?.extra_value,
          transferV2Section2QualifiedLiquidateTransferPercentage: data?.transfer_v2_section2_qualified_liquidate_transfer_partial_percentage?.extra_value,
          transferV2Section2QualifiedAgeRmdTransfer: data?.transfer_v2_section2_qualified_age_rmd_transfer?.value[0],
          transferV2Section2QualifiedWithdrawFunds: data?.transfer_v2_section2_qualified_withdraw_funds?.value[0],
          transferV2Section2QualifiedWithdrawFundsOther: data?.transfer_v2_section2_qualified_withdraw_funds?.extra_value,
          transferV2Section2QualifiedFundsTransfered: data?.transfer_v2_section2_qualified_funds_transfered?.value[0],
        })
      } else {
        SetWholeTransferExchangeRequest({
          ownerFullName: data?.transfer_section1_item_owner_full_name,
          ownerSSN: data?.transfer_section1_item_owner_ssn_or_tax_id,
          ownerStreetAddress: data?.transfer_section1_item_owner_address,
          ownerZip: data?.transfer_section1_item_owner_zip,
          ownerCity: data?.transfer_section1_item_owner_city,
          ownerState: data?.transfer_section1_item_owner_state,
          ownerMaritalStatus: data?.transfer_section1_item_owner_marital_status,
          annuitantOrInsured: data?.transfer_section1_item_owner_annuitant,
          currentInsuranceCompany:
            data?.transfer_section1_item_owner_current_insurance_company,
          currentInsuranceCompanyPhone:
            data?.transfer_section1_item_owner_phone,
          currentInsuranceCompanyAccountNumber:
            data?.transfer_section1_item_annuitant_account_number,
          currentInsuranceCompanyStreetAddress:
            data?.transfer_section1_item_annuitant_address,
          currentInsuranceCompanyZip:
            data?.transfer_section1_item_annuitant_zip,
          currentInsuranceCompanyCity:
            data?.transfer_section1_item_annuitant_city,
          currentInsuranceCompanyState:
            data?.transfer_section1_item_annuitant_state,
          typeOfAccount: data?.transfer_section1_type_of_account?.value[0],
          otherTypeOfAccount:
            data?.transfer_section1_type_of_account?.extra_value,
          ownerExistingAnnuity:
            data?.transfer_section1_existing_contract_or_certificate?.value[0],
          plans: ReturnTransferExchangeRequestPlan(
            data?.transfer_section2_qualified_plan_rmd_reach_age_72?.value[0],
            data?.transfer_section2_non_qualified_plan_prior_exchange?.value[0]
          ),

          flagNonQualifiedOwnerWantWithdrawalToExchange:
            data?.transfer_section2_non_qualified_plan_prior_exchange.value[0],

          explainNonQualifiedOwnerWantWithdrawalToExchange:
            data?.transfer_section2_non_qualified_plan_prior_exchange?.extra_value,

          flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal:
            data?.transfer_section2_non_qualified_plan_continue_exiting_rmd_schedule?.value[0],

          qualifiedTransferOrExchangeExistingAccountAllOrPartial:
            data?.transfer_section2_plan_existing_account_requested_question_1?.value[0],

          qualifiedTransferOrExchangeExistingAccountPartialAmount:
            data?.transfer_section2_plan_existing_account_requested_question_1?.extra_value,

          qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate:
            data?.transfer_section2_plan_existing_account_requested_question_2?.value[0],

          qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField:
            data?.transfer_section2_plan_existing_account_requested_question_2?.extra_value,

          qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy:
            data?.transfer_section2_plan_existing_account_requested_question_3?.value[0],

          qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField:
            data?.transfer_section2_plan_existing_account_requested_question_3?.extra_value,

          qualifiedTypeOfTransfer:
            data?.transfer_section2_plans_transfer_or_rollover?.value[0],

          qualifiedQualifyingEventForDirectRolloverFunds:
            data?.transfer_section2_direct_rollover_fund?.value[0],

          flagQualifiedOwnerReachedAge72OrMoreInThisYear:
            data?.transfer_section2_qualified_plan_rmd_reach_age_72?.value[0],
          flagQualifiedOwnerSatisfiedRMDFromDistributingPlan:
            data?.transfer_section2_qualified_plan_rmd_satisfied_distributing_plan?.value[0],
          qualifiedInternalExchangeOfExistingAnnuityName:
            data?.transfer_section2_qualified_plan_internal_exchnage_annuity,
          flagQualifiedOwnerWantRMDBeforeCompletingExchange:
            data?.transfer_section2_qualified_plan_get_rmd_before_completing?.value[0],
          flagQualifiedOwnerWantToContinueExistingRMDSchedule:
            data?.transfer_section2_qualified_plan_continue_exiting_rmd_schedule?.value[0],
          flagSignatureDigitalOrPhysical: data?.trans_exchange_req_signature_digital_or_physical?.value[0] != "" ? data?.trans_exchange_req_signature_digital_or_physical?.value[0] : ""
        });
      }

      SetWholeNoticeOfReplacement({
        flagDiscontinuingPremiumPayment:
          data?.nor_question1a_terminating_your_existing_policy?.value[0],
        flagPayForPremiumPolicy:
          data?.nor_question1b_fund_from_new_policy?.value[0],
        insurerPolicyDetailFieldArray: ReturnTempInsurerPolicyDetailFieldArray(data?.existing_policy),
        reasonForReplacement: data?.nor_question1d_reason_for_replacement,
      });

      if (flagNewDisclosure) {
        SetWholeAnnuityDisclosureFormV2({
          flagAnnuityClosureV2: data?.safeguard_v2_plus_disclosure_annuity?.value[0],
          flagAnnuityClosureBackupWithout: data?.safeguard_v2_plus_disclosure_backup_withholding?.value[0],
        });
      } else {
        SetWholeAnnuityDisclosureForm({
          flagAnnuityClosure: data?.safeguard_plus_disclosure_annuity?.value[0],
        });
      }
      if (flagProducerDisclosureForm) {
        SetWholeProducerDisclosureForm({
          producerFirstName: data?.producer_disclosure_section1_first_name,
          producerLastName: data?.producer_disclosure_section1_last_name,
          producerBusinessName: data?.producer_disclosure_section1_bussiness_name,
          producerWebsite: data?.producer_disclosure_section1_website,
          producerBusinessMailingAddress: data?.producer_disclosure_section1_bussiness_mailing_address,
          producerCity: data?.producer_disclosure_section1_city,
          producerState: data?.producer_disclosure_section1_state,
          producerZip: data?.producer_disclosure_section1_zip,
          producerTelephone: data?.producer_disclosure_section1_bussiness_telephone_number,
          producerEmail: data?.producer_disclosure_section1_email,
          producerNationalNumber: data?.producer_disclosure_section1_national_producer_name,
          producerInStateOf: data?.producer_disclosure_section1_state_of,
          producerCustomerFirstName: data?.producer_disclosure_section2_first_name,
          producerCustomerLastName: data?.producer_disclosure_section2_last_name,
          producerOfferedProducts: data?.producer_disclosure_section3_offered_products.value,
          producerNonInsuranceProducts: data?.producer_disclosure_section3_non_insurance_products.value,
          producerAuthorizedToSell: data?.producer_disclosure_section4_annuities_authorize_to_sell.value,
          producerAuthorizedToSellDescription: data?.producer_disclosure_section4_annuities_authorize_to_sell.extra_value,
          producerPaidForWork: [...data?.producer_disclosure_section4_cash_compensations.value, ...data?.producer_disclosure_section4_cash_compensations_other.value],
          producerPaidForWorkCommissionDescription: data?.producer_disclosure_section4_cash_compensations.extra_value,
          producerPaidForWorkOtherDescription: data?.producer_disclosure_section4_cash_compensations_other.extra_value,
        });
      }
      SetFlagLoader(false);
    } catch (e) {
      console.log(e);
    }
  };

  const CommonPlansQuestionValidation = () => {
    if (qualifiedTransferOrExchangeExistingAccountAllOrPartial !== "" &&
      (qualifiedTransferOrExchangeExistingAccountAllOrPartial === "partials"
        ? qualifiedTransferOrExchangeExistingAccountPartialAmount !== ""
        : true) &&
      qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate !==
      "" &&
      (qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate ===
        "upon the maturity date of"
        ? qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !==
        ""
        : true) &&
      qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy !==
      "" &&
      (qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy ===
        "to existing policy/account"
        ? qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField !==
        ""
        : true)) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    SetPageIndex(1)
    SetFlagLoader(true);
    let domain = window.location.host;
    let protocol = window.location.protocol;
    let searchId = searchParams.get("id")
    let newApplicationFlag = searchParams.get("flag_new_application")
    if (searchId && newApplicationFlag) {
      setApplicationId(Number(searchId));
      let obj = {
        applicationId: searchId,
        flag: newApplicationFlag
      }
      GetSingleIaaDataAPI(
        obj,
        (res) => {
          setApplicationStatusName(res.data.data[0].application_status_name);
          SetApplicationId(res.data.data[0].applications_id);
          if (res.data.data[0].application_status_name !== "Draft") {
            if (applicationEditable === true) {
              SetDisableAllInputs(false);
            } else {
              SetDisableAllInputs(true);
            }
          } else {
            SetDisableAllInputs(false);
          }
          if (res.data.status === true) {
            let flagNewTransferForm;
            let flagNewDisclosure;
            let flagShowProducerDisclosureForm
            if (res.data.data[0]?.form_id_array.includes(9)) {
              SetDisclosureFormVersion(9)
              flagNewDisclosure = true
            } else if (res.data.data[0]?.form_id_array.includes(5)) {
              SetDisclosureFormVersion(5)
              flagNewDisclosure = false
            }
            if (newApplicationFlag == false) {
              SetTransferExchangeVersion(4)
            }
            else if (res.data.data[0]?.form_id_array.includes(7)) {
              SetTransferExchangeVersion(7)
              flagNewTransferForm = true
            } else if (res.data.data[0]?.form_id_array.includes(4)) {
              SetTransferExchangeVersion(4)
              flagNewTransferForm = false
            } else {
              SetTransferExchangeVersion(7)
              flagNewTransferForm = true
            }
            if (res.data.data[0]?.form_id_array.includes(11)) {
              SetFlagShowProducerDisclosure(true)
              flagShowProducerDisclosureForm = true
            } else {
              SetFlagShowProducerDisclosure(false)
              flagShowProducerDisclosureForm = false
            }
            SetDataToContext(res.data.data[0].form_data, flagNewTransferForm, flagNewDisclosure, flagShowProducerDisclosureForm);
          }
        },
        (err) => {
          SetFlagLoader(false);
        }
      );

      ApplicationStatusId(
        {},
        (res) => {
          SetFlagLoader(!true);

          if (res.data.status === true) {
            res.data.data.map((v) => {
              if (v.name === "Submitted for Signature") {
                setSubmitApplicationStatusId(v);
              } else {
                setSaveAsDraftApplicationStatusId(v);
              }
            });
          }
        },
        (err) => {
          SetFlagLoader(!true);
          if (err.response.status === 401) {
            window.location.reload();
          }
        }
      );
    } else {
      SetFlagLoader(false);
    }

  }, []);

  useEffect(() => {
    if (location?.search?.includes("id") === true) {
      if (
        applicationStatusName !== "Draft" &&
        applicationStatusName !== "" &&
        applicationStatusName !== null
      ) {
        if (applicationEditable === true) {
          SetDisableAllInputs(false);
        } else {
          SetDisableAllInputs(true);
        }
      }
    }
  }, [applicationEditable, applicationStatusName]);

  const {
    flagDiscontinuingPremiumPayment,
    flagPayForPremiumPolicy,
    insurerPolicyDetailFieldArray,
    reasonForReplacement,
  } = noticeOfReplacement;

  const { flagAnnuityClosure } = annuityDisclosureForm;
  const { flagAnnuityClosureV2, flagAnnuityClosureBackupWithout } = annuityDisclosureFormV2;

  const {
    annuitantOrInsured,
    currentInsuranceCompany,
    currentInsuranceCompanyPhone,
    currentInsuranceCompanyAccountNumber,
    currentInsuranceCompanyStreetAddress,
    currentInsuranceCompanyZip,
    currentInsuranceCompanyCity,
    currentInsuranceCompanyState,
    typeOfAccount,
    otherTypeOfAccount,
    ownerExistingAnnuity,
    plans,
    flagNonQualifiedOwnerWantWithdrawalToExchange,
    explainNonQualifiedOwnerWantWithdrawalToExchange,
    flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal,
    flagSignatureDigitalOrPhysical,
    qualifiedTransferOrExchangeExistingAccountAllOrPartial,
    qualifiedTransferOrExchangeExistingAccountPartialAmount,
    qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate,
    qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField,
    qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy,
    qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField,
    qualifiedTypeOfTransfer,
    qualifiedQualifyingEventForDirectRolloverFunds,
    flagQualifiedOwnerReachedAge72OrMoreInThisYear,
    flagQualifiedOwnerSatisfiedRMDFromDistributingPlan,
    qualifiedInternalExchangeOfExistingAnnuityName,
    flagQualifiedOwnerWantRMDBeforeCompletingExchange,
    flagQualifiedOwnerWantToContinueExistingRMDSchedule,
  } = transferOrExchangeRequest;

  const {
    // ownerFullName,
    ownerCurrentAge,
    // ownerGender,
    ownerEmploymentStatus,
    ownerCurrentOccupation,
    ownerDependents,
    spouseFullName,
    spouseCurrentAge,
    spouseGender,
    spouseEmploymentStatus,
    spouseCurrentOccupation,
    spouseDependents,
    ownerAnnualHouseholdIncome,
    ownerSourceOfIncome,
    ownerIncomeTaxBracket,
    ownerTotalOutstandingDebt,
    ownerTotalAssets,
    ownerTotalLiquidAssets,
    flagOwnerIncomeCoverLivingAndMedicalExpenses,
    explainOwnerIncomeCoverLivingAndMedicalExpenses,
    flagOwnerExpectChangesInLivingExpenses,
    explainOwnerExpectChangesInLivingExpenses,
    flagOwnerAnticipateChangesInMedicalExpenses,
    explainOwnerAnticipateChangesInMedicalExpenses,
    flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
    explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
    flagOwnerHaveEmergencyFundForUnexpectedExpenses,
    explainOwnerHaveEmergencyFundForUnexpectedExpenses,
    ownerOtherInvestments,
    otherOwnerInvestmentField,
    ownerRiskTolerance,
    reasonForPurchasingProduct,
    otherRasonForPurchasingThisProduct,
    flagOwnerPlanToWithdrawFundInSurrenderPeriod,
    explainOwnerPlanToWithdrawFundInSurrenderPeriod,
    ownerAnticipateNeedingAccessToFunds,
    sourceOfFundsToPurchaseThisProduct,
    otherSourceOfFundsToPurchaseThisProduct,
    flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct,
    howLongHasTheLifeInsuranceBeenInForce,
    flagSurrenderChargeOnExistingProduct,
    currentSurrenderChargeOnExistingProduct,
    expirationOfCurrentSurrenderCharge,
    typeOfExistingProduct,
    interestRateForExistingProduct,
    flagTakingRequiredMinimumDistributionsFromExistingProduct,
    flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
    explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
    personsThatAssistedOwnerToPurchaseThisProduct,
    otherPersonsThatAssistedOwnerToPurchaseThisProduct,
    additionalRelaventInformationForSuitabilityAnalysis,
    agentAcknowledgment,
  } = suitabilityAnalysis;

  const {
    annuitantFullName,
    annuitantSSN,
    annuitantGender,
    annuitantDOB,
    annuitantStreetAddress,
    annuitantZip,
    annuitantCity,
    annuitantState,
    annuitantPhone,
    annuitantEmail,
    ownerFullName,
    ownerSSN,
    ownerGender,
    ownerDOB,
    ownerStreetAddress,
    ownerZip,
    ownerCity,
    ownerState,
    ownerEmail,
    ownerRelationToAnnuitant,
    ownerPhone,
    jointOwnerFullName,
    jointOwnerSSN,
    jointOwnerGender,
    jointOwnerDOB,
    jointOwnerStreetAddress,
    jointOwnerZip,
    jointOwnerCity,
    jointOwnerState,
    jointOwnerEmail,
    jointOwnerRelationToAnnuitant,
    jointOwnerPhone,
    annuitantPlan,
    planRiders,
    beneficiariesPrimary,
    beneficiariesContigent,
    selectedNonQualifiedOptions,
    // selectedQualifiedOptions,
    otherSelectedQualifiedOptions,
    selectedQualifiedOptionsGroup2,
    stateToShowNoticeOfReplacement,
    flagQualifiedOrNonQualifiedSelected,
    amountPaidWithApplication,
    expectedPremiumField,
    flagHaveExistingLifeInsurance,
    reasonOfApplicationToChangeLifeInsurance,
    applicationReplacementFieldsArray,
  } = individualAnnuityApplication;

  const tempBeneficiaries = () => {

    let temp = [];
    beneficiariesPrimary.map((obj) => {
      temp.push({
        beneficiaries_flag_primary: 1,
        beneficiaries_name: obj?.primaryField,
        beneficiaries_percentage: obj?.percentageField,
        beneficiaries_ssn_tin_no: obj?.ssnField,
        beneficiaries_gender: obj?.genderField,
        beneficiaries_id: obj?.id,
        beneficiaries_relationship: obj?.relationshipField,
      });
    });

    beneficiariesContigent.map((obj) => {
      temp.push({
        beneficiaries_flag_primary: 0,
        beneficiaries_name: obj?.contigentField,
        beneficiaries_percentage: obj?.percentageField,
        beneficiaries_ssn_tin_no: obj?.ssnField,
        beneficiaries_gender: obj?.genderField,
        beneficiaries_id: obj?.id,
        beneficiaries_relationship: obj?.relationshipField,
      });
    });

    return temp;
  };

  const tempInsurerPolicyDetailFieldArray = () => {

    let temp = [];
    insurerPolicyDetailFieldArray.map((v, i) => {
      if (window.location.search.includes("id") === false) {
        temp.push({
          nor_existing_policy_insurer_name:
            (v.existingCarrierName && v.existingCarrierName != "undefined") ? v.existingCarrierName : "",
          nor_existing_policy_insurer_name_address:
            (v.existingCarrierAddress && v.existingCarrierAddress != "undefined") ? v.existingCarrierAddress : "",
          nor_existing_policy_contract_number:
            (v.contractOrPolicyNumber && v.contractOrPolicyNumber != "undefined") ? v.contractOrPolicyNumber : "",
          nor_existing_policy_name_of_insured:
            (v.nameOfInsuredOrAnnuitant && v.nameOfInsuredOrAnnuitant != "undefined") ? v.nameOfInsuredOrAnnuitant : "",
          nor_existing_policy_face_amount:
            (v.amount && v.amount != "undefined") ? v.amount : "",

          nor_existing_policy_effective_date: (v.effectiveDate && v.effectiveDate != "undefined")
            ?
            new Date(v.effectiveDate).toDateString() === "Invalid Date"
              ? ""
              : `${new Date(v.effectiveDate).getMonth() + 1}/${new Date(
                v.effectiveDate
              ).getDate()}/${new Date(v.effectiveDate).getFullYear()}`
            : ""
        });
      } else {
        temp.push({
          existing_policy_id: v.id,
          nor_existing_policy_insurer_name:
            (v.existingCarrierName && v.existingCarrierName != "undefined") ? v.existingCarrierName : "",
          nor_existing_policy_insurer_name_address:
            (v.existingCarrierAddress && v.existingCarrierAddress != "undefined") ? v.existingCarrierAddress : "",
          nor_existing_policy_contract_number:
            (v.contractOrPolicyNumber && v.contractOrPolicyNumber != "undefined") ? v.contractOrPolicyNumber : "",
          nor_existing_policy_name_of_insured:
            (v.nameOfInsuredOrAnnuitant && v.nameOfInsuredOrAnnuitant != "undefined") ? v.nameOfInsuredOrAnnuitant : "",
          nor_existing_policy_face_amount:
            (v.amount && v.amount != "undefined") ? v.amount : "",
          nor_existing_policy_effective_date:
            (v.effectiveDate && v.effectiveDate != "undefined") ?
              new Date(v.effectiveDate).toDateString() === "Invalid Date"
                ? ""
                : `${new Date(v.effectiveDate).getMonth() + 1}/${new Date(
                  v.effectiveDate
                ).getDate()}/${new Date(v.effectiveDate).getFullYear()}`
              : ""
        });
      }
    });
    return temp;
  };

  const replaceMentQuestionDataArray = () => {
    let temp = [];
    applicationReplacementFieldsArray.map((obj) => {
      temp.push({
        replacement_question_company_name_of_policy: obj?.companyNameOfPolicyField,
        replacement_question_policy_contract_number: obj?.policyNumberField,
      });
    });
    return temp;
  };
  const replaceMentArrayConvert = (data) => {
    let temp = [];
    if (data?.length) {
      data.map((obj, i) => {
        temp.push({
          id: i,
          companyNameOfPolicyField: obj?.replacement_question_company_name_of_policy,
          policyNumberField: obj?.replacement_question_policy_contract_number,
        })
      });
    } else {
      [...Array(4)].map((obj, i) => {
        temp.push({
          id: i,
          companyNameOfPolicyField: "",
          policyNumberField: "",
        })
      });
    }
    return temp;
  };

  function isValidEmail(email) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  function isValidZip(zip) {
    return /^[0-9]{5}(?:-[0-9]{4})?$/.test(zip);
  }
  function isValidWebsite(website) {
    return /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/.test(website);
  }
  function isValidNPN(npn) {
    return /^\d{1,10}$/.test(npn);
  }
  function validatePhoneNumberLatest(input_str) {
    return input_str?.replace(/\D/g, '').length >= 11
  }
  function validatePhoneNumber(input_str) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return re.test(input_str);
  }

  const ReplacementQuestionDataArrayValidation = () => {

    let temp = applicationReplacementFieldsArray.filter((v) => {
      if (v.companyNameOfPolicyField === "" || v.policyNumberField === "") {
        return false;
      } else {
        return true;
      }
    });

    if (temp.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const SpouseValidation = () => {
    if (
      spouseFullName === "" &&
      spouseGender === "" &&
      spouseEmploymentStatus === "" &&
      spouseCurrentOccupation === "" &&
      spouseCurrentAge === "" &&
      spouseDependents === ""
    ) {
      return true;
    } else if (
      spouseFullName !== "" &&
      spouseGender !== "" &&
      spouseEmploymentStatus !== "" &&
      spouseCurrentOccupation !== "" &&
      spouseCurrentAge !== "" &&
      spouseDependents !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const CheckInsuredPolicyFieldArray = () => {
    let temp = [];
    temp = insurerPolicyDetailFieldArray.map((v) => {
      if (
        v.existingCarrierName == "" &&
        v.existingCarrierAddress == "" &&
        v.contractOrPolicyNumber == "" &&
        v.nameOfInsuredOrAnnuitant == "" &&
        v.amount == "" &&
        v.effectiveDate == ""
      ) {
        return true;
      } else if (
        v.existingCarrierName !== "" ||
        v.existingCarrierAddress !== "" ||
        v.contractOrPolicyNumber !== "" ||
        v.nameOfInsuredOrAnnuitant !== "" ||
        v.amount !== "" ||
        v.effectiveDate !== ""
      ) {
        if (
          v.existingCarrierName === "" ||
          v.contractOrPolicyNumber === "" ||
          v.nameOfInsuredOrAnnuitant === ""
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    if (temp.indexOf(false) > -1) {
      return false;
    } else {
      return true;
    }
  };

  const TransferExchangeOwnerInfoValidation = () => {
    const {
      transferV2Section1OwnerName,
      transferV2Section1OwnerSsn,
      transferV2Section1JoinOwnerName,
      transferV2Section1JoinOwnerSsn,
      transferV2Section1AnnuitantName,
      transferV2Section1AnnuitantSsn,
      transferV2Section1JoinAnnuitantName,
      transferV2Section1JoinAnnuitantSsn,
      transferV2Section1BeneficiaryName,
      transferV2Section1BeneficiarySsn,
    } = rolloverTransferExchange
    if (
      (transferV2Section1OwnerName != "" && transferV2Section1OwnerSsn == "") ||
      (transferV2Section1JoinOwnerName != "" && transferV2Section1JoinOwnerSsn == "") ||
      (transferV2Section1AnnuitantName != "" && transferV2Section1AnnuitantSsn == "") ||
      (transferV2Section1JoinAnnuitantName != "" && transferV2Section1JoinAnnuitantSsn == "") ||
      (transferV2Section1BeneficiaryName != "" && transferV2Section1BeneficiarySsn == "")
    ) {
      return true
    } else if (
      (transferV2Section1OwnerName == "" && transferV2Section1OwnerSsn != "") ||
      (transferV2Section1JoinOwnerName == "" && transferV2Section1JoinOwnerSsn != "") ||
      (transferV2Section1AnnuitantName == "" && transferV2Section1AnnuitantSsn != "") ||
      (transferV2Section1JoinAnnuitantName == "" && transferV2Section1JoinAnnuitantSsn != "") ||
      (transferV2Section1BeneficiaryName == "" && transferV2Section1BeneficiarySsn != "")
    ) {
      return true
    } else if (
      transferV2Section1OwnerName == "" && transferV2Section1OwnerSsn == "" &&
      transferV2Section1JoinOwnerName == "" && transferV2Section1JoinOwnerSsn == "" &&
      transferV2Section1AnnuitantName == "" && transferV2Section1AnnuitantSsn == "" &&
      transferV2Section1JoinAnnuitantName == "" && transferV2Section1JoinAnnuitantSsn == "" &&
      transferV2Section1BeneficiaryName == "" && transferV2Section1BeneficiarySsn == ""
    ) {
      return "blank"
    } else {
      return false
    }
  }

  const TransferExchangeBasicV2Validation = () => {
    const {
      transferV2Section1CompanyName,
      transferV2Section1CompanyPhone,
      transferV2Section1CompanyPolicyNumber,
      transferV2Section1CompanyAddress,
      transferV2Section1CompanyCity,
      transferV2Section1CompanyState,
      transferV2Section1CompanyZip,
      transferV2Section1InvestmentVehicle,
      transferV2Section1ExistingCertificate
    } = rolloverTransferExchange

    if (
      transferV2Section1CompanyName !== "" &&
      transferV2Section1CompanyPhone !== "" &&
      transferV2Section1CompanyPolicyNumber !== "" &&
      transferV2Section1CompanyAddress !== "" &&
      transferV2Section1CompanyCity !== "" &&
      transferV2Section1CompanyState !== "" &&
      transferV2Section1CompanyZip !== "" &&
      transferV2Section1InvestmentVehicle !== "" &&
      transferV2Section1ExistingCertificate !== "" &&
      TransferExchangeOwnerInfoValidation() != true
    ) {
      return "filled"
    } else if (
      transferV2Section1CompanyName == "" &&
      transferV2Section1CompanyPhone == "" &&
      transferV2Section1CompanyPolicyNumber == "" &&
      transferV2Section1CompanyAddress == "" &&
      transferV2Section1CompanyCity == "" &&
      transferV2Section1CompanyState == "" &&
      transferV2Section1CompanyZip == "" &&
      transferV2Section1InvestmentVehicle == "" &&
      transferV2Section1ExistingCertificate == "" &&
      TransferExchangeOwnerInfoValidation() == "blank"
    ) {
      return "blank"
    } else if (
      transferV2Section1CompanyName == "" ||
      transferV2Section1CompanyPhone == "" ||
      transferV2Section1CompanyPolicyNumber == "" ||
      transferV2Section1CompanyAddress == "" ||
      transferV2Section1CompanyCity == "" ||
      transferV2Section1CompanyState == "" ||
      transferV2Section1CompanyZip == "" ||
      transferV2Section1InvestmentVehicle == "" ||
      transferV2Section1ExistingCertificate == "" ||
      TransferExchangeOwnerInfoValidation() == true
    ) {
      return "partially"
    }
  }

  const TransferExchangePlansV2Validation = () => {
    const {
      transferV2Section2Plan,
      transferV2Section2NonQualified1035Exchange,
      transferV2Section2NonQualified1035ExchangeOtherPercentage,
      transferV2Section2NonQualified1035ExchangeOtherAmount,
      transferV2Section2QualifiedAgeRmdTransfer,
      transferV2Section2QualifiedTypeOfAccount,
      transferV2Section2QualifiedTypeOfAccountOther,
      transferV2Section2QualifiedLiquidateTransfer,
      transferV2Section2QualifiedLiquidateTransferPercentage,
      transferV2Section2QualifiedLiquidateTransferAmount,
      transferV2Section2QualifiedWithdrawFunds,
      transferV2Section2QualifiedWithdrawFundsOther,
      transferV2Section2QualifiedFundsTransfered
    } = rolloverTransferExchange

    if (transferV2Section2Plan == "") {
      return "blank"
    } else if (transferV2Section2Plan == "Qualified Plans") {
      if (
        // transferV2Section2QualifiedAgeRmdTransfer !== "" &&
        transferV2Section2QualifiedTypeOfAccount !== "" &&
        transferV2Section2QualifiedLiquidateTransfer !== "" &&
        transferV2Section2QualifiedWithdrawFunds !== "" &&
        transferV2Section2QualifiedFundsTransfered !== ""
      ) {
        if (
          (transferV2Section2QualifiedTypeOfAccount == "other" && transferV2Section2QualifiedTypeOfAccountOther == "") ||
          (transferV2Section2QualifiedLiquidateTransfer == "partial" &&
            transferV2Section2QualifiedLiquidateTransferAmount == "" && transferV2Section2QualifiedLiquidateTransferPercentage == "") ||
          (transferV2Section2QualifiedWithdrawFunds == "maturity date" && transferV2Section2QualifiedWithdrawFundsOther == "")
        ) {
          return "partially"
        } else {
          return "filled"
        }
      } else if (
        // transferV2Section2QualifiedAgeRmdTransfer == "" &&
        transferV2Section2QualifiedTypeOfAccount == "" &&
        transferV2Section2QualifiedLiquidateTransfer == "" &&
        transferV2Section2QualifiedWithdrawFunds == "" &&
        transferV2Section2QualifiedFundsTransfered == ""
      ) {
        return "partially"
      } else if (
        // transferV2Section2QualifiedAgeRmdTransfer == "" ||
        transferV2Section2QualifiedTypeOfAccount == "" ||
        transferV2Section2QualifiedLiquidateTransfer == "" ||
        transferV2Section2QualifiedWithdrawFunds == "" ||
        transferV2Section2QualifiedFundsTransfered == ""
      ) {
        return "partially"
      }
    } else if (transferV2Section2Plan == "Non-Qualified Plans") {
      if ((transferV2Section2NonQualified1035Exchange !== "")) {
        if (transferV2Section2NonQualified1035Exchange == "partial exchange" &&
          (transferV2Section2NonQualified1035ExchangeOtherPercentage !== "" || transferV2Section2NonQualified1035ExchangeOtherAmount !== "")) {
          return "filled"
        } else if (transferV2Section2NonQualified1035Exchange !== "partial exchange") {
          return "filled"
        } else {
          return "partially"
        }
      } else if (transferV2Section2NonQualified1035Exchange == "") {
        return "partially"
      }
    }
  }

  const BasicInformationValidation = () => {
    const {
      annuitantOrInsured,
      currentInsuranceCompany,
      currentInsuranceCompanyPhone,
      currentInsuranceCompanyAccountNumber,
      currentInsuranceCompanyStreetAddress,
      currentInsuranceCompanyZip,
      currentInsuranceCompanyCity,
      currentInsuranceCompanyState,
      typeOfAccount,
      otherTypeOfAccount,
      ownerExistingAnnuity,
    } = transferOrExchangeRequest;

    if (
      transferOrExchangeRequest?.ownerFullName !== "" &&
      transferOrExchangeRequest?.ownerSSN !== "" &&
      transferOrExchangeRequest?.ownerStreetAddress !== "" &&
      transferOrExchangeRequest?.ownerZip !== "" &&
      // isValidZip(transferOrExchangeRequest?.ownerZip) === true &&
      transferOrExchangeRequest?.ownerCity !== "" &&
      transferOrExchangeRequest?.ownerState !== "" &&
      transferOrExchangeRequest?.ownerMaritalStatus !== "" &&
      // annuitantOrInsured !== "" &&
      currentInsuranceCompany !== "" &&
      currentInsuranceCompanyPhone !== "" &&
      currentInsuranceCompanyAccountNumber !== "" &&
      currentInsuranceCompanyState !== "" &&
      currentInsuranceCompanyStreetAddress !== "" &&
      currentInsuranceCompanyCity !== "" &&
      currentInsuranceCompanyPhone !== "" &&
      currentInsuranceCompanyZip !== "" &&
      // isValidZip(currentInsuranceCompanyZip) === true &&
      typeOfAccount !== "" &&
      (typeOfAccount === "other" && otherTypeOfAccount !== ""
        ? true
        : typeOfAccount !== "other"
          ? true
          : false) &&
      ownerExistingAnnuity !== ""
    ) {
      return "filled";
    } else if (
      transferOrExchangeRequest?.ownerFullName === "" &&
      transferOrExchangeRequest?.ownerSSN === "" &&
      transferOrExchangeRequest?.ownerStreetAddress === "" &&
      transferOrExchangeRequest?.ownerZip === "" &&
      // isValidZip(transferOrExchangeRequest?.ownerZip) === true &&
      transferOrExchangeRequest?.ownerCity === "" &&
      transferOrExchangeRequest?.ownerState === "" &&
      transferOrExchangeRequest?.ownerMaritalStatus === "" &&
      // annuitantOrInsured === "" &&
      currentInsuranceCompany === "" &&
      currentInsuranceCompanyPhone === "" &&
      currentInsuranceCompanyAccountNumber === "" &&
      currentInsuranceCompanyState === "" &&
      currentInsuranceCompanyStreetAddress === "" &&
      currentInsuranceCompanyCity === "" &&
      currentInsuranceCompanyPhone === "" &&
      currentInsuranceCompanyZip === "" &&
      // isValidZip(currentInsuranceCompanyZip) === true &&
      typeOfAccount === "" &&
      ownerExistingAnnuity === ""
    ) {
      return "blank";
    } else if (
      transferOrExchangeRequest?.ownerFullName !== "" ||
      transferOrExchangeRequest?.ownerSSN !== "" ||
      transferOrExchangeRequest?.ownerStreetAddress !== "" ||
      transferOrExchangeRequest?.ownerZip !== "" ||
      // isValidZip(transferOrExchangeRequest?.ownerZip) === true ||
      transferOrExchangeRequest?.ownerCity !== "" ||
      transferOrExchangeRequest?.ownerState !== "" ||
      transferOrExchangeRequest?.ownerMaritalStatus !== "" ||
      // annuitantOrInsured !== "" ||
      currentInsuranceCompany !== "" ||
      currentInsuranceCompanyPhone !== "" ||
      currentInsuranceCompanyAccountNumber !== "" ||
      currentInsuranceCompanyState !== "" ||
      currentInsuranceCompanyStreetAddress !== "" ||
      currentInsuranceCompanyCity !== "" ||
      currentInsuranceCompanyPhone !== "" ||
      currentInsuranceCompanyZip !== "" ||
      // isValidZip(currentInsuranceCompanyZip) === true ||
      typeOfAccount !== "" ||
      ownerExistingAnnuity !== ""
    ) {
      return "partially"
    }
    // else {
    //   return false;
    // }
  };

  const PlansValidation = () => {
    const {
      plans,
      flagNonQualifiedOwnerWantWithdrawalToExchange,
      explainNonQualifiedOwnerWantWithdrawalToExchange,
      flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal,
      qualifiedTypeOfTransfer,
      qualifiedQualifyingEventForDirectRolloverFunds,
      flagQualifiedOwnerReachedAge72OrMoreInThisYear,
      flagQualifiedOwnerSatisfiedRMDFromDistributingPlan,
      qualifiedInternalExchangeOfExistingAnnuityName,
      flagQualifiedOwnerWantRMDBeforeCompletingExchange,
      flagQualifiedOwnerWantToContinueExistingRMDSchedule,
    } = transferOrExchangeRequest;

    if (plans === "") {
      return "blank";
    } else {
      if (plans === "Non-Qualified Plans") {
        if (CommonPlansQuestionValidation() &&
          flagNonQualifiedOwnerWantWithdrawalToExchange !== "" &&
          (flagNonQualifiedOwnerWantWithdrawalToExchange === "yes" &&
            explainNonQualifiedOwnerWantWithdrawalToExchange !== ""
            ? true
            : flagNonQualifiedOwnerWantWithdrawalToExchange === "no"
              ? true
              : false) &&
          flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal !== ""
        ) {
          return "filled";
        } else {
          return "partially";
        }
      } else {
        if (CommonPlansQuestionValidation() &&
          qualifiedTypeOfTransfer !== "" &&
          qualifiedQualifyingEventForDirectRolloverFunds !== "" &&
          flagQualifiedOwnerReachedAge72OrMoreInThisYear !== "" &&
          flagQualifiedOwnerSatisfiedRMDFromDistributingPlan !== "" &&
          (flagQualifiedOwnerReachedAge72OrMoreInThisYear === "yes" &&
            flagQualifiedOwnerSatisfiedRMDFromDistributingPlan === "no"
            ? qualifiedInternalExchangeOfExistingAnnuityName !== ""
            : true)
        ) {
          return "filled";
        } else {
          return "partially";
        }
      }
    }
  };

  const ConditionToShowReplacementForm = () => {
    if (individualAnnuityApplication.ownerState?.length && individualAnnuityApplication.ownerState !== "" && individualAnnuityApplication.ownerState !== "Tennessee") {
      return true
    } else {
      if (individualAnnuityApplication.annuitantState?.length && individualAnnuityApplication.annuitantState !== "" && individualAnnuityApplication.annuitantState !== "Tennessee") {
        return true
      } else {
        return false
      }
    }
  };

  const TransferringCompanyValidation = () => {
    const {
      transferV2Section1CompanyName,
      transferV2Section1CompanyPhone,
      transferV2Section1CompanyPolicyNumber,
      transferV2Section1CompanyAddress,
      transferV2Section1CompanyCity,
      transferV2Section1CompanyState,
      transferV2Section1CompanyZip,
    } = rolloverTransferExchange

    if (
      transferV2Section1CompanyName !== "" &&
      transferV2Section1CompanyPhone !== "" &&
      transferV2Section1CompanyPolicyNumber !== "" &&
      transferV2Section1CompanyAddress !== "" &&
      transferV2Section1CompanyCity !== "" &&
      transferV2Section1CompanyState !== "" &&
      transferV2Section1CompanyZip !== ""
    ) {
      return false
    } else {
      return true
    }
  }

  const VehicleInfoValidation = () => {
    const {
      transferV2Section1InvestmentVehicle,
      transferV2Section1ExistingCertificate
    } = rolloverTransferExchange

    if (
      transferV2Section1InvestmentVehicle !== "" &&
      transferV2Section1ExistingCertificate !== ""

    ) {
      return false
    } else {
      return true
    }
  }

  const QualifiedSectionV2Validation = () => {
    const {
      transferV2Section2QualifiedAgeRmdTransfer,
      transferV2Section2QualifiedTypeOfAccount,
      transferV2Section2QualifiedTypeOfAccountOther,
      transferV2Section2QualifiedLiquidateTransfer,
      transferV2Section2QualifiedLiquidateTransferPercentage,
      transferV2Section2QualifiedLiquidateTransferAmount,
      transferV2Section2QualifiedWithdrawFunds,
      transferV2Section2QualifiedWithdrawFundsOther,
      transferV2Section2QualifiedFundsTransfered
    } = rolloverTransferExchange
    if (
      // transferV2Section2QualifiedAgeRmdTransfer !== "" &&
      transferV2Section2QualifiedTypeOfAccount !== "" &&
      transferV2Section2QualifiedLiquidateTransfer !== "" &&
      transferV2Section2QualifiedWithdrawFunds !== "" &&
      transferV2Section2QualifiedFundsTransfered !== ""
    ) {
      if (
        (transferV2Section2QualifiedTypeOfAccount == "other" && transferV2Section2QualifiedTypeOfAccountOther == "") ||
        (transferV2Section2QualifiedLiquidateTransfer == "partial" &&
          transferV2Section2QualifiedLiquidateTransferAmount == "" && transferV2Section2QualifiedLiquidateTransferPercentage == "") ||
        (transferV2Section2QualifiedWithdrawFunds == "maturity date" && transferV2Section2QualifiedWithdrawFundsOther == "")
      ) {
        return "partially"
      } else {
        return "filled"
      }
    } else if (
      // transferV2Section2QualifiedAgeRmdTransfer == "" &&
      transferV2Section2QualifiedTypeOfAccount == "" &&
      transferV2Section2QualifiedLiquidateTransfer == "" &&
      transferV2Section2QualifiedWithdrawFunds == "" &&
      transferV2Section2QualifiedFundsTransfered == ""
    ) {
      return "blank"
    } else if (
      // transferV2Section2QualifiedAgeRmdTransfer == "" ||
      transferV2Section2QualifiedTypeOfAccount == "" ||
      transferV2Section2QualifiedLiquidateTransfer == "" ||
      transferV2Section2QualifiedWithdrawFunds == "" ||
      transferV2Section2QualifiedFundsTransfered == ""
    ) {
      return "partially"
    }
  }

  const NonQualifiedSectionV2Validation = () => {
    const {
      transferV2Section2NonQualified1035Exchange,
      transferV2Section2NonQualified1035ExchangeOtherPercentage,
      transferV2Section2NonQualified1035ExchangeOtherAmount,
    } = rolloverTransferExchange

    if ((transferV2Section2NonQualified1035Exchange !== "")) {
      if (transferV2Section2NonQualified1035Exchange == "partial exchange" &&
        (transferV2Section2NonQualified1035ExchangeOtherPercentage !== "" || transferV2Section2NonQualified1035ExchangeOtherAmount !== "")) {
        return "filled"
      } else if (transferV2Section2NonQualified1035Exchange !== "partial exchange") {
        return "filled"
      } else {
        return "partially"
      }
    } else if (transferV2Section2NonQualified1035Exchange == "") {
      return "blank"
    }
  }

  const IsJointOwnerRequired = () => {
    if (
      (jointOwnerFullName != "" && jointOwnerFullName != null) ||
      (jointOwnerSSN != "" && jointOwnerSSN != null) ||
      (jointOwnerGender != "" && jointOwnerGender != null) ||
      (jointOwnerDOB != "" && jointOwnerDOB != null) ||
      (jointOwnerStreetAddress != "" && jointOwnerStreetAddress != null) ||
      (jointOwnerZip != "" && jointOwnerZip != null) ||
      (jointOwnerCity != "" && jointOwnerCity != null) ||
      (jointOwnerState != "" && jointOwnerState != null) ||
      (jointOwnerEmail != "" && jointOwnerEmail != null) ||
      (jointOwnerRelationToAnnuitant != "" && jointOwnerRelationToAnnuitant != null) ||
      (jointOwnerPhone != "" && jointOwnerPhone != null)
    ) {
      return true
    } else {
      return false
    }
  }
  const IsOwnerRequired = () => {
    let ownerFields = [
      "ownerFullName",
      "ownerSSN",
      "ownerGender",
      "ownerDOB",
      "ownerStreetAddress",
      "ownerZip",
      "ownerCity",
      "ownerState",
      "ownerEmail",
      "ownerRelationToAnnuitant",
      "ownerPhone"
    ]

    let isJointOwnerFilled = false
    for (let i = 0; i < ownerFields.length; i++) {
      if (individualAnnuityApplication[ownerFields[i]] !== "" && individualAnnuityApplication[ownerFields[i]] !== null) {
        isJointOwnerFilled = true
      }
    }

    return isJointOwnerFilled

  }

  const Validation = () => {
    const { individualAnnuityApplication, producerDisclosureForm } = state;
    const {
      annuitantFullName,
      annuitantSSN,
      annuitantGender,
      annuitantDOB,
      annuitantStreetAddress,
      annuitantZip,
      annuitantCity,
      annuitantState,
      annuitantPhone,
      annuitantEmail,
    } = individualAnnuityApplication;

    // plans applied for
    const { annuitantPlan, planRiders } = individualAnnuityApplication;
    const {
      producerFirstName,
      producerLastName,
      producerBusinessName,
      producerWebsite,
      producerBusinessMailingAddress,
      producerCity,
      producerState,
      producerZip,
      producerTelephone,
      producerEmail,
      producerNationalNumber,
      producerInStateOf,
      producerCustomerFirstName,
      producerCustomerLastName,
      producerOfferedProducts,
      producerNonInsuranceProducts,
      producerAuthorizedToSell,
      producerAuthorizedToSellDescription,
      producerPaidForWork,
      producerPaidForWorkCommissionDescription,
      producerPaidForWorkOtherDescription } = producerDisclosureForm
    let temp = [];
    let returnObj = {};


    if (annuitantFullName === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Annuitant Full Name",
        type: "Empty Field"
      });
    }
    if (annuitantSSN === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Annuitant SSN/TIN",
        type: "Empty Field"
      });
    }
    if (annuitantGender === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Annuitant Gender",
        type: "Empty Field"
      });
    }
    if (annuitantDOB === "" || annuitantDOB === null) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Annuitant Date of Birth",
        type: "Empty Field"
      });
    }
    if (annuitantStreetAddress === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Annuitant Street Address",
        type: "Empty Field"
      });
    }
    if (annuitantZip === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Annuitant Zip",
        type: "Empty Field"
      });
    }
    if (annuitantCity === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Annuitant City",
        type: "Empty Field"
      });
    }
    if (annuitantState === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Annuitant State",
        type: "Empty Field"
      });
    }
    if (annuitantPhone === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Annuitant Phone",
        type: "Empty Field"
      });
    }
    if (annuitantEmail === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Annuitant Email",
        type: "Empty Field"
      });
    }
    if (
      isValidEmail(annuitantEmail) === false &&
      annuitantEmail.length > 0 &&
      annuitantEmail !== ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Email Id is not valid",
        type: "Incorrect Details"
      });
    }
    if (isValidZip(annuitantZip) === false && annuitantZip.length > 0) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "ZIP number should only contain 5 digits",
        type: "Incorrect Details"
      });
    }
    if (annuitantPhone?.length > 0 && validatePhoneNumberLatest(annuitantPhone) === false) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Phone Number is not valid",
        type: "Incorrect Details"
      });
    }

    if (IsOwnerRequired()) {

      if (ownerFullName === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Owner Full Name",
          type: "Empty Field"
        });
      }
      if (ownerSSN === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Owner SSN/TIN",
          type: "Empty Field"
        });
      }
      if (ownerGender === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Owner Gender",
          type: "Empty Field"
        });
      }
      if (ownerDOB === "" || ownerDOB === null) {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Owner Date of Birth",
          type: "Empty Field"
        });
      }
      if (ownerStreetAddress === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Owner Street Address",
          type: "Empty Field"
        });
      }
      if (ownerZip === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Owner Zip",
          type: "Empty Field"
        });
      }
      if (ownerCity === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Owner City",
          type: "Empty Field"
        });
      }
      if (ownerState === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Owner State",
          type: "Empty Field"
        });
      }
      if (ownerEmail === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Owner Email",
          type: "Empty Field"
        });
      }
      if (ownerRelationToAnnuitant === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Owner Relation To Annuitant",
          type: "Empty Field"
        });
      }
      if (ownerPhone === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Owner Phone Number",
          type: "Empty Field"
        });
      }
      if (ownerPhone?.length > 0 && validatePhoneNumberLatest(ownerPhone) === false) {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "2",
          field: "Phone Number is not valid",
          type: "Incorrect Details"
        });
      }
    }

    if (isValidZip(ownerZip) === false && ownerZip.length > 0) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "2",
        field: "ZIP number only contains 5 digits",
        type: "Incorrect Details"
      });
    }
    if (
      isValidEmail(ownerEmail) === false &&
      ownerEmail.length > 0 &&
      ownerEmail !== ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "2",
        field: "Email Id is not valid",
        type: "Incorrect Details"
      });
    }

    if (IsJointOwnerRequired()) {
      if (jointOwnerFullName === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "3",
          field: "Joint Owner Full Name",
          type: "Empty Field"
        });
      }
      if (jointOwnerSSN === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "3",
          field: "Joint Owner SSN/TIN",
          type: "Empty Field"
        });
      }
      if (jointOwnerGender === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "3",
          field: "Joint Owner Gender",
          type: "Empty Field"
        });
      }
      if (jointOwnerDOB === "" || jointOwnerDOB === null) {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "3",
          field: "Joint Owner Date of Birth",
          type: "Empty Field"
        });
      }
      if (jointOwnerStreetAddress === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "3",
          field: "Joint Owner Street Address",
          type: "Empty Field"
        });
      }
      if (jointOwnerZip === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "3",
          field: "Joint Owner Zip",
          type: "Empty Field"
        });
      }
      if (jointOwnerCity === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "3",
          field: "Joint Owner City",
          type: "Empty Field"
        });
      }
      if (jointOwnerState === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "3",
          field: "Joint Owner State",
          type: "Empty Field"
        });
      }
      if (jointOwnerEmail === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "3",
          field: "Joint Owner Email",
          type: "Empty Field"
        });
      }
      if (jointOwnerRelationToAnnuitant === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "3",
          field: "Joint Owner Relation To Annuitant",
          type: "Empty Field"
        });
      }
      if (jointOwnerPhone === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "3",
          field: "Joint Owner Phone Number",
          type: "Empty Field"
        });
      }
    }

    if (
      isValidEmail(jointOwnerEmail) === false &&
      jointOwnerEmail.length >= 0 &&
      jointOwnerEmail !== ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "3",
        field: "Email Id is not valid",
        type: "Incorrect Details"
      });
    }
    if (isValidZip(jointOwnerZip) === false && jointOwnerZip.length > 0) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "3",
        field: "ZIP number only contains 5 digits",
        type: "Incorrect Details"
      });
    }
    // 10th january 4th section validation

    if (annuitantPlan.length == 0) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "4",
        field: "Please select atleast one annuity plan",
        type: "Empty Field"
      });
      // } else if (planRiders.length === 0) {

      //   returnObj["status"] = false;
      //   temp.push({
      //     page: "Individual Annuity Application",
      //     section: "4",
      //     field: "Please select atleast one annuity plan",
      //   });

    } else {
    }

    const tempBeneficiariesf1 = () => {
      let resultPrimary = beneficiariesPrimary.map((index, i) => {
        if (
          index.primaryField === "" &&
          index.relationshipField === "" &&
          index.percentageField === "" &&
          index.genderField === ""
        ) {
          return true;
        } else if (
          index.primaryField !== "" &&
          index.relationshipField !== "" &&
          index.percentageField !== "" &&
          index.genderField !== ""
        ) {
          return true;
        } else if (
          index.primaryField === "" ||
          index.relationshipField === "" ||
          index.percentageField === "" ||
          index.genderField === ""
        ) {
          return false;
        }
      });

      if (resultPrimary.indexOf(false) > -1) {
        return true
      } else {
        return false
      }
    };

    const tempbeneficiariesContigentf2 = () => {
      let resultContigent = beneficiariesContigent.map((index, i) => {
        if (
          index.contigentField === "" &&
          index.relationshipField === "" &&
          index.percentageField === "" &&
          index.genderField === ""
        ) {
          return false;
        } else if (
          index.contigentField !== "" &&
          index.relationshipField !== "" &&
          index.percentageField !== "" &&
          index.genderField !== ""
        ) {
          return false;
        } else if (
          index.contigentField !== "" ||
          index.relationshipField !== "" ||
          index.percentageField !== "" ||
          index.genderField !== ""
        ) {
          return true;
        }
      });

      if (resultContigent.indexOf(true) > -1) {
        return true
      } else {
        return false
      }
    };

    const isContingentPercentage = () => {
      let qq;
      let result = beneficiariesContigent.map((index, i) => {
        if (index.percentageField === "") {
          return true;
        } else {
          return false;
        }
      });
      qq = result.indexOf(false) > -1 ? true : false;
      return qq;
    };

    const tempBeneficiariespersentage = () => {
      let count = 0
      beneficiariesPrimary?.map((obj) => {
        if (obj.percentageField !== "" && obj.percentageField !== null && obj.percentageField !== -1) {
          count += parseFloat(obj.percentageField)
        }
      })
      return count === 100 ? true : false;
    };

    const tempbeneficiariesContigentpersentage = () => {
      let count = 0
      beneficiariesContigent?.map((obj) => {
        if (obj.percentageField !== "" && obj.percentageField !== null && obj.percentageField !== -1) {
          count += parseFloat(obj.percentageField)
        }
      })
      return (count === 100 || count == 0) ? true : false;
    };

    if (tempBeneficiariesf1()) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "5",
        field: "Primary: All feilds are mandatory of this section",
        type: "Empty Field"
      });
    } else if (tempBeneficiariespersentage() === false) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "5",
        field: "Primary  Beneficiaries Percentage should not be more than or less than 100%",
        type: "Empty Field"
      });
    }

    if (tempbeneficiariesContigentf2()) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "5",
        field: "Contigent: All feilds are mandatory of this section",
        type: "Empty Field"
      });
    }
    else if (isContingentPercentage() === true && tempbeneficiariesContigentpersentage() === false) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "5",
        field: "Contigent Beneficiaries Percentage should not be more than or less than 100%",
        type: "Empty Field"
      });
    }

    if (
      (individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected !== "" &&
        individualAnnuityApplication.selectedNonQualifiedOptions.length > 0 &&
        amountPaidWithApplication === "")
    ) {
      if (expectedPremiumField === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "6",
          field: "Enter any one Amount Paid With Application or Expected Premium Field",
          type: "Empty Field"
        });
      }
    } else if (
      (individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected !== "" &&
        individualAnnuityApplication.selectedNonQualifiedOptions.length > 0 &&
        (individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("transfer") > -1 ||
          individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("rollover") > -1 ||
          individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("1035 exchange") > -1
        )
        && expectedPremiumField === "")
    ) {
      if (amountPaidWithApplication === "") {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "6",
          field: "Enter any one Amount Paid With Application or Expected Premium Field",
          type: "Empty Field"
        });
      }
    }

    if (flagQualifiedOrNonQualifiedSelected === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "6",
        field: "Select atleast One Premimum and Tax Qualification Status",
        type: "Empty Field"
      });
    }

    if (
      flagQualifiedOrNonQualifiedSelected !== "" &&
      selectedNonQualifiedOptions?.length == 0
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "6",
        field: "Select atleast One Plan in Tax Qualification Status",
        type: "Empty Field"
      });
    }
    if (
      flagQualifiedOrNonQualifiedSelected !== "" &&
      (selectedNonQualifiedOptions?.length > 0 && selectedQualifiedOptionsGroup2 === "")
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "6",
        field: "Select atleast One Type of Plan in Tax Qualification Status",
        type: "Empty Field"
      });
    }

    if (flagHaveExistingLifeInsurance === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "7",
        field: "Please select checkbox for any existing life insurance policies you have",
        type: "Empty Field"
      });
    }

    if (reasonOfApplicationToChangeLifeInsurance === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "7",
        field: "Please select checkbox Is this Application intended to change any life insurance or contract",
        type: "Empty Field"
      });
    }

    if (
      reasonOfApplicationToChangeLifeInsurance === "yes" &&
      ReplacementQuestionDataArrayValidation() === false
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "7",
        field: "Atleast one row should be filled for Company Name of Policy Replaced and Policy or Contract Number",
        type: "Empty Field"
      });
    }


    if (
      suitabilityAnalysis.ownerFullName === "" ||
      ownerCurrentAge === "" ||
      suitabilityAnalysis.ownerGender === "" ||
      ownerEmploymentStatus === "" ||
      ownerCurrentOccupation === "" ||
      ownerDependents === ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "1",
        field: "All Personal information must be filled",
        type: "Empty Field"
      });
    }

    if (
      ownerAnnualHouseholdIncome === "" ||
      ownerSourceOfIncome === "" ||
      ownerTotalAssets === "" ||
      ownerTotalLiquidAssets === "" ||
      ownerTotalOutstandingDebt === "" ||
      ownerIncomeTaxBracket === ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "2",
        field: "All fields of Financial Information Of Owner must be filled",
        type: "Empty Field"
      });
    }
    if (
      flagOwnerIncomeCoverLivingAndMedicalExpenses === "" ||
      flagOwnerExpectChangesInLivingExpenses === "" ||
      flagOwnerAnticipateChangesInMedicalExpenses === "" ||
      flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
      "" ||
      flagOwnerHaveEmergencyFundForUnexpectedExpenses === "" ||
      ownerOtherInvestments.length === 0 ||
      ownerRiskTolerance === ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "3",
        field: "All fields of The Product Purchase Of Owner must be filled",
        type: "Empty Field"
      });
    }
    if (
      (flagOwnerIncomeCoverLivingAndMedicalExpenses === "no" &&
        explainOwnerIncomeCoverLivingAndMedicalExpenses === "") ||
      (flagOwnerExpectChangesInLivingExpenses === "yes" &&
        explainOwnerExpectChangesInLivingExpenses === "") ||
      (flagOwnerAnticipateChangesInMedicalExpenses === "yes" &&
        explainOwnerAnticipateChangesInMedicalExpenses === "") ||
      (flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
        "no" &&
        explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
        "") ||
      (flagOwnerHaveEmergencyFundForUnexpectedExpenses === "no" &&
        explainOwnerHaveEmergencyFundForUnexpectedExpenses === "")
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "3",
        field: "Please fill all the fields of explain for section 3",
        type: "Empty Field"
      });
    }
    if (
      reasonForPurchasingProduct.length === 0 ||
      flagOwnerPlanToWithdrawFundInSurrenderPeriod === "" ||
      (flagOwnerPlanToWithdrawFundInSurrenderPeriod === "yes" &&
        explainOwnerPlanToWithdrawFundInSurrenderPeriod === "") ||
      ownerAnticipateNeedingAccessToFunds === "" ||
      sourceOfFundsToPurchaseThisProduct.length === 0
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "4",
        field: "All fields of Existing Account Information must be filled",
        type: "Empty Field"
      });
    }
    if (flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "5",
        field: "All fields of Existing Account Information must be filled",
        type: "Empty Field"
      });
    }
    if (
      flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct ===
      "yes" &&
      (howLongHasTheLifeInsuranceBeenInForce === "" ||
        flagSurrenderChargeOnExistingProduct === "" ||
        typeOfExistingProduct === "" ||
        interestRateForExistingProduct === "" ||
        flagTakingRequiredMinimumDistributionsFromExistingProduct === "" ||
        flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution === "")
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "5",
        field: "All fields of Existing Account Information must be filled",
        type: "Empty Field"
      });
    }
    if (
      flagSurrenderChargeOnExistingProduct === "yes" &&
      (currentSurrenderChargeOnExistingProduct === "" ||
        expirationOfCurrentSurrenderCharge === "") &&
      flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct ===
      "yes"
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "5",
        field:
          "What is current charge and when it will expire fields are required",
        type: "Empty Field"
      });
    }
    if (
      flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution ===
      "yes" &&
      explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution === ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "5",
        field: "For question 7, please describe",
        type: "Empty Field"
      });
    }
    if (agentAcknowledgment === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "7",
        field: "Please fill Agent's Acknowledgment section",
        type: "Empty Field"
      });
    }

    if (ConditionToShowReplacementForm() === true) {
      if (
        flagDiscontinuingPremiumPayment === "" ||
        flagPayForPremiumPolicy === ""
      ) {
        returnObj["status"] = false;
        temp.push({
          page: "Notice of Replacement",
          section: "1",
          field: "Please fill all fields",
          type: "Empty Field"
        });
      }
      if (
        (flagDiscontinuingPremiumPayment === "yes" ||
          flagPayForPremiumPolicy === "yes") &&
        (CheckInsuredPolicyFieldArray() === false)
      ) {
        returnObj["status"] = false;
        temp.push({
          page: "Notice of Replacement",
          section: "1",
          field: "Question C is required ",
          type: "Empty Field"
        });
      }
      if (
        (flagDiscontinuingPremiumPayment === "yes" ||
          flagPayForPremiumPolicy === "yes") &&
        (reasonForReplacement == "")
      ) {
        returnObj["status"] = false;
        temp.push({
          page: "Notice of Replacement",
          section: "1",
          field: "Question D is required ",
          type: "Empty Field"
        });
      }
    }

    if (transferExchangeVersion === 7) {
      if ((TransferExchangeBasicV2Validation() == "blank" && TransferExchangePlansV2Validation() == "blank")
        || (TransferExchangeBasicV2Validation() == "filled" && TransferExchangePlansV2Validation() == "filled")) {
        if (TransferExchangeBasicV2Validation() == "filled") {
          if (TransferExchangeOwnerInfoValidation() == true) {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "1",
              field: "Please fill all fields",
              type: "Empty Field"
            });
          }
          if (isValidZip(rolloverTransferExchange?.transferV2Section1CompanyZip) === false &&
            rolloverTransferExchange?.transferV2Section1CompanyZip.length > 0 &&
            rolloverTransferExchange?.transferV2Section1CompanyZip !== "") {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "1",
              field: "ZIP number should contains 5 digits",
              type: "Incorrect Details"
            });
          }
          if (
            validatePhoneNumber(rolloverTransferExchange.transferV2Section1CompanyPhone) === false &&
            rolloverTransferExchange.transferV2Section1CompanyPhone?.length > 0 &&
            rolloverTransferExchange.transferV2Section1CompanyPhone !== "") {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "1",
              field: "Phone Number is not valid",
              type: "Incorrect Details"
            });
          }
        }
        if (TransferExchangeOwnerInfoValidation() == true) {
          returnObj["status"] = false;
          temp.push({
            page: "Transfer or Exchange Request",
            section: "1",
            field: "Please fill all fields",
            type: "Empty Field"
          });
        }
      } else {
        if ((TransferExchangeBasicV2Validation() === "partially" && TransferExchangePlansV2Validation() == "partially") ||
          (TransferExchangeBasicV2Validation() === "blank" && TransferExchangePlansV2Validation() == "partially") ||
          (TransferExchangeBasicV2Validation() === "partially" && TransferExchangePlansV2Validation() == "blank")
        ) {
          if (TransferringCompanyValidation()) {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "1",
              field: "Information of transferring company",
              type: "Empty Field"
            });
          }
          if (TransferExchangeOwnerInfoValidation() == true) {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "1",
              field: "Please fill all fields",
              type: "Empty Field"
            });
          }
          if (VehicleInfoValidation()) {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "1",
              field: "Current Investment Vehicle",
              type: "Empty Field"
            });
          }
          if (TransferExchangePlansV2Validation() == "blank") {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "2",
              field: "Select any one Plan",
              type: "Empty Field"
            });
          }
          if (rolloverTransferExchange.transferV2Section2Plan == "Qualified Plans" && QualifiedSectionV2Validation() !== "filled") {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "2",
              field: "Please Fill all the required fields of Transfer/Rollover",
              type: "Empty Field"
            });
          }
          if (rolloverTransferExchange.transferV2Section2Plan == "Non-Qualified Plans" && NonQualifiedSectionV2Validation() !== "filled") {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "2",
              field: "Please Fill all the required fields of 1035 Exchange",
              type: "Empty Field"
            });
          }
        }
        if (TransferExchangePlansV2Validation() == "filled" && (TransferExchangeBasicV2Validation() == "blank" || TransferExchangeBasicV2Validation() == "partially")) {
          if (TransferringCompanyValidation()) {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "1",
              field: "Information of transferring company",
              type: "Empty Field"
            });
          }
          if (VehicleInfoValidation()) {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "1",
              field: "Current Investment Vehicle",
              type: "Empty Field"
            });
          }
          if (TransferExchangeOwnerInfoValidation() == true) {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "1",
              field: "Please fill all fields",
              type: "Empty Field"
            });
          }
        }
        if (TransferExchangeBasicV2Validation() == "filled" && (TransferExchangePlansV2Validation() == "blank" || TransferExchangePlansV2Validation() == "partially")) {
          if (TransferExchangeOwnerInfoValidation() == true) {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "1",
              field: "Please fill all fields",
              type: "Empty Field"
            });
          }
          if (TransferExchangePlansV2Validation() == "blank") {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "2",
              field: "Select any one Plan",
              type: "Empty Field"
            });
          }
          if (rolloverTransferExchange.transferV2Section2Plan == "Qualified Plans" && QualifiedSectionV2Validation() !== "filled") {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "2",
              field: "Please Fill all the required fields of Transfer/Rollover",
              type: "Empty Field"
            });
          }
          if (rolloverTransferExchange.transferV2Section2Plan == "Non-Qualified Plans" && NonQualifiedSectionV2Validation() !== "filled") {
            returnObj["status"] = false;
            temp.push({
              page: "Transfer or Exchange Request",
              section: "2",
              field: "Please Fill all the required fields of 1035 Exchange",
              type: "Empty Field"
            });
          }
        }
      }
    } else {
      if ((BasicInformationValidation() == "blank" && PlansValidation() == "blank" && flagSignatureDigitalOrPhysical == "")
        ||
        (BasicInformationValidation() == "filled" && PlansValidation() == "filled" && flagSignatureDigitalOrPhysical !== "")
      ) {
        // return true
      } else {
        if ((BasicInformationValidation() === "filled" || flagSignatureDigitalOrPhysical !== "") &&
          (PlansValidation() == "blank" || PlansValidation() == "partially")) {
          returnObj["status"] = false;
          temp.push({
            page: "Transfer or Exchange Request",
            section: "2",
            field: "Please fill all fields",
            type: "Empty Field"
          });
        }
        if ((PlansValidation() === "filled" || flagSignatureDigitalOrPhysical !== "") &&
          (BasicInformationValidation() == "blank" || BasicInformationValidation() == "partially")) {
          returnObj["status"] = false;
          temp.push({
            page: "Transfer or Exchange Request",
            section: "1",
            field: "Please fill all fields",
            type: "Empty Field"
          });
        }
        if (
          (BasicInformationValidation() === "partially" && PlansValidation() == "partially") ||
          (BasicInformationValidation() === "blank" && PlansValidation() == "partially") ||
          (BasicInformationValidation() === "partially" && PlansValidation() == "blank")
        ) {
          returnObj["status"] = false;
          temp.push({
            page: "Transfer or Exchange Request",
            section: "1",
            field: "Please fill all fields",
          });
          temp.push({
            page: "Transfer or Exchange Request",
            section: "2",
            field: "Please fill all fields",
          });
        }
        if (flagSignatureDigitalOrPhysical === "") {
          temp.push({
            page: "Transfer or Exchange Request",
            section: "3",
            field: "Please fill all fields",
            type: "Empty Field"
          });
        }
      }
    }

    if (
      isValidZip(transferOrExchangeRequest?.ownerZip) === false &&
      transferOrExchangeRequest?.ownerZip.length > 0 &&
      transferOrExchangeRequest?.ownerZip !== ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Transfer or Exchange Request",
        section: "1",
        field: "ZIP number should sontains 5 digits",
        type: "Incorrect Details"
      });
    }
    if (
      isValidZip(currentInsuranceCompanyZip) === false &&
      currentInsuranceCompanyZip.length > 0 &&
      currentInsuranceCompanyZip !== ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Transfer or Exchange Request",
        section: "1",
        field: "ZIP number should sontains 5 digits",
        type: "Incorrect Details"
      });
    }

    if (
      validatePhoneNumber(currentInsuranceCompanyPhone) === false &&
      currentInsuranceCompanyPhone !== "" &&
      currentInsuranceCompanyPhone.length > 0
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Transfer or Exchange Request",
        section: "1",
        field: "Phone Number is not valid",
        type: "Incorrect Details"
      });
    }


    if (disclosureFormVersion == 9) {
      if (flagAnnuityClosureV2 == "") {
        returnObj["status"] = false;
        temp.push({
          page: "Annuity Disclosure",
          section: "6",
          field: "Please check any one of the safeguard plus annuity disclosure",
          type: "Empty Field"
        });
      }
      // if (flagAnnuityClosureBackupWithout == "no") {
      //   returnObj["status"] = false;
      //   temp.push({
      //     page: "Annuity Disclosure",
      //     section: "6",
      //     field: "Please confirm if you are subject to backup withholding",
      //     type: "Empty Field"
      //   });
      // }
    } else {
      if (flagAnnuityClosure == "") {
        returnObj["status"] = false;
        temp.push({
          page: "Annuity Disclosure",
          section: "6",
          field: "Please check any one of the safeguard plus annuity disclosure",
          type: "Empty Field"
        });
      }
    }
    // producer disclosure validations
    if (flagShowProducerDisclosure) {
      const ProducerInfoValidate = () => {
        if (
          producerFirstName !== "" &&
          producerLastName !== "" &&
          producerBusinessName !== "" &&
          // producerWebsite !== "" &&
          producerBusinessMailingAddress !== "" &&
          producerCity !== "" &&
          producerState !== "" &&
          producerZip !== "" &&
          producerTelephone !== "" &&
          producerEmail !== "" &&
          producerNationalNumber !== "" &&
          producerInStateOf !== ""
        ) {
          return true
        } else {
          return false
        }
      }
      const ProducerCustomerInfoValidate = () => {
        if (producerCustomerFirstName !== "" && producerCustomerLastName !== "") {
          return true
        } else {
          return false
        }
      }
      const producerOfferProductsValidate = () => {
        if (producerOfferedProducts.length > 0) {
          return true
        } else {
          return false
        }
      }
      // const producerNonInsuranceProductsValidate = () => {
      //   if (producerNonInsuranceProducts.length > 0) {
      //     return true
      //   } else {
      //     return false
      //   }
      // }
      const producerAuthorisedToSellValidate = () => {
        if (
          producerAuthorizedToSell.length === 0
        ) {
          return false
        } else if (
          producerAuthorizedToSell.length !== 0
        ) {
          if (producerAuthorizedToSell.indexOf("more insurer") < 0) {
            return true
          } else {
            if (producerAuthorizedToSellDescription !== "") {
              return true
            } else {
              return false
            }
          }
        } else {
          return false
        }
      }
      const producerPaidForWorkValidate = () => {
        if (
          producerPaidForWork.length === 0
        ) {
          return false
        } else if (
          producerPaidForWork.length !== 0
        ) {
          if (producerPaidForWork.indexOf("commission") < 0 && producerPaidForWork.indexOf("other")) {
            return true
          } else {
            // if (producerPaidForWork.includes("commission") && producerPaidForWorkCommissionDescription === "") {
            //   return false
            // } else if (producerPaidForWork.includes("other") > 0 && producerPaidForWorkOtherDescription === "") {
            //   return false
            // } else {
            //   return true
            // }
            return true
          }
        } else {
          return false
        }
      }
      if (ProducerInfoValidate() == false) {
        returnObj["status"] = false;
        temp.push({
          page: "Producer Disclosure",
          section: "1",
          field: "Please fill all required fields",
          type: "Empty Field"
        });
      }
      if (
        isValidEmail(producerEmail) === false &&
        producerEmail.length > 0 &&
        producerEmail !== ""
      ) {
        returnObj["status"] = false;
        temp.push({
          page: "Producer Disclosure",
          section: "1",
          field: "Email Id is not valid",
          type: "Incorrect Details"
        });
      }
      if (isValidZip(producerZip) === false && producerZip.length > 0) {
        returnObj["status"] = false;
        temp.push({
          page: "Producer Disclosure",
          section: "1",
          field: "ZIP number only contains 5 digits",
          type: "Incorrect Details"
        });
      }
      if (producerTelephone?.length > 0 && validatePhoneNumberLatest(producerTelephone) === false) {
        returnObj["status"] = false;
        temp.push({
          page: "Producer Disclosure",
          section: "1",
          field: "Phone Number is not valid",
          type: "Incorrect Details"
        });
      }
      if (isValidWebsite(producerWebsite) === false && producerWebsite.length > 0 && producerWebsite !== "") {
        returnObj["status"] = false;
        temp.push({
          page: "Producer Disclosure",
          section: "1",
          field: "Website is not valid",
          type: "Incorrect Details"
        });
      }
      if (isValidNPN(producerNationalNumber) === false && producerNationalNumber.length > 0 && producerNationalNumber !== "") {
        returnObj["status"] = false;
        temp.push({
          page: "Producer Disclosure",
          section: "1",
          field: "National Number should not exceed 10 digits",
          type: "Incorrect Details"
        });
      }
      if (ProducerCustomerInfoValidate() == false) {
        returnObj["status"] = false;
        temp.push({
          page: "Producer Disclosure",
          section: "2",
          field: "Please fill all required fields",
          type: "Empty Field"
        });
      }
      // if (producerOfferProductsValidate() == false || producerNonInsuranceProductsValidate() === false) {
      //   returnObj["status"] = false;
      //   temp.push({
      //     page: "Producer Disclosure",
      //     section: "3",
      //     field: "Please fill all required fields",
      //     type: "Empty Field"
      //   });
      // }
      // if (producerNonInsuranceProductsValidate() == false) {
      //   returnObj["status"] = false;
      //   temp.push({
      //     page: "Producer Disclosure",
      //     section: "7",
      //     field: "Please fill all required fields",
      //     type: "Empty Field"
      //   });
      // }
      // if (producerAuthorisedToSellValidate() == false) {
      //   returnObj["status"] = false;
      //   temp.push({
      //     page: "Producer Disclosure",
      //     section: "4",
      //     field: "Please fill all required fields",
      //     type: "Empty Field"
      //   });
      // }
      if (producerPaidForWorkValidate() === false) {
        returnObj["status"] = false;
        temp.push({
          page: "Producer Disclosure",
          section: "5",
          field: "Please fill all required fields",
          type: "Empty Field"
        });
      }
    }
    returnObj["data"] = temp;
    return returnObj;
  };

  useEffect(() => {
    if (
      stateToShowNoticeOfReplacement !== "Tennessee" &&
      stateToShowNoticeOfReplacement !== ""
    ) {
      if (flagQualifiedOrNonQualifiedSelected === "non - qualified") {
        if (
          selectedNonQualifiedOptions?.length == 1 &&
          selectedNonQualifiedOptions.indexOf("cash with application") > -1 &&
          selectedQualifiedOptionsGroup2 !== ""
        ) {
          SetWholeNoticeOfReplacement({
            flagDiscontinuingPremiumPayment: "",
            flagPayForPremiumPolicy: "",
            insurerPolicyDetailFieldArray: [
              {
                id: 1,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 2,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 3,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 4,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
            ],
            reasonForReplacement: ""
          });
        }
      } else if (flagQualifiedOrNonQualifiedSelected === "Qualified") {
        if (
          selectedNonQualifiedOptions?.length == 1 &&
          selectedNonQualifiedOptions.indexOf("cash with application") > -1 &&
          selectedQualifiedOptionsGroup2 !== ""
        ) {
          SetWholeNoticeOfReplacement({
            flagDiscontinuingPremiumPayment: "",
            flagPayForPremiumPolicy: "",
            insurerPolicyDetailFieldArray: [
              {
                id: 1,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 2,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 3,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 4,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
            ],
            reasonForReplacement: ""
          });
        }
      }
    }
  }, [
    stateToShowNoticeOfReplacement,
    flagQualifiedOrNonQualifiedSelected,
    selectedNonQualifiedOptions,
    selectedQualifiedOptionsGroup2,
    // selectedQualifiedOptions,
  ]);

  useEffect(() => {
    if (state.transferOrExchangeRequestFlag === false) {
      SetWholeTransferExchangeRequest({
        ownerFullName: "",
        ownerSSN: "",
        ownerStreetAddress: "",
        ownerZip: "",
        ownerCity: "",
        ownerState: "",
        ownerMaritalStatus: "",
        annuitantOrInsured: "",
        currentInsuranceCompany: "",
        currentInsuranceCompanyPhone: "",
        currentInsuranceCompanyAccountNumber: "",
        currentInsuranceCompanyStreetAddress: "",
        currentInsuranceCompanyZip: "",
        currentInsuranceCompanyCity: "",
        currentInsuranceCompanyState: "",
        typeOfAccount: "",
        otherTypeOfAccount: "",
        ownerExistingAnnuity: "",
        plans: "",
        flagNonQualifiedOwnerWantWithdrawalToExchange: "",
        explainNonQualifiedOwnerWantWithdrawalToExchange: "",
        flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal: "",
        flagSignatureDigitalOrPhysical: "",
        qualifiedTransferOrExchangeExistingAccountAllOrPartial: "",
        qualifiedTransferOrExchangeExistingAccountPartialAmount: "",
        qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate:
          "",
        qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField: "",
        qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy:
          "",
        qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField:
          "",
        qualifiedTypeOfTransfer: "",
        qualifiedQualifyingEventForDirectRolloverFunds: "",
        flagQualifiedOwnerReachedAge72OrMoreInThisYear: "",
        flagQualifiedOwnerSatisfiedRMDFromDistributingPlan: "",
        qualifiedInternalExchangeOfExistingAnnuityName: "",
        flagQualifiedOwnerWantRMDBeforeCompletingExchange: "",
        flagQualifiedOwnerWantToContinueExistingRMDSchedule: "",
      });
    }
  }, [state.transferOrExchangeRequestFlag]);

  const data = {
    main_form_id: 1,
    iaa_section1_annuitant_full_name: annuitantFullName,
    iaa_section1_annuitant_ssn: annuitantSSN,
    iaa_section1_annuitant_dob:
      new Date(annuitantDOB).toDateString() === "Invalid Date"
        ? ""
        : `${new Date(annuitantDOB).getMonth() + 1}/${new Date(
          annuitantDOB
        ).getDate()}/${new Date(annuitantDOB).getFullYear()}`,
    iaa_section1_annuitant_address: annuitantStreetAddress,
    iaa_section1_annuitant_city: annuitantCity,
    iaa_section1_annuitant_state: annuitantState,
    iaa_section1_annuitant_zip: annuitantZip,
    iaa_section1_annuitant_phone: GetPhoneNumberPayloadFormate(annuitantPhone),
    iaa_section1_annuitant_mail: annuitantEmail,
    iaa_section1_annuitant_gender: {
      value: annuitantGender,
      extra_value: "",
    },

    iaa_section2_owner_full_name: ownerFullName,
    iaa_section2_owner_address: ownerStreetAddress,
    iaa_section2_owner_city: ownerCity,
    iaa_section2_owner_state: ownerState,
    iaa_section2_owner_zip: ownerZip,
    iaa_section2_owner_mail: ownerEmail,
    iaa_section2_owner_phone: GetPhoneNumberPayloadFormate(ownerPhone),
    iaa_section2_owner_ssn: ownerSSN,
    iaa_section2_owner_relationship_to_annuitant: ownerRelationToAnnuitant,
    iaa_section2_owner_dob:
      (new Date(ownerDOB).toDateString() === "Invalid Date" || ownerDOB === null)
        ? ""
        : `${new Date(ownerDOB).getMonth() + 1}/${new Date(
          ownerDOB
        ).getDate()}/${new Date(ownerDOB).getFullYear()}`,
    iaa_section2_owner_gender: {
      value: ownerGender,
      extra_value: "",
    },

    iaa_section3_joint_owner_full_name: jointOwnerFullName,
    iaa_section3_joint_owner_ssn: jointOwnerSSN,
    iaa_section3_joint_owner_address: jointOwnerStreetAddress,
    iaa_section3_joint_owner_city: jointOwnerCity,
    iaa_section3_joint_owner_state: jointOwnerState,
    iaa_section3_joint_owner_zip: jointOwnerZip,
    iaa_section3_joint_owner_mail: jointOwnerEmail,
    iaa_section3_joint_owner_relationship_to_annuitant: jointOwnerRelationToAnnuitant,
    iaa_section3_joint_owner_phone: GetPhoneNumberPayloadFormate(jointOwnerPhone),
    iaa_section3_joint_owner_dob:
      new Date(jointOwnerDOB).toDateString() === "Invalid Date"
        ? ""
        : `${new Date(jointOwnerDOB).getMonth() + 1}/${new Date(jointOwnerDOB).getDate()}/${new Date(jointOwnerDOB).getFullYear()}`,
    iaa_section3_joint_owner_gender: {
      value: jointOwnerGender,
      extra_value: "",
    },

    iaa_section4_planriders_annuitant_plans: {
      value: annuitantPlan,
      extra_value: "",
    },
    iaa_section4_plan_riders: {
      value: planRiders?.join(","),
      extra_value: "",
    },

    iaa_section6_premium_tax_qualification_status: {
      value: flagQualifiedOrNonQualifiedSelected,
      extra_value: ""
    },
    iaa_section6_non_qualified: {
      value: flagQualifiedOrNonQualifiedSelected == "non - qualified" ? selectedNonQualifiedOptions?.join(",") : "",
      extra_value: ""
    },
    iaa_section6_qualified: {
      value: flagQualifiedOrNonQualifiedSelected == "qualified" ? selectedNonQualifiedOptions?.join(",") : "",
      extra_value: "",
    },
    iaa_section6_type_of_plan: {
      value: selectedQualifiedOptionsGroup2,
      extra_value: otherSelectedQualifiedOptions,
    },

    iaa_section7_state_to_show_notice_of_replacement: stateToShowNoticeOfReplacement,
    iaa_section6_ammount_paid_application: amountPaidWithApplication.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    ),
    iaa_section6_expected_premium_amount: expectedPremiumField.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    ),
    iaa_section7_owner_replacement_question1: {
      value: flagHaveExistingLifeInsurance,
      extra_value: "",
    },
    iaa_section7_owner_replacement_question2: {
      value: reasonOfApplicationToChangeLifeInsurance,
      extra_value: "",
    },
    suitability_analysis_section1_owner_full_name: suitabilityAnalysis?.ownerFullName,
    suitability_analysis_section1_owner_age: ownerCurrentAge,
    suitability_analysis_section1_owner_gender: {
      value: suitabilityAnalysis?.ownerGender,
      extra_value: "",
    },
    suitability_analysis_section1_owner_emp_status: {
      value: ownerEmploymentStatus,
      extra_value: "",
    },
    suitability_analysis_section1_owner_occupation: ownerCurrentOccupation,
    suitability_analysis_section1_owner_depedents: ownerDependents,

    suitability_analysis_section1_spouse_emp_status: {
      value: spouseEmploymentStatus,
      extra_value: "",
    },
    suitability_analysis_section1_spouse_full_name: spouseFullName,
    suitability_analysis_section1_spouse_age: spouseCurrentAge,
    suitability_analysis_section1_spouse_gender: {
      value: spouseGender,
      extra_value: "",
    },
    suitability_analysis_section1_spouse_occupation: spouseCurrentOccupation,
    suitability_analysis_section1_spouse_depedents: spouseDependents,
    suitability_analysis_section2_annual_income: ownerAnnualHouseholdIncome.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    suitability_analysis_section2_source_income: ownerSourceOfIncome,
    suitability_analysis_section2_income_tax: ownerIncomeTaxBracket,
    suitability_analysis_section2_total_assets: ownerTotalAssets.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    suitability_analysis_section2_total_liquid_assets: ownerTotalLiquidAssets.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    suitability_analysis_section2_total_outstanding_debt:
      ownerTotalOutstandingDebt.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    suitability_analysis_section3_q1_medical_expenses: {
      value: flagOwnerIncomeCoverLivingAndMedicalExpenses,
      extra_value: explainOwnerIncomeCoverLivingAndMedicalExpenses,
    },
    suitability_analysis_section3_q2_living_expenses: {
      value: flagOwnerExpectChangesInLivingExpenses,
      extra_value: explainOwnerExpectChangesInLivingExpenses,
    },
    suitability_analysis_section3_q3_pocket_expenses: {
      value: flagOwnerAnticipateChangesInMedicalExpenses,
      extra_value: explainOwnerAnticipateChangesInMedicalExpenses,
    },
    suitability_analysis_section3_q4_surrender_charge_expenses: {
      value:
        flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
      extra_value:
        explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
    },
    suitability_analysis_section3_q5_emergency_fund: {
      value: flagOwnerHaveEmergencyFundForUnexpectedExpenses,
      extra_value: explainOwnerHaveEmergencyFundForUnexpectedExpenses,
    },
    suitability_analysis_section3_q6_own_investment:
    {
      value: ownerOtherInvestments.length > 0 ? ownerOtherInvestments?.join(",") : "",
      extra_value: otherOwnerInvestmentField,
    },
    suitability_analysis_section3_q7_risk_tolerance: {
      value: ownerRiskTolerance,
      extra_value: "",
    },

    suitability_analysis_section4_q_reason_for_purchase_product: {
      value:
        reasonForPurchasingProduct.length > 0
          ? reasonForPurchasingProduct.join(",")
          : "",
      extra_value: otherRasonForPurchasingThisProduct,
    },
    suitability_analysis_section4_q1_surrender_charge_period: {
      value: flagOwnerPlanToWithdrawFundInSurrenderPeriod,
      extra_value: explainOwnerPlanToWithdrawFundInSurrenderPeriod,
    },
    suitability_analysis_section4_q2_anticipate_need: {
      value: ownerAnticipateNeedingAccessToFunds,
      extra_value: ""
    },
    suitability_analysis_section4_q3_source_of_fund_purchase_product:
    {
      value: sourceOfFundsToPurchaseThisProduct?.join(","),
      extra_value: otherSourceOfFundsToPurchaseThisProduct,
    },

    suitability_analysis_section5_q1_existing_account_information: {
      value: flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct,
      extra_value: "",
    },
    suitability_analysis_section5_q2_life_insurance_or_annuity_force_year: howLongHasTheLifeInsuranceBeenInForce,
    suitability_analysis_section5_q3_surrender_charge_product: {
      value: flagSurrenderChargeOnExistingProduct,
      extra_value: `${currentSurrenderChargeOnExistingProduct},${expirationOfCurrentSurrenderCharge}`,
    },
    suitability_analysis_section5_q4_type_of_existed_product: {
      value: typeOfExistingProduct,
      extra_value: "",
    },
    suitability_analysis_section5_q5_interest_rate_existing_product: interestRateForExistingProduct,
    suitability_analysis_section5_q6_tax_qualified_annuity: {
      value: flagTakingRequiredMinimumDistributionsFromExistingProduct,
      extra_value: "",
    },
    suitability_analysis_section5_q7_tax_periodic_distributions: {
      value: flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
      extra_value:
        explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
    },

    suitability_analysis_section6_assist_purchase_product: {
      value: personsThatAssistedOwnerToPurchaseThisProduct.length > 0 ? personsThatAssistedOwnerToPurchaseThisProduct.join(",") : "",
      extra_value: otherPersonsThatAssistedOwnerToPurchaseThisProduct,
    },
    suitability_analysis_section6_relevant_information:
      additionalRelaventInformationForSuitabilityAnalysis,
    suitability_analysis_section7_agent_acknowledgement: {
      value: suitabilityAnalysis?.agentAcknowledgment,
      extra_value: "",
    },
    beneficiaries: tempBeneficiaries(),
    replacement_question_contact_data: replaceMentQuestionDataArray(),
    nor_question1a_terminating_your_existing_policy: {
      value: flagDiscontinuingPremiumPayment,
      extra_value: ""
    },
    nor_question1b_fund_from_new_policy: {
      value: flagPayForPremiumPolicy,
      extra_value: ""
    },
    nor_question1d_reason_for_replacement: reasonForReplacement,
    existing_policy: tempInsurerPolicyDetailFieldArray(),

  };

  const AddTransferExchangeData = (finalData) => {
    if (transferExchangeVersion === 7) {
      finalData["transfer_v2_section1_company_name"] = rolloverTransferExchange.transferV2Section1CompanyName
      finalData["transfer_v2_section1_company_phone"] = rolloverTransferExchange.transferV2Section1CompanyPhone
      finalData["transfer_v2_section1_company_policy_number"] = rolloverTransferExchange.transferV2Section1CompanyPolicyNumber
      finalData["transfer_v2_section1_company_address"] = rolloverTransferExchange.transferV2Section1CompanyAddress
      finalData["transfer_v2_section1_company_city"] = rolloverTransferExchange.transferV2Section1CompanyCity
      finalData["transfer_v2_section1_company_state"] = rolloverTransferExchange.transferV2Section1CompanyState
      finalData["transfer_v2_section1_company_zip"] = rolloverTransferExchange.transferV2Section1CompanyZip
      finalData["transfer_v2_section1_owner_name"] = rolloverTransferExchange.transferV2Section1OwnerName
      finalData["transfer_v2_section1_owner_ssn"] = rolloverTransferExchange.transferV2Section1OwnerSsn
      finalData["transfer_v2_section1_join_owner_name"] = rolloverTransferExchange.transferV2Section1JoinOwnerName
      finalData["transfer_v2_section1_join_owner_ssn"] = rolloverTransferExchange.transferV2Section1JoinOwnerSsn
      finalData["transfer_v2_section1_annuitant_name"] = rolloverTransferExchange.transferV2Section1AnnuitantName
      finalData["transfer_v2_section1_annuitant_ssn"] = rolloverTransferExchange.transferV2Section1AnnuitantSsn
      finalData["transfer_v2_section1_join_annuitant_name"] = rolloverTransferExchange.transferV2Section1JoinAnnuitantName
      finalData["transfer_v2_section1_join_annuitant_ssn"] = rolloverTransferExchange.transferV2Section1JoinAnnuitantSsn
      finalData["transfer_v2_section1_beneficiary_name"] = rolloverTransferExchange.transferV2Section1BeneficiaryName
      finalData["transfer_v2_section1_beneficiary_ssn"] = rolloverTransferExchange.transferV2Section1BeneficiarySsn
      finalData["transfer_v2_section1_investment_vehicle"] = {
        value: rolloverTransferExchange.transferV2Section1InvestmentVehicle,
        extra_value: rolloverTransferExchange.transferV2Section1InvestmentVehicleOther
      }

      finalData["transfer_v2_section1_existing_certificate"] = {
        value: rolloverTransferExchange.transferV2Section1ExistingCertificate,
        extra_value: ""
      }
      finalData["transfer_v2_section2_plan"] = rolloverTransferExchange.transferV2Section2Plan
      finalData["transfer_v2_section2_non_qualified_1035_exchange"] = {
        value: rolloverTransferExchange.transferV2Section2NonQualified1035Exchange,
        extra_value: rolloverTransferExchange.transferV2Section2NonQualified1035ExchangeOtherPercentage + "," +
          (rolloverTransferExchange?.transferV2Section2NonQualified1035ExchangeOtherAmount ?
            rolloverTransferExchange?.transferV2Section2NonQualified1035ExchangeOtherAmount?.replace(/$|,/gi, '') : "")
      }

      finalData["transfer_v2_section2_qualified_age_rmd_transfer"] = {
        value: rolloverTransferExchange.transferV2Section2QualifiedAgeRmdTransfer,
        extra_value: ""
      }
      finalData["transfer_v2_section2_qualified_type_of_account"] = {
        value: rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccount,
        extra_value: rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccountOther
      }

      finalData["transfer_v2_section2_qualified_liquidate_transfer_all"] = {
        value: rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer == "all" ?
          rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer : "",
        extra_value: ""
      }

      finalData["transfer_v2_section2_qualified_liquidate_transfer_partial_amount"] = {
        value: rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferAmount?.length ? "partial amount" : "",
        extra_value: rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferAmount?.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )
      }
      finalData["transfer_v2_section2_qualified_liquidate_transfer_partial_percentage"] = {
        value: rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferPercentage?.length ? "partial percentage" : "",
        extra_value: rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferPercentage
      }

      finalData["transfer_v2_section2_qualified_withdraw_funds"] = {
        value: rolloverTransferExchange.transferV2Section2QualifiedWithdrawFunds,
        extra_value: rolloverTransferExchange.transferV2Section2QualifiedWithdrawFundsOther
      }

      finalData["transfer_v2_section2_qualified_funds_transfered"] = {
        value: rolloverTransferExchange.transferV2Section2QualifiedFundsTransfered,
        extra_value: ""
      }

    } else {
      finalData["transfer_section1_item_owner_full_name"] = transferOrExchangeRequest.ownerFullName;
      finalData["transfer_section1_item_owner_address"] = transferOrExchangeRequest.ownerStreetAddress;
      finalData["transfer_section1_item_owner_city"] = transferOrExchangeRequest.ownerCity;
      finalData["transfer_section1_item_owner_state"] = transferOrExchangeRequest.ownerState;
      finalData["transfer_section1_item_owner_marital_status"] = transferOrExchangeRequest.ownerMaritalStatus;
      finalData["transfer_section1_item_owner_zip"] = transferOrExchangeRequest.ownerZip;
      finalData["transfer_section1_item_owner_annuitant"] = annuitantOrInsured;
      finalData["transfer_section1_item_owner_ssn_or_tax_id"] = transferOrExchangeRequest.ownerSSN;
      finalData["transfer_section1_item_owner_current_insurance_company"] = currentInsuranceCompany;
      finalData["transfer_section1_item_owner_phone"] = currentInsuranceCompanyPhone;

      finalData["transfer_section1_item_annuitant_account_number"] = currentInsuranceCompanyAccountNumber;
      finalData["transfer_section1_item_annuitant_address"] = currentInsuranceCompanyStreetAddress;
      finalData["transfer_section1_item_annuitant_city"] = currentInsuranceCompanyCity;
      finalData["transfer_section1_item_annuitant_state"] = currentInsuranceCompanyState;
      finalData["transfer_section1_item_annuitant_zip"] = currentInsuranceCompanyZip;
      finalData["transfer_section1_type_of_account"] = {
        value: typeOfAccount,
        extra_value: otherTypeOfAccount
      };
      finalData["transfer_section1_existing_contract_or_certificate"] = {
        value: ownerExistingAnnuity,
        extra_value: ""
      }

      finalData["transfer_section2_plan_existing_account_requested_question_1"] =
      {
        value: qualifiedTransferOrExchangeExistingAccountAllOrPartial,
        extra_value: qualifiedTransferOrExchangeExistingAccountPartialAmount
      }

      finalData["transfer_section2_plan_existing_account_requested_question_2"] =
      {
        value: qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate,
        extra_value: qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField
      }
      finalData["transfer_section2_plan_existing_account_requested_question_3"] =
      {
        value: qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy,
        extra_value: qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField
      }

      finalData["transfer_section2_plans_transfer_or_rollover"] = {
        value: qualifiedTypeOfTransfer,
        extra_value: ""
      }
      finalData["transfer_section2_direct_rollover_fund"] = {
        value: qualifiedQualifyingEventForDirectRolloverFunds,
        extra_value: ""
      }

      finalData["transfer_section2_qualified_plan_rmd_reach_age_72"] = {
        value: flagQualifiedOwnerReachedAge72OrMoreInThisYear,
        extra_value: ""
      }
      finalData["transfer_section2_qualified_plan_rmd_satisfied_distributing_plan"] = {
        value: flagQualifiedOwnerSatisfiedRMDFromDistributingPlan,
        extra_value: ""
      }
      finalData["transfer_section2_qualified_plan_internal_exchnage_annuity"] = qualifiedInternalExchangeOfExistingAnnuityName
      finalData["transfer_section2_qualified_plan_get_rmd_before_completing"] = {
        value: flagQualifiedOwnerWantRMDBeforeCompletingExchange,
        extra_value: ""
      }
      finalData["transfer_section2_qualified_plan_continue_exiting_rmd_schedule"] = {
        value: flagQualifiedOwnerWantToContinueExistingRMDSchedule,
        extra_value: ""
      }

      // Non-qualified 
      finalData["transfer_section2_non_qualified_plan_prior_exchange"] = {
        value: flagNonQualifiedOwnerWantWithdrawalToExchange,
        extra_value: explainNonQualifiedOwnerWantWithdrawalToExchange
      }
      finalData["transfer_section2_non_qualified_plan_continue_exiting_rmd_schedule"] = {
        value: flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal,
        extra_value: ""
      }
      finalData["trans_exchange_req_signature_digital_or_physical"] = {
        value: flagSignatureDigitalOrPhysical,
        extra_value: ""
      }


    }
    return finalData;
  }

  const AddDisclosureData = (finalData) => {
    if (disclosureFormVersion == 9) {
      finalData["safeguard_v2_plus_disclosure_annuity"] = {
        value: flagAnnuityClosureV2,
        extra_value: ""
      }
      finalData["safeguard_v2_plus_disclosure_backup_withholding"] = {
        value: flagAnnuityClosureBackupWithout,
        extra_value: ""
      }
    } else {
      finalData["safeguard_plus_disclosure_annuity"] = {
        value: flagAnnuityClosure,
        extra_value: ""
      }
    }
    return finalData;
  }

  const AddProducerDisclosureData = (finalData) => {
    if (flagShowProducerDisclosure) {
      finalData["producer_disclosure_section1_first_name"] = producerDisclosureForm.producerFirstName
      finalData["producer_disclosure_section1_last_name"] = producerDisclosureForm.producerLastName
      finalData["producer_disclosure_section1_bussiness_name"] = producerDisclosureForm.producerBusinessName
      finalData["producer_disclosure_section1_website"] = producerDisclosureForm.producerWebsite
      finalData["producer_disclosure_section1_bussiness_mailing_address"] = producerDisclosureForm.producerBusinessMailingAddress
      finalData["producer_disclosure_section1_city"] = producerDisclosureForm.producerCity
      finalData["producer_disclosure_section1_state"] = producerDisclosureForm.producerState
      finalData["producer_disclosure_section1_zip"] = producerDisclosureForm.producerZip
      finalData["producer_disclosure_section1_bussiness_telephone_number"] = producerDisclosureForm.producerTelephone
      finalData["producer_disclosure_section1_email"] = producerDisclosureForm.producerEmail
      finalData["producer_disclosure_section1_national_producer_name"] = producerDisclosureForm.producerNationalNumber
      finalData["producer_disclosure_section1_state_of"] = producerDisclosureForm.producerInStateOf
      finalData["producer_disclosure_section2_first_name"] = producerDisclosureForm.producerCustomerFirstName
      finalData["producer_disclosure_section2_last_name"] = producerDisclosureForm.producerCustomerLastName
      finalData["producer_disclosure_section3_offered_products"] = {
        value: producerDisclosureForm.producerOfferedProducts.length > 0 ? producerDisclosureForm.producerOfferedProducts.join(',') : "",
        extra_value: ""
      }
      finalData['producer_disclosure_section3_non_insurance_products'] = {
        value: producerDisclosureForm.producerNonInsuranceProducts.length > 0 ? producerDisclosureForm.producerNonInsuranceProducts.join(',') : "",
        extra_value: ""
      }
      finalData['producer_disclosure_section4_annuities_authorize_to_sell'] = {
        value: producerDisclosureForm.producerAuthorizedToSell.length > 0 ? producerDisclosureForm.producerAuthorizedToSell.join(',') : "",
        extra_value: producerDisclosureForm.producerAuthorizedToSellDescription
      }
      finalData['producer_disclosure_section4_cash_compensations'] = {
        value: producerDisclosureForm.producerPaidForWork.length > 0 ? (producerDisclosureForm.producerPaidForWork.filter((value) => value == "commission" || value == "fees").length == 1 ? producerDisclosureForm.producerPaidForWork.filter((value) => value == "commission" || value == "fees") + "," : producerDisclosureForm.producerPaidForWork.filter((value) => value == "commission" || value == "fees").join(",")) : "",
        extra_value: producerDisclosureForm.producerPaidForWorkCommissionDescription
      }
      finalData['producer_disclosure_section4_cash_compensations_other'] = {
        value: producerDisclosureForm.producerPaidForWork.length > 0 ? (producerDisclosureForm.producerPaidForWork.includes('other') ? "other" : "") : "",
        extra_value: producerDisclosureForm.producerPaidForWorkOtherDescription
      }

    }
    return finalData;
  }

  const SendDataToServer = (type, redirect) => {
    SetFlagLoader(true);
    let temp = { ...data };
    temp = AddTransferExchangeData(temp)
    temp = AddDisclosureData(temp)
    temp = AddProducerDisclosureData(temp)
    if (window.location.search.includes("id") === true) {
      temp["application_status_id"] = type === "draft" ? saveAsDraftApplicationStatusId.id : submitApplicationStatusId.id;
    } else {
      temp["application_status_id"] = type === "draft" ? 1 : 2;
    }

    if (applicationId !== null) {
      temp["applications_id"] = applicationId;
    }
    SubmitSaveDraftIaaApplication(temp,
      (res) => {
        SetFlagLoader(false);
        let domain = window.location.host;
        let protocol = window.location.protocol;
        if (res?.data?.status === true) {
          if (type !== "draft") {
            setFlagSuccessDialog(true);
          } else {
            SetApplicationId(res.data.data.application_id);
            if (redirect === true) {
              window.location.replace(
                `${protocol}//${domain}/myga_application_history`
              );
            } else {
              let data = res.data;
              return data;
            }
          }
        } else {
          setStatusFalse(true);
        }
      },
      (err) => {
        setStatusFalse(true);
        SetFlagLoader(false);
      }
    );
  };

  const SubmitOnClick = () => {
    const res = Validation();
    if (res.status === false && res.data.length > 0) {
      setFlagErrorValidationDialog({
        status: true,
        tableData: res.data,
      });
    }
    else {
      if (user?.user_phone_is_verified) {
        SendDataToServer("submit", true);
      } else {
        setFlagVerifyPhonePopup(true)
      }
    }
  }

  return (
    <Fragment>
      {flagVerifyPhonePopup && <VerifyPhoneDialog setFlagVerifyPhonePopup={setFlagVerifyPhonePopup} SendDataToServer={SendDataToServer} />}
      {flagSuccessDialog === true && (
        <SuccessDdialog
          firstText="Application Submitted Successfully!"
          secondText="DocuSign request to sign the envelop will be sent to applicant in 5 minutes."
          redirect="/myga_application_history"
          closeSuccessDialog={() => {
            setFlagSuccessDialog(false);
            let domain = window.location.host;
            let protocol = window.location.protocol;
            window.location.replace(
              `${protocol}//${domain}/myga_application_history`
            );
          }}
        />
      )}
      {statusFalse === true && <SomethingWentWrongDialog popupState={statusFalse} setClosePopupState={setStatusFalse} />}
      {flagErrorValidtionDialog.status === true && (
        <ErrorValidationSubmitDialog
          tableData={flagErrorValidtionDialog.tableData}
          closeDialog={() => {
            setFlagErrorValidationDialog(false);
          }}
        />
      )}
      <Box className="new_application_form">
        <Box className="page_title">
          <Typography
            variant=""
            style={{ marginBottom: "0px" }}
            paragraph={true}
          >
            Application Form
          </Typography>
        </Box>
        <Box className="application_form_main">
          {applicationStatusName === "Submitted for Signature" && (
            <Button
              className="editable_button"
              sx={{
                position: "absolute",
                top: "calc(100px + 55px + 25px)",
                right: "25%",
                color: "#fff",
                background: "#074a6e",
                minWidth: "160px",
                textTransform: "capitalize",
                zInex: 1,
              }}
              onClick={() => {
                if (applicationEditable === true) {
                  window.location.reload();
                }
                if (applicationEditable === false) {
                  SetPageIndex(1);
                }
                setApplicationEditable(!applicationEditable);
              }}
            >
              {applicationEditable === true
                ? "View Application"
                : "Edit Application"}
            </Button>
          )}
          {pageIndex === 1 && (
            <IndividualAnnuityApplication
              changePageIndex={(pagenumber) => {
                SetPageIndex(pageIndex + pagenumber);
              }}
              beneficiariesPercentageTotal={(value) => {
                setBeneficiariesPercentageTotal(value);
              }}
              errorFields={errorFieldsArray}
            />
          )}

          {pageIndex === 3 && (
            <SuitabilityAnalysis
              changePageIndex={(pagenumber) => {
                SetPageIndex(pageIndex - pagenumber);
              }}
              nextPage={() => {
                SetPageIndex(pageIndex + 1);
              }}
              eftPage={() => {
                SetPageIndex(pageIndex + 3);
              }}
              errorFields={errorFieldsArray}
            />
          )}

          {pageIndex === 2 && (
            <ReplacementForm
              previousPage={() => {
                SetPageIndex(pageIndex - 1);
              }}
              nextPage={() => {
                SetPageIndex(pageIndex + 1);
              }}
              errorFields={errorFieldsArray}
            />
          )}
          {pageIndex === 4 && (
            <div className="individual_form">
              {transferExchangeVersion == 7 ?
                <RolloverTransferExchangeRequest
                  previousPage={() => {
                    SetPageIndex(pageIndex - 1);
                  }}
                  nextPage={() => {
                    SetPageIndex(pageIndex + 2);
                  }}
                  errorFields={errorFieldsArray}
                />
                :
                <TransferExchangeRequest
                  previousPage={() => {
                    SetPageIndex(pageIndex - 1);
                  }}
                  nextPage={() => {
                    SetPageIndex(pageIndex + 2);
                  }}
                  errorFields={errorFieldsArray}
                />
              }
            </div>
          )}
          {pageIndex === 6 && (
            disclosureFormVersion == 5
              ?
              <AnnuityDisclosureForm
                suitabilityAnalysisPage={() => {
                  SetPageIndex(pageIndex - 3);
                }}
                previousPage={() => {
                  SetPageIndex(pageIndex - 2);
                }}
                nextPage={() => {
                  SetPageIndex(pageIndex + 2);
                }}
                errorFields={errorFieldsArray}
              />
              :
              <AnnuityDisclosureFormV2
                suitabilityAnalysisPage={() => {
                  SetPageIndex(pageIndex - 3);
                }}
                previousPage={() => {
                  SetPageIndex(pageIndex - 2);
                }}
                nextPage={() => {
                  SetPageIndex(pageIndex + 1);
                }}
                errorFields={errorFieldsArray}
              />
          )}
          {(pageIndex === 7 && flagShowProducerDisclosure) && (
            <div className="individual_form">
              <ProducerDisclosureForm
                previousPage={() => {
                  SetPageIndex(pageIndex - 1);
                }}

                errorFields={errorFieldsArray}
              />
            </div>
          )}
          <div className="formsitemap">
            <FormSiteMap
              pageIndex={pageIndex}
              setPage={(dataid) => {
                SetPageIndex(dataid);
              }}
              applicationId={applicationId}
              saveAsDraft={async (onGetData) => {
                let res = await SendDataToServer("draft", false);
              }}
            />
          </div>
        </Box>
        {(applicationEditable === true
          ? true
          : applicationStatusName !== "Submitted for Signature"
            ? true
            : false) && (
            <Box
              className="btn_group"
              style={{
                margin: "10px 0",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  SubmitOnClick()
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                {applicationEditable === true &&
                  applicationStatusName === "Submitted for Signature"
                  ? "Re-Submit"
                  : "Submit"}
              </Button>
              {applicationEditable === true ? null : (
                <Button
                  onClick={() => {
                    SendDataToServer("draft", true);
                  }}
                  style={{
                    background: "#074a6e",
                    color: "white",
                    textTransform: "capitalize",
                    margin: "0 10px",
                  }}
                >
                  Save As Draft
                </Button>
              )}
            </Box>
          )}
      </Box>
    </Fragment>
  );
};

export default ApplicationForm;
