
import React, { Fragment, useState, useContext, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import FixedIndexAnnuityApplication from "../../../components/V3/fixedIndexAnnuityApplication/FixedIndexAnnuityApplication";
import SuitabilityAnalysis from "../../../components/V3/suitabilityAnalysis/suitabilityAnalysis";
import FixedIndexSiteMap from "../../../components/V3/fixedIndexAnnuityApplication/FixedIndexSiteMap";
import { ApplicationStatusId } from "../../../api/applicationStatusIdAndIdAPI";
import TransferExchangeRequest from "../../../components/V3/transferOrExchangeRequest/transferExchangeRequest";
import RolloverTransferExchangeRequest from "../../../components/V3/rolloverTransferOrExchangeRequest/rolloverTransferExchangeRequest";
import { Context } from "../../../context/V3/newApplicationFormContext";
import SuccessDdialog from "../../../components/successDialog/successDialog";
import { Context as UserLoaderContext } from "../../../context/userLoaderContext";
import { GetSingleFiaDataAPI, SubmitSaveDraftFiaApplication } from "../../../api/V3/fiaApplicationAPI";
import ErrorValidationSubmitDialog from "../../../components/V3/errorValidationSubmitDialog/errorValidationSubmitDialog";
import { useNavigate, useLocation } from "react-router-dom";
import { Context as ErrorValidationContext } from "../../../context/V3/errorValidationContext";
import AnnuityDisclosureForm from "../../../components/V3/annuityDisclosureForm/annuityDisclosureForm";
import SomethingWentWrongDialog from "../../../components/somethingWentWrong/somethingWentWrong";
import ReplacementForm from "../../../components/V3/replacementForm/replacementForm";
import HarvestDisclosureForm from "../../../components/V3/harvestDisclosureForm/harvestDisclosureForm";
import VerifyPhoneDialog from "../../../components/VerifyPhoneDialog/VerifyPhoneDialog";
import AnnuityDisclosureFormV2 from "../../../components/V3/annuityDisclosureForm/V2/annuityDisclosureFormV2";
import ProducerDisclosureForm from "../../../components/V3/producerDisclosureForm/producerDisclosureForm";

const FiaApplication = () => {
    // const [pageIndex, setPageIndex] = useState(1);
    const { state, transferOrExchangeRequestFlag } = useContext(Context);
    console.log(
        "useContext -------------------------------------------- state",
        state
    );
    const {
        individualFixedIndexAnnuityApplication,
        suitabilityAnalysis,
        transferOrExchangeRequest,
        // bankDetails,
        noticeOfReplacement,
        annuityDisclosureForm,
        annuityDisclosureFormV2,
        accountAllocationArray,
        rolloverTransferExchange,
        transferExchangeVersion,
        harvestDisclosureForm,
        fiaDisclosureVersion,
        producerDisclosureForm,
        flagShowProducerDisclosure
    } = state;

    const location = useLocation();
    const [statusFalse, setStatusFalse] = useState(false);
    const [applicationEditable, setApplicationEditable] = useState(false);

    const { SetPageIndex, SetApplicationFormFlagSectionData } = useContext(
        ErrorValidationContext
    );

    const { pageIndex } = useContext(ErrorValidationContext).state;

    const [flagSuccessDialog, setFlagSuccessDialog] = useState(false);

    const { SetFlagLoader } = useContext(UserLoaderContext);
    const { user } = useContext(UserLoaderContext).state;
    const [submitApplicationStatusId, setSubmitApplicationStatusId] = useState(
        {}
    );
    const [saveAsDraftApplicationStatusId, setSaveAsDraftApplicationStatusId] =
        useState({});
    const navigate = useNavigate();
    const {
        SetWholeIndividualFixedIndexAnnuityApplication,
        SetWholeSuitabilityAnalysis,
        SetWholeTransferExchangeRequest,
        SetDisableAllInputs,
        disableAllInputs,
        SetApplicationId,
        SetWholeAnnuityDisclosureForm,
        SetWholeNoticeOfReplacement,
        SetWholeRolloverTransferExchangeRequest,
        SetWholeHarvestDisclosureForm,
        SetWholeAnnuityDisclosureFormV2,
        SetTransferExchangeVersion,
        SetFiaDisclosureVersion,
        SetWholeProducerDisclosureForm,
        SetFlagShowProducerDisclosure
    } = useContext(Context);
    const [applicationId, setApplicationId] = useState(null);
    const [flagErrorValidtionDialog, setFlagErrorValidationDialog] =
        useState(false);
    const [applicationStatusName, setApplicationStatusName] = useState("");
    const [beneficiariesPercentageTotal, setBeneficiariesPercentageTotal] =
        useState(0);

    const [errorFieldsArray, setErrorFieldsArray] = useState([]);
    const [searchParams] = new URLSearchParams();
    const { flagRequestYield } = noticeOfReplacement;
    const [flagVerifyPhonePopup, setFlagVerifyPhonePopup] = useState(false);

    const ReturnTempInsurerPolicyDetailFieldArray = (data) => {
        if (data === undefined || data?.length == 0) {
            return [
                {
                    id: 1,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
                {
                    id: 2,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
                {
                    id: 3,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
                {
                    id: 4,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
            ];
        }
        let temp = [];
        data?.map((v) => {
            temp?.push({
                id: v?.existing_policy_id,
                existingCarrierName: (v.nor_existing_policy_insurer_name && v.nor_existing_policy_insurer_name != "undefined") ?
                    v.nor_existing_policy_insurer_name : "",
                existingCarrierAddress: (v.nor_existing_policy_insurer_name_address && v.nor_existing_policy_insurer_name_address != "undefined") ?
                    v.nor_existing_policy_insurer_name_address : "",
                contractOrPolicyNumber: (v.nor_existing_policy_contract_number && v.nor_existing_policy_contract_number != "undefined") ? v.nor_existing_policy_contract_number : "",
                nameOfInsuredOrAnnuitant: (v.nor_existing_policy_name_of_insured && v.nor_existing_policy_name_of_insured != "undefined") ? v.nor_existing_policy_name_of_insured : "",
                amount: (v.nor_existing_policy_face_amount && v.nor_existing_policy_face_amount != "undefined") ? v.nor_existing_policy_face_amount : "",
                effectiveDate: (v.nor_existing_policy_effective_date && v.nor_existing_policy_effective_date != "undefined") ? v.nor_existing_policy_effective_date : ""
            });
        });
        return temp;
    };

    const ReturnInitialAllocationObj = (data) => {
        let obj = {}
        obj["fixedStrategy"] = data?.fia_section4_initial_account_allocation_0_fixed_strategy
        obj["pointWithCap"] = data?.fia_section4_initial_account_allocation_1_sp_500_cap
        obj["pointWithEnhancedCap"] = data?.fia_section4_initial_account_allocation_2_sp_500_enhanced_cap
        obj["pointWithParticipationRate"] = data?.fia_section4_initial_account_allocation_3_sp_500_participation_rate
        obj["pointWithEnhancedParticipationRate"] = data?.fia_section4_initial_account_allocation_4_sp_500_enhanced_participation_rate
        obj["dividendGrowersParticipationRate"] = data?.fia_section4_initial_account_allocation_5_sp_divident_growers_participation_rate
        obj["dividendGrowersEnhancedParticipationRate"] = data?.fia_section4_initial_account_allocation_6_sp_divident_growers_enhanced_participation_rate
        obj["nasdaqParticipationRate"] = data?.fia_section4_initial_account_allocation_7_nasdaq_100_participation_rate
        obj["nasdaqEnhancedParticipationRate"] = data?.fia_section4_initial_account_allocation_8_nasdaq_100_enhanced_participation_rate
        obj["bloombergGlobalParticipationRate"] = data?.fia_section4_initial_account_allocation_9_bloomberg_global_participation_rate
        obj["bloombergGlobalEnhancedParticipationRate"] = data?.fia_section4_initial_account_allocation_10_bloomberg_global_enhanced_participation_rate
        return obj
    }

    const GetPhoneNumberPayloadFormate = (phoneNumber) => {
        if (!phoneNumber) {
            return ""
        }
        let gg = phoneNumber?.split(" ");
        let countryCode = gg[0];
        gg.shift();
        let restOfTheNumber = gg.join();

        countryCode = countryCode.replace(/[^\d+]/g, '');
        restOfTheNumber = restOfTheNumber.replace(/[^\d+]/g, '');
        if (countryCode.startsWith('+')) {
            countryCode = countryCode.substring(1);
        }

        return `${countryCode}-${restOfTheNumber}`;
    }

    const formatPhoneNumberForContext = (phoneNumber) => {
        if (!phoneNumber) {
            return ""
        }
        if (phoneNumber?.replace(/[^\d+]/g, '').length > 10) {
            let gg = phoneNumber.split("-")
            if (gg[0] != 1) {
                return "+1 " + gg[1]
            } else {
                return "+" + phoneNumber.replace("-", " ");
            }
        } else {
            let formatedPhone = phoneNumber?.replace(/[^\d+]| /g, '')
            formatedPhone = "+1 " + formatedPhone
            return formatedPhone
        }
    }



    const SetDataToContext = (data, flagNewTransferForm, flagHarvestDisclosure, flagProducerDisclosureForm) => {
        try {
            SetWholeIndividualFixedIndexAnnuityApplication({
                ownerFullName: data?.fia_section1_owner_name,
                ownerStreetAddress: data?.fia_section1_owner_street_address,
                ownerCity: data?.fia_section1_owner_city,
                ownerState: data?.fia_section1_owner_state,
                ownerZip: data?.fia_section1_owner_zip,
                ownerEmail: data?.fia_section1_owner_email,
                ownerPhone: formatPhoneNumberForContext(data?.fia_section1_owner_phone),
                ownerSSN: data?.fia_section1_owner_ssn,
                ownerRelationToAnnuitant: data?.fia_section1_owner_relationship_to_annuitant,
                ownerDOB: data?.fia_section1_owner_dob,
                ownerGender: data?.fia_section1_owner_gender?.value?.length ? data.fia_section1_owner_gender.value[0] : "",

                jointOwnerFullName: data?.fia_section1_join_owner_name,
                jointOwnerStreetAddress: data?.fia_section1_join_owner_street_address,
                jointOwnerCity: data?.fia_section1_join_owner_city,
                jointOwnerState: data?.fia_section1_join_owner_state,
                jointOwnerZip: data?.fia_section1_join_owner_zip,
                jointOwnerEmail: data?.fia_section1_join_owner_email,
                jointOwnerPhone: formatPhoneNumberForContext(data?.fia_section1_join_owner_phone),
                jointOwnerSSN: data?.fia_section1_join_owner_ssn,
                jointOwnerRelationToOwner: "Spouse",
                jointOwnerDOB: data?.fia_section1_join_owner_dob,
                jointOwnerGender: data?.fia_section1_join_owner_gender?.value?.length ? data.fia_section1_join_owner_gender.value[0] : "",

                annuitantFullName: data?.fia_section2_annuitant_name,
                annuitantStreetAddress: data?.fia_section2_annuitant_street_address,
                annuitantCity: data?.fia_section2_annuitant_city,
                annuitantState: data?.fia_section2_annuitant_state,
                annuitantZip: data?.fia_section2_annuitant_zip,
                annuitantEmail: data?.fia_section2_annuitant_email,
                annuitantPhone: formatPhoneNumberForContext(data?.fia_section2_annuitant_phone),
                annuitantSSN: data?.fia_section2_annuitant_ssn,
                annuitantDOB: data?.fia_section2_annuitant_dob,
                annuitantGender: data?.fia_section2_annuitant_gender?.value?.length ? data.fia_section2_annuitant_gender.value[0] : "",
                annuitantRelationToOwner: data?.fia_section2_annuitant_relationship_to_owner,

                jointAnnuitantFullName: data?.fia_section2_join_annuitant_name,
                jointAnnuitantStreetAddress: data?.fia_section2_join_annuitant_street_address,
                jointAnnuitantCity: data?.fia_section2_join_annuitant_city,
                jointAnnuitantState: data?.fia_section2_join_annuitant_state,
                jointAnnuitantZip: data?.fia_section2_join_annuitant_zip,
                jointAnnuitantEmail: data?.fia_section2_join_annuitant_email,
                jointAnnuitantPhone: data?.fia_section2_join_annuitant_phone ? formatPhoneNumberForContext(data?.fia_section2_join_annuitant_phone) : "",
                jointAnnuitantSSN: data?.fia_section2_join_annuitant_ssn,
                jointAnnuitantDOB: data?.fia_section2_join_annuitant_dob,
                jointAnnuitantGender: data?.fia_section2_join_annuitant_gender?.value?.length ? data.fia_section2_join_annuitant_gender.value[0] : "",
                jointAnnuitantRelationToAnnuitant: data?.fia_section2_join_annuitant_relationship_to_annuitant,

                planHarvest: data?.fia_section3_plan_harvest?.value?.length > 0 ? data.fia_section3_plan_harvest.value[0] : "",
                ridersPlan: data?.fia_section3_riders_plan?.value?.length > 0 ? data?.fia_section3_riders_plan?.value[0] : "",

                initialAccountAllocation: ReturnInitialAllocationObj(data),
                beneficiaries: ReturnBeneficiariesArray(data?.beneficiaries),

                flagQualifiedOrNonQualifiedSelected: data?.fia_section7_premium_tax_qualification_status?.value?.length > 0 ? data?.fia_section7_premium_tax_qualification_status.value[0] : "",
                selectedNonQualifiedOptions:
                    data.fia_section7_premium_qualified.value[0] != "" ?
                        data.fia_section7_premium_qualified.value :
                        data.fia_section7_premium_non_qualified?.value,
                selectedQualifiedOptionsGroup2: data?.fia_section7_type_of_plan?.value?.length ? data?.fia_section7_type_of_plan?.value[0] == "sep" ? "" : data?.fia_section7_type_of_plan?.value[0] : "",
                otherSelectedQualifiedOptions: data?.fia_section7_type_of_plan?.extra_value,
                stateToShowNoticeOfReplacement: data?.fia_state_to_show_notice_of_replacement,
                amountPaidWithApplication: data.fia_section7_amount_paid,
                expectedPremiumField: data?.fia_section7_premium_amount,

                producerStateA:
                    data?.fia_insurance_producer_declaration_question_a_producer?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_a_producer?.value[0] : "",
                applicantStateA:
                    data?.fia_insurance_producer_declaration_question_a_applicant?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_a_applicant?.value[0] : "",
                producerStateB:
                    data?.fia_insurance_producer_declaration_question_b_producer?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_b_producer?.value[0] : "",
                applicantStateB:
                    data?.fia_insurance_producer_declaration_question_b_applicant?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_b_applicant?.value[0] : "",
                producerStateC:
                    data?.fia_insurance_producer_declaration_question_c_producer?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_c_producer?.value[0] : "",
                applicantStateC:
                    data?.fia_insurance_producer_declaration_question_c_applicant?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_c_applicant?.value[0] : "",
                producerStateD:
                    data?.fia_insurance_producer_declaration_question_d_producer?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_d_producer?.value[0] : "",
                applicantStateD:
                    data?.fia_insurance_producer_declaration_question_d_applicant?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_d_applicant?.value[0] : "",
                producerStateE:
                    data?.fia_insurance_producer_declaration_question_e_producer?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_e_producer?.value[0] : "",
                applicantStateE:
                    data?.fia_insurance_producer_declaration_question_e_applicant?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_e_applicant?.value[0] : "",
                producerStateF:
                    data?.fia_insurance_producer_declaration_question_f_producer?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_f_producer?.value[0] : "",
                applicantStateF:
                    data?.fia_insurance_producer_declaration_question_f_applicant?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_f_applicant?.value[0] : "",
                producerStateG:
                    data?.fia_insurance_producer_declaration_question_g_producer?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_g_producer?.value[0] : "",
                applicantStateG:
                    data?.fia_insurance_producer_declaration_question_g_applicant?.value?.length ?
                        data?.fia_insurance_producer_declaration_question_g_applicant?.value[0] : "",
            });

            SetWholeSuitabilityAnalysis({
                ownerFullName: data?.suitability_analysis_section1_owner_full_name,
                ownerCurrentAge: data?.suitability_analysis_section1_owner_age,
                ownerGender: data?.suitability_analysis_section1_owner_gender?.value[0],
                ownerEmploymentStatus: data?.suitability_analysis_section1_owner_emp_status?.value[0],
                ownerCurrentOccupation: data?.suitability_analysis_section1_owner_occupation,
                ownerDependents: data?.suitability_analysis_section1_owner_depedents,
                spouseFullName: data?.suitability_analysis_section1_spouse_full_name,
                spouseCurrentAge: data?.suitability_analysis_section1_spouse_age,
                spouseGender: data?.suitability_analysis_section1_spouse_gender?.value[0],
                spouseEmploymentStatus: data?.suitability_analysis_section1_spouse_emp_status?.value[0],
                spouseCurrentOccupation: data?.suitability_analysis_section1_spouse_occupation,
                spouseDependents: data?.suitability_analysis_section1_spouse_depedents,
                ownerAnnualHouseholdIncome: data?.suitability_analysis_section2_annual_income,
                ownerSourceOfIncome: data?.suitability_analysis_section2_source_income,
                ownerIncomeTaxBracket: data?.suitability_analysis_section2_income_tax,

                ownerTotalOutstandingDebt: data?.suitability_analysis_section2_total_outstanding_debt,
                ownerTotalAssets: data?.suitability_analysis_section2_total_assets,
                ownerTotalLiquidAssets: data?.suitability_analysis_section2_total_liquid_assets,
                flagOwnerIncomeCoverLivingAndMedicalExpenses:
                    data?.suitability_analysis_section3_q1_medical_expenses?.value[0],
                explainOwnerIncomeCoverLivingAndMedicalExpenses:
                    data?.suitability_analysis_section3_q1_medical_expenses.extra_value,

                flagOwnerExpectChangesInLivingExpenses:
                    data?.suitability_analysis_section3_q2_living_expenses?.value[0],
                explainOwnerExpectChangesInLivingExpenses:
                    data?.suitability_analysis_section3_q2_living_expenses.extra_value,

                flagOwnerAnticipateChangesInMedicalExpenses:
                    data?.suitability_analysis_section3_q3_pocket_expenses?.value[0],
                explainOwnerAnticipateChangesInMedicalExpenses:
                    data?.suitability_analysis_section3_q3_pocket_expenses.extra_value,

                flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod:
                    data?.suitability_analysis_section3_q4_surrender_charge_expenses?.value[0],
                explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod:
                    data?.suitability_analysis_section3_q4_surrender_charge_expenses.extra_value,

                flagOwnerHaveEmergencyFundForUnexpectedExpenses:
                    data?.suitability_analysis_section3_q5_emergency_fund?.value[0],
                explainOwnerHaveEmergencyFundForUnexpectedExpenses:
                    data?.suitability_analysis_section3_q5_emergency_fund.extra_value,

                ownerOtherInvestments:
                    data?.suitability_analysis_section3_q6_own_investment?.value[0] == ""
                        ? []
                        : data.suitability_analysis_section3_q6_own_investment.value,

                otherOwnerInvestmentField: data?.suitability_analysis_section3_q6_own_investment.extra_value,

                ownerRiskTolerance: data?.suitability_analysis_section3_q7_risk_tolerance?.value[0],
                reasonForPurchasingProduct:
                    data?.suitability_analysis_section4_q_reason_for_purchase_product?.value[0] === ""
                        ? []
                        :
                        data?.suitability_analysis_section4_q_reason_for_purchase_product.value,
                otherRasonForPurchasingThisProduct:
                    data?.suitability_analysis_section4_q_reason_for_purchase_product.extra_value,
                flagOwnerPlanToWithdrawFundInSurrenderPeriod:
                    data?.suitability_analysis_section4_q1_surrender_charge_period?.value[0],
                explainOwnerPlanToWithdrawFundInSurrenderPeriod:
                    data?.suitability_analysis_section4_q1_surrender_charge_period.extra_value,
                ownerAnticipateNeedingAccessToFunds:
                    data?.suitability_analysis_section4_q2_anticipate_need?.value[0],
                sourceOfFundsToPurchaseThisProduct:
                    data?.suitability_analysis_section4_q3_source_of_fund_purchase_product?.value[0] === ""
                        ? []
                        : data?.suitability_analysis_section4_q3_source_of_fund_purchase_product?.value,
                otherSourceOfFundsToPurchaseThisProduct:
                    data?.suitability_analysis_section4_q3_source_of_fund_purchase_product?.extra_value,

                flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct:
                    data?.suitability_analysis_section5_q1_existing_account_information?.value[0],

                howLongHasTheLifeInsuranceBeenInForce:
                    data?.suitability_analysis_section5_q2_life_insurance_or_annuity_force_year,
                flagSurrenderChargeOnExistingProduct:
                    data?.suitability_analysis_section5_q3_surrender_charge_product?.value[0],
                currentSurrenderChargeOnExistingProduct:
                    data?.suitability_analysis_section5_q3_surrender_charge_product?.value[0] == "yes" ?
                        data?.suitability_analysis_section5_q3_surrender_charge_product.extra_value.split(",")[0]
                        : "",
                expirationOfCurrentSurrenderCharge:
                    data?.suitability_analysis_section5_q3_surrender_charge_product?.value[0] == "yes" ?
                        data?.suitability_analysis_section5_q3_surrender_charge_product.extra_value.split(",")[1]
                        : "",
                typeOfExistingProduct:
                    data?.suitability_analysis_section5_q4_type_of_existed_product?.value[0],
                interestRateForExistingProduct: data?.suitability_analysis_section5_q5_interest_rate_existing_product,

                flagTakingRequiredMinimumDistributionsFromExistingProduct:
                    data?.suitability_analysis_section5_q6_tax_qualified_annuity?.value[0],
                flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution:
                    data?.suitability_analysis_section5_q7_tax_periodic_distributions?.value[0],
                explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution:
                    data?.suitability_analysis_section5_q7_tax_periodic_distributions.extra_value,
                personsThatAssistedOwnerToPurchaseThisProduct:
                    data?.suitability_analysis_section6_assist_purchase_product?.value[0] == ""
                        ? []
                        :
                        data?.suitability_analysis_section6_assist_purchase_product?.value,
                otherPersonsThatAssistedOwnerToPurchaseThisProduct:
                    data?.suitability_analysis_section6_assist_purchase_product.extra_value,

                additionalRelaventInformationForSuitabilityAnalysis:
                    data?.suitability_analysis_section6_relevant_information,
                agentAcknowledgment: data?.suitability_analysis_section7_agent_acknowledgement?.value[0],
            });
            if (flagNewTransferForm) {
                SetWholeRolloverTransferExchangeRequest({
                    transferV2Section1CompanyName: data?.transfer_v2_section1_company_name,
                    transferV2Section1CompanyPhone: data?.transfer_v2_section1_company_phone,
                    transferV2Section1CompanyPolicyNumber: data?.transfer_v2_section1_company_policy_number,
                    transferV2Section1CompanyAddress: data?.transfer_v2_section1_company_address,
                    transferV2Section1CompanyCity: data?.transfer_v2_section1_company_city,
                    transferV2Section1CompanyState: data?.transfer_v2_section1_company_state,
                    transferV2Section1CompanyZip: data?.transfer_v2_section1_company_zip,
                    transferV2Section1OwnerName: data?.transfer_v2_section1_owner_name,
                    transferV2Section1OwnerSsn: data?.transfer_v2_section1_owner_ssn,
                    transferV2Section1JoinOwnerName: data?.transfer_v2_section1_join_owner_name,
                    transferV2Section1JoinOwnerSsn: data?.transfer_v2_section1_join_owner_ssn,
                    transferV2Section1AnnuitantName: data?.transfer_v2_section1_annuitant_name,
                    transferV2Section1AnnuitantSsn: data?.transfer_v2_section1_annuitant_ssn,
                    transferV2Section1JoinAnnuitantName: data?.transfer_v2_section1_join_annuitant_name,
                    transferV2Section1JoinAnnuitantSsn: data?.transfer_v2_section1_join_annuitant_ssn,
                    transferV2Section1BeneficiaryName: data?.transfer_v2_section1_beneficiary_name,
                    transferV2Section1BeneficiarySsn: data?.transfer_v2_section1_beneficiary_ssn,
                    transferV2Section1InvestmentVehicle: data?.transfer_v2_section1_investment_vehicle?.value[0],
                    transferV2Section1InvestmentVehicleOther: data?.transfer_v2_section1_investment_vehicle?.extra_value,
                    transferV2Section1ExistingCertificate: data?.transfer_v2_section1_existing_certificate?.value[0],

                    transferV2Section2Plan: data?.transfer_v2_section2_plan,
                    transferV2Section2NonQualified1035Exchange: data?.transfer_v2_section2_non_qualified_1035_exchange?.value[0],
                    transferV2Section2NonQualified1035ExchangeOtherPercentage:
                        data?.transfer_v2_section2_non_qualified_1035_exchange?.extra_value?.split(",")[0],
                    transferV2Section2NonQualified1035ExchangeOtherAmount:
                        data?.transfer_v2_section2_non_qualified_1035_exchange?.extra_value?.split(",")[1],
                    transferV2Section2QualifiedTypeOfAccount: data?.transfer_v2_section2_qualified_type_of_account?.value[0],
                    transferV2Section2QualifiedTypeOfAccountOther: data?.transfer_v2_section2_qualified_type_of_account?.extra_value,
                    transferV2Section2QualifiedLiquidateTransfer:
                        data?.transfer_v2_section2_qualified_liquidate_transfer_all?.value[0] == "all"
                            ? "all" : "partial",
                    transferV2Section2QualifiedLiquidateTransferAmount: data?.transfer_v2_section2_qualified_liquidate_transfer_partial_amount?.extra_value,
                    transferV2Section2QualifiedLiquidateTransferPercentage: data?.transfer_v2_section2_qualified_liquidate_transfer_partial_percentage?.extra_value,
                    transferV2Section2QualifiedAgeRmdTransfer: data?.transfer_v2_section2_qualified_age_rmd_transfer?.value[0],
                    transferV2Section2QualifiedWithdrawFunds: data?.transfer_v2_section2_qualified_withdraw_funds?.value[0],
                    transferV2Section2QualifiedWithdrawFundsOther: data?.transfer_v2_section2_qualified_withdraw_funds?.extra_value,
                    transferV2Section2QualifiedFundsTransfered: data?.transfer_v2_section2_qualified_funds_transfered?.value[0],
                })
            } else {
                SetWholeTransferExchangeRequest({
                    ownerFullName: data?.transfer_section1_item_owner_full_name,
                    ownerStreetAddress: data?.transfer_section1_item_owner_address,
                    ownerCity: data?.transfer_section1_item_owner_city,
                    ownerState: data?.transfer_section1_item_owner_state,
                    ownerMaritalStatus: data?.transfer_section1_item_owner_marital_status,
                    ownerZip: data?.transfer_section1_item_owner_zip,
                    annuitantOrInsured: data?.transfer_section1_item_owner_annuitant,
                    ownerSSN: data?.transfer_section1_item_owner_ssn_or_tax_id,
                    currentInsuranceCompany:
                        data?.transfer_section1_item_owner_current_insurance_company,
                    currentInsuranceCompanyPhone:
                        data?.transfer_section1_item_owner_phone,
                    currentInsuranceCompanyAccountNumber:
                        data?.transfer_section1_item_annuitant_account_number,
                    currentInsuranceCompanyStreetAddress:
                        data?.transfer_section1_item_annuitant_address,
                    currentInsuranceCompanyZip:
                        data?.transfer_section1_item_annuitant_zip,
                    currentInsuranceCompanyCity:
                        data?.transfer_section1_item_annuitant_city,
                    currentInsuranceCompanyState:
                        data?.transfer_section1_item_annuitant_state,
                    typeOfAccount: data?.transfer_section1_type_of_account?.value[0],

                    otherTypeOfAccount:
                        data?.transfer_section1_type_of_account?.extra_value,
                    ownerExistingAnnuity:
                        data?.transfer_section1_existing_contract_or_certificate?.value[0],

                    flagNonQualifiedOwnerWantWithdrawalToExchange:
                        data?.transfer_section2_non_qualified_plan_prior_exchange.value[0],

                    explainNonQualifiedOwnerWantWithdrawalToExchange:
                        data?.transfer_section2_non_qualified_plan_prior_exchange?.extra_value,

                    flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal:
                        data?.transfer_section2_non_qualified_plan_continue_exiting_rmd_schedule?.value[0],

                    plans: data?.transfer_section2_qualified_plan_rmd_reach_age_72?.value[0] ? "Qualified Plans" : "Non-Qualified Plans",
                    qualifiedTransferOrExchangeExistingAccountAllOrPartial:
                        data?.transfer_section2_plan_existing_account_requested_question_1?.value[0] ? data?.transfer_section2_plan_existing_account_requested_question_1?.value[0] : "",

                    qualifiedTransferOrExchangeExistingAccountPartialAmount: data?.transfer_section2_plan_existing_account_requested_question_1?.extra_value,

                    qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate:
                        data?.transfer_section2_plan_existing_account_requested_question_2?.value[0],

                    qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField:
                        data?.transfer_section2_plan_existing_account_requested_question_2?.extra_value,

                    qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy:
                        data?.transfer_section2_plan_existing_account_requested_question_3?.value[0],

                    qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField:
                        data?.transfer_section2_plan_existing_account_requested_question_3?.extra_value,

                    qualifiedTypeOfTransfer: data?.transfer_section2_plans_transfer_or_rollover?.value[0],

                    qualifiedQualifyingEventForDirectRolloverFunds:
                        data?.transfer_section2_direct_rollover_fund?.value[0],
                    flagQualifiedOwnerReachedAge72OrMoreInThisYear:
                        data?.transfer_section2_qualified_plan_rmd_reach_age_72?.value[0],
                    flagQualifiedOwnerSatisfiedRMDFromDistributingPlan:
                        data?.transfer_section2_qualified_plan_rmd_satisfied_distributing_plan?.value[0],
                    qualifiedInternalExchangeOfExistingAnnuityName:
                        data?.transfer_section2_qualified_plan_internal_exchnage_annuity,
                    flagQualifiedOwnerWantRMDBeforeCompletingExchange:
                        data?.transfer_section2_qualified_plan_get_rmd_before_completing?.value[0],
                    flagQualifiedOwnerWantToContinueExistingRMDSchedule:
                        data?.transfer_section2_qualified_plan_continue_exiting_rmd_schedule?.value[0],
                    flagSignatureDigitalOrPhysical:
                        data?.trans_exchange_req_signature_digital_or_physical?.value?.length ?
                            data?.trans_exchange_req_signature_digital_or_physical?.value[0] : ""
                });
            }

            if (flagHarvestDisclosure == 10) {
                SetWholeAnnuityDisclosureFormV2({
                    flagAnnuityClosureV2: data?.harvest_v2_plus_disclosure_annuity?.value[0],
                    flagAnnuityClosureBackupWithout: data?.harvest_v2_plus_disclosure_backup_withholding?.value[0],
                });
            } else if (flagHarvestDisclosure == 8) {
                SetWholeHarvestDisclosureForm({
                    flagHarvestClosure: data?.harvest_plus_disclosure_annuity?.value[0],
                });
            } else if (flagHarvestDisclosure == 5) {
                SetWholeAnnuityDisclosureForm({
                    flagAnnuityClosure: data?.safeguard_plus_disclosure_annuity?.value[0],
                });
            }

            SetWholeNoticeOfReplacement({
                flagDiscontinuingPremiumPayment:
                    data?.nor_question1a_terminating_your_existing_policy?.value[0] ?
                        data?.nor_question1a_terminating_your_existing_policy?.value[0] : "",
                flagPayForPremiumPolicy:
                    data?.nor_question1b_fund_from_new_policy?.value[0] ?
                        data?.nor_question1b_fund_from_new_policy?.value[0] : "",
                insurerPolicyDetailFieldArray: ReturnTempInsurerPolicyDetailFieldArray(
                    data?.existing_policy
                ),
                reasonForReplacement: data?.nor_question1d_reason_for_replacement,
            });

            if (flagProducerDisclosureForm) {
                SetWholeProducerDisclosureForm({
                    producerFirstName: data?.producer_disclosure_section1_first_name,
                    producerLastName: data?.producer_disclosure_section1_last_name,
                    producerBusinessName: data?.producer_disclosure_section1_bussiness_name,
                    producerWebsite: data?.producer_disclosure_section1_website,
                    producerBusinessMailingAddress: data?.producer_disclosure_section1_bussiness_mailing_address,
                    producerCity: data?.producer_disclosure_section1_city,
                    producerState: data?.producer_disclosure_section1_state,
                    producerZip: data?.producer_disclosure_section1_zip,
                    producerTelephone: data?.producer_disclosure_section1_bussiness_telephone_number,
                    producerEmail: data?.producer_disclosure_section1_email,
                    producerNationalNumber: data?.producer_disclosure_section1_national_producer_name,
                    producerInStateOf: data?.producer_disclosure_section1_state_of,
                    producerCustomerFirstName: data?.producer_disclosure_section2_first_name,
                    producerCustomerLastName: data?.producer_disclosure_section2_last_name,
                    producerOfferedProducts: data?.producer_disclosure_section3_offered_products.value,
                    producerNonInsuranceProducts: data?.producer_disclosure_section3_non_insurance_products.value,
                    producerAuthorizedToSell: data?.producer_disclosure_section4_annuities_authorize_to_sell.value,
                    producerAuthorizedToSellDescription: data?.producer_disclosure_section4_annuities_authorize_to_sell.extra_value,
                    producerPaidForWork: [...data?.producer_disclosure_section4_cash_compensations.value, ...data?.producer_disclosure_section4_cash_compensations_other.value],
                    producerPaidForWorkCommissionDescription: data?.producer_disclosure_section4_cash_compensations.extra_value,
                    producerPaidForWorkOtherDescription: data?.producer_disclosure_section4_cash_compensations_other.extra_value,
                });
            }

            SetFlagLoader(false);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        SetPageIndex(1)
        SetFlagLoader(true);
        let searchId = window.location.search?.substring(4);
        setApplicationId(searchId);
        SetFlagLoader(true);
        GetSingleFiaDataAPI(
            searchId,
            (res) => {
                setApplicationStatusName(res.data.data[0].application_status_name);
                SetApplicationId(res.data.data[0].applications_id);
                if (res.data.data[0].application_status_name !== "Draft") {
                    if (applicationEditable === true) {
                        SetDisableAllInputs(false);
                    } else {
                        SetDisableAllInputs(true);
                    }
                } else {
                    SetDisableAllInputs(false);
                }

                if (res.data.status === true) {
                    let flagNewTransferForm;
                    let flagHarvestDisclosure;
                    let flagShowProducerDisclosureForm
                    if (res.data.data[0]?.form_id_array.includes(7)) {
                        SetTransferExchangeVersion(7)
                        flagNewTransferForm = true
                    } else if (res.data.data[0]?.form_id_array.includes(4)) {
                        SetTransferExchangeVersion(4)
                        flagNewTransferForm = false
                    } else {
                        SetTransferExchangeVersion(7)
                        flagNewTransferForm = true
                    }

                    if (res.data.data[0]?.form_id_array.includes(8)) {
                        SetFiaDisclosureVersion(8)
                        flagHarvestDisclosure = 8
                    } else if (res.data.data[0]?.form_id_array.includes(10)) {
                        SetFiaDisclosureVersion(10)
                        flagHarvestDisclosure = 10
                    } else if (res.data.data[0]?.form_id_array.includes(5)) {
                        SetFiaDisclosureVersion(5)
                        flagHarvestDisclosure = 5
                    }

                    if (res.data.data[0]?.form_id_array.includes(11)) {
                        SetFlagShowProducerDisclosure(true)
                        flagShowProducerDisclosureForm = true
                    } else {
                        SetFlagShowProducerDisclosure(false)
                        flagShowProducerDisclosureForm = false
                    }

                    SetDataToContext(res.data.data[0].form_data, flagNewTransferForm, flagHarvestDisclosure, flagShowProducerDisclosureForm);
                }
            },
            (err) => {
                SetFlagLoader(false);
            }
        );
        ApplicationStatusId(
            {},
            (res) => {
                SetFlagLoader(!true);

                if (res.data.status === true) {
                    res.data.data.map((v) => {
                        if (v.name === "Submitted for Signature") {
                            setSubmitApplicationStatusId(v);
                        } else {
                            setSaveAsDraftApplicationStatusId(v);
                        }
                    });
                }
            },
            (err) => {
                SetFlagLoader(!true);
                if (err.response.status === 401) {
                    window.location.reload();
                }
            }
        );

    }, []);

    useEffect(() => {
        if (location?.search?.includes("id") === true) {
            if (
                applicationStatusName !== "Draft" &&
                applicationStatusName !== "" &&
                applicationStatusName !== null
            ) {
                if (applicationEditable === true) {
                    SetDisableAllInputs(false);
                } else {
                    SetDisableAllInputs(true);
                }
            }
        }
    }, [applicationEditable, applicationStatusName]);

    const {
        flagDiscontinuingPremiumPayment,
        flagPayForPremiumPolicy,
        insurerPolicyDetailFieldArray,
        reasonForReplacement,
    } = noticeOfReplacement;

    const { flagAnnuityClosure } = annuityDisclosureForm;
    const { flagAnnuityClosureV2, flagAnnuityClosureBackupWithout } = annuityDisclosureFormV2;
    const { flagHarvestClosure } = harvestDisclosureForm;

    const {
        annuitantOrInsured,
        currentInsuranceCompany,
        currentInsuranceCompanyPhone,
        currentInsuranceCompanyAccountNumber,
        currentInsuranceCompanyStreetAddress,
        currentInsuranceCompanyZip,
        currentInsuranceCompanyCity,
        currentInsuranceCompanyState,
        typeOfAccount,
        otherTypeOfAccount,
        ownerExistingAnnuity,
        plans,
        flagNonQualifiedOwnerWantWithdrawalToExchange,
        explainNonQualifiedOwnerWantWithdrawalToExchange,
        flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal,
        flagSignatureDigitalOrPhysical,
        qualifiedTransferOrExchangeExistingAccountAllOrPartial,
        qualifiedTransferOrExchangeExistingAccountPartialAmount,
        qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate,
        qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField,
        qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy,
        qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField,
        qualifiedTypeOfTransfer,
        qualifiedQualifyingEventForDirectRolloverFunds,
        flagQualifiedOwnerReachedAge72OrMoreInThisYear,
        flagQualifiedOwnerSatisfiedRMDFromDistributingPlan,
        qualifiedInternalExchangeOfExistingAnnuityName,
        flagQualifiedOwnerWantRMDBeforeCompletingExchange,
        flagQualifiedOwnerWantToContinueExistingRMDSchedule,
    } = transferOrExchangeRequest;

    const {
        // ownerFullName,
        ownerCurrentAge,
        // ownerGender,
        ownerEmploymentStatus,
        ownerCurrentOccupation,
        ownerDependents,
        spouseFullName,
        spouseCurrentAge,
        spouseGender,
        spouseEmploymentStatus,
        spouseCurrentOccupation,
        spouseDependents,
        ownerAnnualHouseholdIncome,
        ownerSourceOfIncome,
        ownerIncomeTaxBracket,
        ownerTotalOutstandingDebt,
        ownerTotalAssets,
        ownerTotalLiquidAssets,
        flagOwnerIncomeCoverLivingAndMedicalExpenses,
        explainOwnerIncomeCoverLivingAndMedicalExpenses,
        flagOwnerExpectChangesInLivingExpenses,
        explainOwnerExpectChangesInLivingExpenses,
        flagOwnerAnticipateChangesInMedicalExpenses,
        explainOwnerAnticipateChangesInMedicalExpenses,
        flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
        explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
        flagOwnerHaveEmergencyFundForUnexpectedExpenses,
        explainOwnerHaveEmergencyFundForUnexpectedExpenses,
        ownerOtherInvestments,
        otherOwnerInvestmentField,
        ownerRiskTolerance,
        reasonForPurchasingProduct,
        otherRasonForPurchasingThisProduct,
        flagOwnerPlanToWithdrawFundInSurrenderPeriod,
        explainOwnerPlanToWithdrawFundInSurrenderPeriod,
        ownerAnticipateNeedingAccessToFunds,
        sourceOfFundsToPurchaseThisProduct,
        otherSourceOfFundsToPurchaseThisProduct,
        flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct,
        howLongHasTheLifeInsuranceBeenInForce,
        flagSurrenderChargeOnExistingProduct,
        currentSurrenderChargeOnExistingProduct,
        expirationOfCurrentSurrenderCharge,
        typeOfExistingProduct,
        interestRateForExistingProduct,
        flagTakingRequiredMinimumDistributionsFromExistingProduct,
        flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
        explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
        personsThatAssistedOwnerToPurchaseThisProduct,
        otherPersonsThatAssistedOwnerToPurchaseThisProduct,
        additionalRelaventInformationForSuitabilityAnalysis,
        agentAcknowledgment,
    } = suitabilityAnalysis;

    const {
        ownerFullName,
        ownerSSN,
        ownerGender,
        ownerDOB,
        ownerStreetAddress,
        ownerZip,
        ownerCity,
        ownerState,
        ownerEmail,
        ownerRelationToAnnuitant,
        ownerPhone,

        jointOwnerFullName,
        jointOwnerSSN,
        jointOwnerGender,
        jointOwnerDOB,
        jointOwnerStreetAddress,
        jointOwnerZip,
        jointOwnerCity,
        jointOwnerState,
        jointOwnerEmail,
        jointOwnerPhone,
        // jointOwnerRelationToOwner,

        annuitantFullName,
        annuitantSSN,
        annuitantGender,
        annuitantDOB,
        annuitantStreetAddress,
        annuitantZip,
        annuitantCity,
        annuitantState,
        annuitantEmail,
        annuitantPhone,
        annuitantRelationToOwner,

        jointAnnuitantFullName,
        jointAnnuitantSSN,
        jointAnnuitantGender,
        jointAnnuitantDOB,
        jointAnnuitantStreetAddress,
        jointAnnuitantZip,
        jointAnnuitantCity,
        jointAnnuitantState,
        jointAnnuitantEmail,
        jointAnnuitantPhone,
        jointAnnuitantRelationToAnnuitant,

        planHarvest,
        ridersPlan,
        beneficiaries,
        initialAccountAllocation,

        flagNonQualifiedSelected,
        selectedNonQualifiedOptions,
        // flagQualifiedSelected,

        otherSelectedQualifiedOptions,
        selectedQualifiedOptionsGroup2,
        stateToShowNoticeOfReplacement,
        flagQualifiedOrNonQualifiedSelected,
        amountPaidWithApplication,
        expectedPremiumField,

        producerStateA,
        applicantStateA,
        producerStateB,
        applicantStateB,
        producerStateC,
        applicantStateC,
        producerStateD,
        applicantStateD,
        producerStateE,
        applicantStateE,
        producerStateF,
        applicantStateF,
        producerStateG,
        applicantStateG,

        flagHaveExistingLifeInsurance,
        reasonOfApplicationToChangeLifeInsurance,
    } = individualFixedIndexAnnuityApplication;

    const tempBeneficiaries = () => {
        let temp = [];
        beneficiaries.map((obj) => {
            temp.push({
                beneficiaries_flag_primary: obj?.primaryOrContingent == "Primary" ? 1 : obj?.primaryOrContingent == "Contingent" ? 0 : "",
                beneficiaries_name: obj?.name,
                beneficiaries_percentage: obj?.percentageField,
                beneficiaries_ssn_tin_no: obj?.ssnField,
                beneficiaries_gender: obj?.genderField,
                beneficiaries_relationship: obj?.relationshipField,
            });
        });
        return temp;
    };

    const ReturnBeneficiariesArray = (data) => {
        let temp = [];
        if (data?.length) {
            data.map((obj) => {
                temp.push({
                    id: obj.beneficiaries_id,
                    primaryOrContingent: obj?.beneficiaries_flag_primary == 1 ? "Primary" : obj?.beneficiaries_flag_primary == 0 ? "Contingent" : "",
                    name: obj?.beneficiaries_name,
                    percentageField: obj?.beneficiaries_percentage,
                    ssnField: obj?.beneficiaries_ssn_tin_no,
                    genderField: obj?.beneficiaries_gender,
                    relationshipField: obj?.beneficiaries_relationship,
                });
            });
        } else {
            let tempArray = [1, 2, 3, 4, 5, 6]
            tempArray.map((obj, i) => {
                temp.push({
                    id: tempArray[i],
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: "",
                });
            });
        }
        return temp;
    }

    function isValidEmail(email) {
        return /^([a-zA-Z0-9\._]+)@([a-zA-Z)-9])+.([a-z]+)(.[a-z]+)?$/.test(email);
    }

    function isValidZip(zip) {
        return /^[0-9]{5}(?:-[0-9]{4})?$/.test(zip);
    }
    function isValidWebsite(website) {
        return /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/.test(website);
    }
    function isValidNPN(npn) {
        return /^\d{1,10}$/.test(npn);
    }
    function validatePhoneNumberLatest(input_str) {
        return input_str?.replace(/\D/g, '').length >= 11
    }
    function validatePhoneNumber(input_str) {
        var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        return re.test(input_str);
    }

    let ownerKeys = [
        "ownerFullName",
        "ownerSSN",
        "ownerGender",
        "ownerDOB",
        "ownerStreetAddress",
        "ownerZip",
        "ownerCity",
        "ownerState",
        "ownerEmail",
        "ownerRelationToAnnuitant",
        "ownerPhone"
    ]

    const producerDeclarationsArray = [
        {
            key: "A",
            producerState: "producerStateA",
            applicantState: "applicantStateA",
        },
        {
            key: "B",
            producerState: "producerStateB",
            applicantState: "applicantStateB",
        },
        {
            key: "C",
            producerState: "producerStateC",
            applicantState: "applicantStateC",
        },
        {
            key: "D",
            producerState: "producerStateD",
            applicantState: "applicantStateD",
        },
        {
            key: "E",
            producerState: "producerStateE",
            applicantState: "applicantStateE",
        },
        {
            key: "F",
            producerState: "producerStateF",
            applicantState: "applicantStateF",
        },
        {
            key: "G",
            producerState: "producerStateG",
            applicantState: "applicantStateG",
        }
    ]

    const IsOwnerSectionFilledOneField = () => {
        let flagFillOwnerField = []
        for (let i = 0; i < ownerKeys.length; i++) {
            if (individualFixedIndexAnnuityApplication[ownerKeys[i]] == "" || individualFixedIndexAnnuityApplication[ownerKeys[i]] == null) {
                flagFillOwnerField.push(false)
            } else {
                flagFillOwnerField.push(true)
            }
        }
        if (flagFillOwnerField.indexOf(true) > -1) {
            return true;
        } else {
            return false;
        }
    }

    const AnnuitantValidation = () => {
        let flagValidEmail = isValidEmail(ownerEmail);
        if (
            annuitantFullName === "" &&
            annuitantSSN === "" &&
            // annuitantGender === "" &&
            // annuitantDOB === "" &&
            annuitantStreetAddress === "" &&
            annuitantZip === "" &&
            annuitantCity === "" &&
            annuitantState === "" &&
            annuitantEmail === "" &&
            annuitantPhone === "" &&
            // isValidEmail(annuitantEmail) === false &&
            annuitantRelationToOwner === ""
        ) {
            if (IsOwnerSectionFilledOneField()) {
                return true;
            } else {
                return false;
            }
        } else if (
            annuitantFullName === "" ||
            annuitantSSN === "" ||
            // annuitantGender === "" ||
            // annuitantDOB === "" ||
            annuitantStreetAddress === "" ||
            annuitantZip === "" ||
            annuitantCity === "" ||
            annuitantState === "" ||
            annuitantEmail === "" ||
            annuitantPhone === "" ||
            // isValidEmail(annuitantEmail) === false &&
            annuitantRelationToOwner === ""
        ) {
            return false;
        } else if (
            annuitantFullName !== "" &&
            annuitantSSN !== "" &&
            ((annuitantGender === "Not Applicable") || (annuitantGender !== "Not Applicable" && annuitantDOB !== "")) &&
            annuitantStreetAddress !== "" &&
            annuitantZip !== "" &&
            isValidZip(annuitantZip) === true &&
            annuitantCity !== "" &&
            annuitantState !== "" &&
            annuitantEmail !== "" &&
            annuitantPhone !== "" &&
            isValidEmail(annuitantEmail) === true &&
            // validatePhoneNumber(annuitantPhone) === true &&
            annuitantRelationToOwner !== ""
        ) {
            return true;
        }
        else {
            return false;
        }
    };

    const JointOwnerValidation = () => {
        if (
            jointOwnerFullName === "" &&
            jointOwnerSSN === "" &&
            jointOwnerGender === "" &&
            jointOwnerDOB === "" &&
            jointOwnerStreetAddress === "" &&
            jointOwnerZip === "" &&
            jointOwnerCity === "" &&
            jointOwnerState === "" &&
            jointOwnerEmail === "" &&
            isValidEmail(jointOwnerEmail) === false
            // jointOwnerRelationToOwner === ""
        ) {
            return true;
        } else if (
            jointOwnerFullName !== "" &&
            jointOwnerSSN !== "" &&
            jointOwnerGender !== "" &&
            ((jointOwnerGender === "Not Applicable") || (jointOwnerGender !== "Not Applicable" && jointOwnerDOB !== "")) &&
            jointOwnerStreetAddress !== "" &&
            jointOwnerZip !== "" &&
            jointOwnerCity !== "" &&
            jointOwnerState !== "" &&
            jointOwnerEmail !== ""
            // isValidEmail(ownerEmail) === true &&
            // jointOwnerRelationToOwner !== ""
        ) {
            return true;
        } else {
            return false;
        }
    };

    const JointAnnuitantValidation = () => {
        if (
            jointAnnuitantFullName === "" &&
            jointAnnuitantSSN === "" &&
            jointAnnuitantGender === "" &&
            jointAnnuitantDOB === "" &&
            jointAnnuitantStreetAddress === "" &&
            jointAnnuitantZip === "" &&
            jointAnnuitantCity === "" &&
            jointAnnuitantState === "" &&
            jointAnnuitantEmail === "" &&
            jointAnnuitantPhone === "" &&
            isValidEmail(jointAnnuitantEmail) === false &&
            jointAnnuitantRelationToAnnuitant === ""
        ) {
            return true;
        } else if (
            jointAnnuitantFullName !== "" &&
            jointAnnuitantSSN !== "" &&
            jointAnnuitantGender !== "" &&
            ((jointAnnuitantGender === "Not Applicable") || (jointAnnuitantGender !== "Not Applicable" && jointAnnuitantDOB !== "")) &&
            jointAnnuitantStreetAddress !== "" &&
            jointAnnuitantZip !== "" &&
            jointAnnuitantCity !== "" &&
            jointAnnuitantState !== "" &&
            jointAnnuitantEmail !== "" &&
            jointAnnuitantPhone !== "" &&
            // isValidEmail(ownerEmail) === true &&
            jointAnnuitantRelationToAnnuitant !== ""
        ) {
            return true;
        } else {
            return false;
        }
    };

    const InitialAccountAllocationValidation = () => {
        let tempPercentage = 0;
        accountAllocationArray?.map((obj) => {
            if (individualFixedIndexAnnuityApplication.initialAccountAllocation[obj.state] != "" && individualFixedIndexAnnuityApplication.initialAccountAllocation[obj.state] != -1) {
                tempPercentage += parseFloat(individualFixedIndexAnnuityApplication.initialAccountAllocation[obj.state])
            }
        })
        if (tempPercentage == 100) {
            return true
        } else {
            return false
        }
    }

    const ProducerDeclarationsValidation = () => {
        let tempProducerFlagEmpty = []
        producerDeclarationsArray.map((ele) => {
            if (individualFixedIndexAnnuityApplication[ele.producerState] !== "" && individualFixedIndexAnnuityApplication[ele.applicantState] !== "") {
                tempProducerFlagEmpty.push(true)
            } else {
                tempProducerFlagEmpty.push(false)
            }
        })

        if (tempProducerFlagEmpty?.indexOf(false) > -1) {
            return false
        } else {
            return true
        }
    }

    const BeneficiariesFieldValidation = () => {
        let emptyFields = []

        for (let i = 0; i < beneficiaries.length; i++) {
            if (
                beneficiaries[i].primaryOrContingent !== "" &&
                beneficiaries[i].name !== "" &&
                (beneficiaries[i].percentageField !== "" || beneficiaries[i].percentageField !== null) &&
                beneficiaries[i].relationshipField !== "" &&
                beneficiaries[i].genderField !== ""

            ) {
                emptyFields.push(true)
            } else if (
                beneficiaries[i].primaryOrContingent == "" &&
                beneficiaries[i].name == "" &&
                (beneficiaries[i].percentageField == "" || beneficiaries[i].percentageField == null) &&
                beneficiaries[i].relationshipField == "" &&
                beneficiaries[i].genderField == ""
            ) {
                emptyFields.push(true)
            } else {
                emptyFields.push(false)
            }
        }
        if (emptyFields?.indexOf(false) > -1) {
            return false
        } else {
            return true
        }
    }

    const BeneficiariesValidation = () => {
        let flagPrimaryValuesEmpty = [];
        let flagContingentValuesEmpty = [];
        let totalPrimarySum = 0;
        let totalContingentSum = 0;

        for (let i = 0; i < beneficiaries.length; i++) {
            if (beneficiaries[i].primaryOrContingent !== "" && beneficiaries[i].percentageField !== "") {
                if (beneficiaries[i].primaryOrContingent == "Primary") {
                    totalPrimarySum += parseFloat(beneficiaries[i].percentageField)
                    flagPrimaryValuesEmpty.push(false)
                } else if (beneficiaries[i].primaryOrContingent == "Contingent") {
                    totalContingentSum += parseFloat(beneficiaries[i].percentageField)
                    flagContingentValuesEmpty.push(false)
                }
            } else if (beneficiaries[i].primaryOrContingent == "") {
                flagPrimaryValuesEmpty.push(false)
                flagContingentValuesEmpty.push(false)

            } else {
                flagPrimaryValuesEmpty.push(true)
                flagContingentValuesEmpty.push(true)
            }
        }

        if ((flagPrimaryValuesEmpty?.indexOf(false) > -1 && totalPrimarySum == 100) || flagContingentValuesEmpty?.indexOf(false) > -1 && totalContingentSum == 100) {
            return true
        } else {
            return false
        }
    }

    const SpouseValidation = () => {
        if (
            spouseFullName === "" &&
            spouseGender === "" &&
            spouseEmploymentStatus === "" &&
            spouseCurrentOccupation === "" &&
            spouseCurrentAge === "" &&
            spouseDependents === ""
        ) {
            return true;
        } else if (
            spouseFullName !== "" &&
            spouseGender !== "" &&
            spouseEmploymentStatus !== "" &&
            spouseCurrentOccupation !== "" &&
            spouseCurrentAge !== "" &&
            spouseDependents !== ""
        ) {
            return true;
        } else {
            return false;
        }
    };

    const BasicInformationValidation = () => {
        const {
            annuitantOrInsured,
            currentInsuranceCompany,
            currentInsuranceCompanyPhone,
            currentInsuranceCompanyAccountNumber,
            currentInsuranceCompanyStreetAddress,
            currentInsuranceCompanyZip,
            currentInsuranceCompanyCity,
            currentInsuranceCompanyState,
            typeOfAccount,
            otherTypeOfAccount,
            ownerExistingAnnuity,
        } = transferOrExchangeRequest;

        if (
            transferOrExchangeRequest?.ownerFullName !== "" &&
            transferOrExchangeRequest?.ownerSSN !== "" &&
            transferOrExchangeRequest?.ownerStreetAddress !== "" &&
            transferOrExchangeRequest?.ownerZip !== "" &&
            // isValidZip(transferOrExchangeRequest?.ownerZip) === true &&
            transferOrExchangeRequest?.ownerCity !== "" &&
            transferOrExchangeRequest?.ownerState !== "" &&
            transferOrExchangeRequest?.ownerMaritalStatus !== "" &&
            // annuitantOrInsured !== "" &&
            currentInsuranceCompany !== "" &&
            currentInsuranceCompanyPhone !== "" &&
            currentInsuranceCompanyAccountNumber !== "" &&
            currentInsuranceCompanyState !== "" &&
            currentInsuranceCompanyStreetAddress !== "" &&
            currentInsuranceCompanyCity !== "" &&
            currentInsuranceCompanyPhone !== "" &&
            currentInsuranceCompanyZip !== "" &&
            // isValidZip(currentInsuranceCompanyZip) === true &&
            typeOfAccount !== "" &&
            (typeOfAccount === "other" && otherTypeOfAccount !== ""
                ? true
                : typeOfAccount !== "other"
                    ? true
                    : false) &&
            ownerExistingAnnuity !== ""
        ) {
            return "filled";
        } else if (
            transferOrExchangeRequest?.ownerFullName === "" &&
            transferOrExchangeRequest?.ownerSSN === "" &&
            transferOrExchangeRequest?.ownerStreetAddress === "" &&
            transferOrExchangeRequest?.ownerZip === "" &&
            // isValidZip(transferOrExchangeRequest?.ownerZip) === true &&
            transferOrExchangeRequest?.ownerCity === "" &&
            transferOrExchangeRequest?.ownerState === "" &&
            transferOrExchangeRequest?.ownerMaritalStatus === "" &&
            // annuitantOrInsured === "" &&
            currentInsuranceCompany === "" &&
            currentInsuranceCompanyPhone === "" &&
            currentInsuranceCompanyAccountNumber === "" &&
            currentInsuranceCompanyState === "" &&
            currentInsuranceCompanyStreetAddress === "" &&
            currentInsuranceCompanyCity === "" &&
            currentInsuranceCompanyPhone === "" &&
            currentInsuranceCompanyZip === "" &&
            // isValidZip(currentInsuranceCompanyZip) === true &&
            typeOfAccount === "" &&
            ownerExistingAnnuity === ""
        ) {
            return "blank";
        } else if (
            transferOrExchangeRequest?.ownerFullName !== "" ||
            transferOrExchangeRequest?.ownerSSN !== "" ||
            transferOrExchangeRequest?.ownerStreetAddress !== "" ||
            transferOrExchangeRequest?.ownerZip !== "" ||
            // isValidZip(transferOrExchangeRequest?.ownerZip) === true ||
            transferOrExchangeRequest?.ownerCity !== "" ||
            transferOrExchangeRequest?.ownerState !== "" ||
            transferOrExchangeRequest?.ownerMaritalStatus !== "" ||
            // annuitantOrInsured !== "" ||
            currentInsuranceCompany !== "" ||
            currentInsuranceCompanyPhone !== "" ||
            currentInsuranceCompanyAccountNumber !== "" ||
            currentInsuranceCompanyState !== "" ||
            currentInsuranceCompanyStreetAddress !== "" ||
            currentInsuranceCompanyCity !== "" ||
            currentInsuranceCompanyPhone !== "" ||
            currentInsuranceCompanyZip !== "" ||
            // isValidZip(currentInsuranceCompanyZip) === true ||
            typeOfAccount !== "" ||
            ownerExistingAnnuity !== ""
        ) {
            return "partially"
        }
        // else {
        //   return false;
        // }
    };

    const TransferExchangeBasicV2Validation = () => {
        const {
            transferV2Section1CompanyName,
            transferV2Section1CompanyPhone,
            transferV2Section1CompanyPolicyNumber,
            transferV2Section1CompanyAddress,
            transferV2Section1CompanyCity,
            transferV2Section1CompanyState,
            transferV2Section1CompanyZip,
            transferV2Section1OwnerName,
            transferV2Section1OwnerSsn,
            transferV2Section1JoinOwnerName,
            transferV2Section1JoinOwnerSsn,
            transferV2Section1AnnuitantName,
            transferV2Section1AnnuitantSsn,
            transferV2Section1JoinAnnuitantName,
            transferV2Section1JoinAnnuitantSsn,
            transferV2Section1BeneficiaryName,
            transferV2Section1BeneficiarySsn,
            transferV2Section1InvestmentVehicle,
            transferV2Section1InvestmentVehicleOther,
            transferV2Section1ExistingCertificate
        } = rolloverTransferExchange

        if (
            transferV2Section1CompanyName !== "" &&
            transferV2Section1CompanyPhone !== "" &&
            transferV2Section1CompanyPolicyNumber !== "" &&
            transferV2Section1CompanyAddress !== "" &&
            transferV2Section1CompanyCity !== "" &&
            transferV2Section1CompanyState !== "" &&
            transferV2Section1CompanyZip !== "" &&
            // transferV2Section1OwnerName !== "" &&
            // transferV2Section1OwnerSsn !== "" &&
            // transferV2Section1JoinOwnerName !== "" &&
            // transferV2Section1JoinOwnerSsn !== "" &&
            // transferV2Section1AnnuitantName !== "" &&
            // transferV2Section1AnnuitantSsn !== "" &&
            // transferV2Section1JoinAnnuitantName !== "" &&
            // transferV2Section1JoinAnnuitantSsn !== "" &&
            // transferV2Section1BeneficiaryName !== "" &&
            // transferV2Section1BeneficiarySsn !== "" &&
            transferV2Section1InvestmentVehicle !== "" &&
            // transferV2Section1InvestmentVehicleOther !== "" &&
            transferV2Section1ExistingCertificate !== ""

        ) {
            return "filled"
        } else if (
            transferV2Section1CompanyName == "" &&
            transferV2Section1CompanyPhone == "" &&
            transferV2Section1CompanyPolicyNumber == "" &&
            transferV2Section1CompanyAddress == "" &&
            transferV2Section1CompanyCity == "" &&
            transferV2Section1CompanyState == "" &&
            transferV2Section1CompanyZip == "" &&
            // transferV2Section1OwnerName == "" &&
            // transferV2Section1OwnerSsn == "" &&
            // transferV2Section1JoinOwnerName == "" &&
            // transferV2Section1JoinOwnerSsn == "" &&
            // transferV2Section1AnnuitantName == "" &&
            // transferV2Section1AnnuitantSsn == "" &&
            // transferV2Section1JoinAnnuitantName == "" &&
            // transferV2Section1JoinAnnuitantSsn == "" &&
            // transferV2Section1BeneficiaryName == "" &&
            // transferV2Section1BeneficiarySsn == "" &&
            transferV2Section1InvestmentVehicle == "" &&
            // transferV2Section1InvestmentVehicleOther == "" &&
            transferV2Section1ExistingCertificate == ""
        ) {
            return "blank"
        } else if (
            transferV2Section1CompanyName == "" ||
            transferV2Section1CompanyPhone == "" ||
            transferV2Section1CompanyPolicyNumber == "" ||
            transferV2Section1CompanyAddress == "" ||
            transferV2Section1CompanyCity == "" ||
            transferV2Section1CompanyState == "" ||
            transferV2Section1CompanyZip == "" ||
            // transferV2Section1OwnerName == "" ||
            // transferV2Section1OwnerSsn == "" ||
            // transferV2Section1JoinOwnerName == "" ||
            // transferV2Section1JoinOwnerSsn == "" ||
            // transferV2Section1AnnuitantName == "" ||
            // transferV2Section1AnnuitantSsn == "" ||
            // transferV2Section1JoinAnnuitantName == "" ||
            // transferV2Section1JoinAnnuitantSsn == "" ||
            // transferV2Section1BeneficiaryName == "" ||
            // transferV2Section1BeneficiarySsn == "" ||
            transferV2Section1InvestmentVehicle == "" ||
            // transferV2Section1InvestmentVehicleOther == "" ||
            transferV2Section1ExistingCertificate == ""
        ) {
            return "partially"
        }
    }

    const TransferExchangePlansV2Validation = () => {
        const {
            transferV2Section2Plan,
            transferV2Section2NonQualified1035Exchange,
            transferV2Section2NonQualified1035ExchangeOtherPercentage,
            transferV2Section2NonQualified1035ExchangeOtherAmount,
            transferV2Section2QualifiedAgeRmdTransfer,
            transferV2Section2QualifiedTypeOfAccount,
            transferV2Section2QualifiedTypeOfAccountOther,
            transferV2Section2QualifiedLiquidateTransfer,
            transferV2Section2QualifiedLiquidateTransferPercentage,
            transferV2Section2QualifiedLiquidateTransferAmount,
            transferV2Section2QualifiedWithdrawFunds,
            transferV2Section2QualifiedWithdrawFundsOther,
            transferV2Section2QualifiedFundsTransfered
        } = rolloverTransferExchange

        if (transferV2Section2Plan == "") {
            return "blank"
        } else if (transferV2Section2Plan == "Qualified Plans") {
            if (
                // transferV2Section2QualifiedAgeRmdTransfer !== "" &&
                transferV2Section2QualifiedTypeOfAccount !== "" &&
                transferV2Section2QualifiedLiquidateTransfer !== "" &&
                transferV2Section2QualifiedWithdrawFunds !== "" &&
                transferV2Section2QualifiedFundsTransfered !== ""
            ) {
                if (
                    (transferV2Section2QualifiedTypeOfAccount == "other" && transferV2Section2QualifiedTypeOfAccountOther == "") ||
                    (transferV2Section2QualifiedLiquidateTransfer == "partial" &&
                        transferV2Section2QualifiedLiquidateTransferAmount == "" && transferV2Section2QualifiedLiquidateTransferPercentage == "") ||
                    (transferV2Section2QualifiedWithdrawFunds == "maturity date" && transferV2Section2QualifiedWithdrawFundsOther == "")
                ) {
                    return "partially"
                } else {
                    return "filled"
                }
            } else if (
                // transferV2Section2QualifiedAgeRmdTransfer == "" &&
                transferV2Section2QualifiedTypeOfAccount == "" &&
                transferV2Section2QualifiedLiquidateTransfer == "" &&
                transferV2Section2QualifiedWithdrawFunds == "" &&
                transferV2Section2QualifiedFundsTransfered == ""
            ) {
                return "blank"
            } else if (
                // transferV2Section2QualifiedAgeRmdTransfer == "" ||
                transferV2Section2QualifiedTypeOfAccount == "" ||
                transferV2Section2QualifiedLiquidateTransfer == "" ||
                transferV2Section2QualifiedWithdrawFunds == "" ||
                transferV2Section2QualifiedFundsTransfered == ""
            ) {
                return "partially"
            }
        } else if (transferV2Section2Plan == "Non-Qualified Plans") {
            if ((transferV2Section2NonQualified1035Exchange !== "")) {
                if (transferV2Section2NonQualified1035Exchange == "partial exchange" &&
                    (transferV2Section2NonQualified1035ExchangeOtherPercentage !== "" || transferV2Section2NonQualified1035ExchangeOtherAmount !== "")) {
                    return "filled"
                } else if (transferV2Section2NonQualified1035Exchange !== "partial exchange") {
                    return "filled"
                } else {
                    return "partially"
                }
            } else if (transferV2Section2NonQualified1035Exchange == "") {
                return "blank"
            }
        }
    }

    const CheckInsuredPolicyFieldArray = () => {
        let temp = [];
        temp = insurerPolicyDetailFieldArray.map((v) => {
            if (
                v.existingCarrierName == "" &&
                v.existingCarrierAddress == "" &&
                v.contractOrPolicyNumber == "" &&
                v.nameOfInsuredOrAnnuitant == "" &&
                v.amount == "" &&
                v.effectiveDate == ""
            ) {
                return true;
            } else if (
                v.existingCarrierName !== "" ||
                v.existingCarrierAddress !== "" ||
                v.contractOrPolicyNumber !== "" ||
                v.nameOfInsuredOrAnnuitant !== "" ||
                v.amount !== "" ||
                v.effectiveDate !== ""
            ) {
                if (
                    v.existingCarrierName === "" ||
                    v.contractOrPolicyNumber === "" ||
                    v.nameOfInsuredOrAnnuitant === ""
                ) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        });

        if (temp.indexOf(false) > -1) {
            return false;
        } else {
            return true;
        }
    };

    const PlansValidation = () => {
        const {
            plans,
            flagNonQualifiedOwnerWantWithdrawalToExchange,
            explainNonQualifiedOwnerWantWithdrawalToExchange,
            flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal,
            qualifiedTypeOfTransfer,
            qualifiedQualifyingEventForDirectRolloverFunds,
            flagQualifiedOwnerReachedAge72OrMoreInThisYear,
            flagQualifiedOwnerSatisfiedRMDFromDistributingPlan,
            qualifiedInternalExchangeOfExistingAnnuityName,
            flagQualifiedOwnerWantRMDBeforeCompletingExchange,
            flagQualifiedOwnerWantToContinueExistingRMDSchedule,
        } = transferOrExchangeRequest;

        if (plans === "") {
            return "blank";
        } else {
            if (plans === "Non-Qualified Plans") {
                if (CommonPlansQuestionValidation() &&
                    flagNonQualifiedOwnerWantWithdrawalToExchange !== "" &&
                    (flagNonQualifiedOwnerWantWithdrawalToExchange === "yes" &&
                        explainNonQualifiedOwnerWantWithdrawalToExchange !== ""
                        ? true
                        : flagNonQualifiedOwnerWantWithdrawalToExchange === "no"
                            ? true
                            : false) &&
                    flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal !== ""
                ) {
                    return "filled";
                } else {
                    return "partially";
                }
            } else {
                if (CommonPlansQuestionValidation() &&
                    qualifiedTypeOfTransfer !== "" &&
                    qualifiedQualifyingEventForDirectRolloverFunds !== "" &&
                    flagQualifiedOwnerReachedAge72OrMoreInThisYear !== "" &&
                    flagQualifiedOwnerSatisfiedRMDFromDistributingPlan !== "" &&
                    (flagQualifiedOwnerReachedAge72OrMoreInThisYear === "yes" &&
                        flagQualifiedOwnerSatisfiedRMDFromDistributingPlan === "no"
                        ? qualifiedInternalExchangeOfExistingAnnuityName !== ""
                        : true)
                    //   &&
                    // flagQualifiedOwnerWantRMDBeforeCompletingExchange !== "" &&
                    // flagQualifiedOwnerWantToContinueExistingRMDSchedule !== ""
                ) {
                    return "filled";
                } else {
                    return "partially";
                }
            }
        }
    };

    const ConditionToShowReplacementForm = () => {
        if (ownerState?.length && ownerState !== "" && ownerState !== "Tennessee") {
            return true
        } else {
            if (individualFixedIndexAnnuityApplication.annuitantState?.length && individualFixedIndexAnnuityApplication.annuitantState !== "" && individualFixedIndexAnnuityApplication.annuitantState !== "Tennessee") {
                return true
            } else {
                return false
            }
        }
    };

    const CommonPlansQuestionValidation = () => {
        if (qualifiedTransferOrExchangeExistingAccountAllOrPartial !== "" &&
            (qualifiedTransferOrExchangeExistingAccountAllOrPartial === "partials"
                ? qualifiedTransferOrExchangeExistingAccountPartialAmount !== ""
                : true) &&
            qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate !==
            "" &&
            (qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate ===
                "upon the maturity date of"
                ? qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !==
                ""
                : true) &&
            qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy !==
            "" &&
            (qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy ===
                "to existing policy/account"
                ? qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField !==
                ""
                : true)) {
            return true
        } else {
            return false
        }
    }

    const IsAnnuitantRequired = () => {

        let annuitantKeys = [
            "annuitantFullName",
            "annuitantSSN",
            "annuitantGender",
            "annuitantDOB",
            "annuitantStreetAddress",
            "annuitantZip",
            "annuitantCity",
            "annuitantState",
            "annuitantEmail",
            "annuitantRelationToOwner",
            "annuitantPhone",
        ]

        let flagFillAnnuitantField = false
        for (let i = 0; i < annuitantKeys.length; i++) {
            if (individualFixedIndexAnnuityApplication[annuitantKeys[i]] !== "" && individualFixedIndexAnnuityApplication[annuitantKeys[i]] !== null) {
                flagFillAnnuitantField = true
            }
        }

        return flagFillAnnuitantField
    }


    const Validation = () => {
        // whole annuitant, owner - joint owner (one field is filled then all else zero), plans applied for


        // plans applied for
        const {
            producerFirstName,
            producerLastName,
            producerBusinessName,
            producerWebsite,
            producerBusinessMailingAddress,
            producerCity,
            producerState,
            producerZip,
            producerTelephone,
            producerEmail,
            producerNationalNumber,
            producerInStateOf,
            producerCustomerFirstName,
            producerCustomerLastName,
            producerOfferedProducts,
            producerNonInsuranceProducts,
            producerAuthorizedToSell,
            producerAuthorizedToSellDescription,
            producerPaidForWork,
            producerPaidForWorkCommissionDescription,
            producerPaidForWorkOtherDescription } = producerDisclosureForm

        let temp = [];
        let returnObj = {};

        if (ownerFullName === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "Owner full name",
                type: "Empty Field"
            });
        }
        if (ownerSSN === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "SSN/TIN",
                type: "Empty Field"
            });
        }
        if (ownerGender === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "Gender",
                type: "Empty Field"
            });
        }
        if (ownerDOB === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "Date of Birth",
                type: "Empty Field"
            });
        }
        if (ownerStreetAddress === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "Street Address",
                type: "Empty Field"
            });
        }
        if (ownerZip === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "Zip",
                type: "Empty Field"
            });
        }
        if (ownerCity === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "City",
                type: "Empty Field"
            });
        }
        if (ownerState === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "State",
                type: "Empty Field"
            });
        }
        if (ownerEmail === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "Email",
                type: "Empty Field"
            });
        }
        if (ownerRelationToAnnuitant === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "Relationship To Annuitant",
                type: "Empty Field"
            });
        }
        if (ownerPhone === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "Phone",
                type: "Empty Field"
            });
        }
        if (validatePhoneNumberLatest(ownerPhone) === false && ownerPhone?.length > 0 && ownerPhone !== "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "Phone number is not valid",
                type: "Incorrect Details"
            });
        }
        if (isValidEmail(ownerEmail) === false && ownerEmail.length > 0 && ownerEmail !== "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "Email Id is not valid",
                type: "Incorrect Details"
            });
        }
        if (isValidZip(ownerZip) === false && ownerZip.length > 0) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "1",
                field: "ZIP number should only contain 5 digits",
                type: "Incorrect Details"
            });
        }
        if (IsAnnuitantRequired() == true && AnnuitantValidation() === false) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "3",
                field: "All fields are required",
                type: "Empty Field"
            });
            if (isValidZip(annuitantZip) === false && annuitantZip.length > 0) {
                returnObj["status"] = false;
                temp.push({
                    page: "Individual Fixed Index Annuity Application",
                    section: "3",
                    field: "ZIP number only contains 5 digits",
                    type: "Incorrect Details"
                });
            }
            if (isValidEmail(annuitantEmail) === false && annuitantEmail.length > 0 && annuitantEmail !== "") {
                returnObj["status"] = false;
                temp.push({
                    page: "Individual Fixed Index Annuity Application",
                    section: "3",
                    field: "Email Id is not valid",
                    type: "Incorrect Details"
                });
            }
            if (validatePhoneNumberLatest(annuitantPhone) === false && annuitantPhone?.length > 0 && annuitantPhone !== "") {
                returnObj["status"] = false;
                temp.push({
                    page: "Individual Fixed Index Annuity Application",
                    section: "3",
                    field: "Phone number is not valid",
                    type: "Incorrect Details"
                });
            }
        }
        if (JointOwnerValidation() === false) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "2",
                field: "All fields are required",
                type: "Empty Field"
            });
        } else {
            if (validatePhoneNumberLatest(jointOwnerPhone) === false && jointOwnerPhone?.length > 0 && jointOwnerPhone !== "") {
                returnObj["status"] = false;
                temp.push({
                    page: "Individual Fixed Index Annuity Application",
                    section: "2",
                    field: "Phone number is not valid",
                    type: "Incorrect Details"
                });
            }
        }
        if (JointAnnuitantValidation() === false) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "4",
                field: "All fields are required",
                type: "Empty Field"
            });
        }
        if (validatePhoneNumberLatest(jointAnnuitantPhone) === false && jointAnnuitantPhone?.length > 0 && jointAnnuitantPhone !== "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "4",
                field: "Phone number is not valid",
                type: "Incorrect Details"
            });
        }
        if (isValidEmail(jointOwnerEmail) === false && jointOwnerEmail.length >= 0 && jointOwnerEmail !== "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "2",
                field: "Email Id is not valid",
                type: "Incorrect Details"
            });
        }
        if (isValidZip(jointOwnerZip) === false && jointOwnerZip.length > 0) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "2",
                field: "ZIP number only contains 5 digits",
                type: "Incorrect Details"
            });
        }
        // 10th january 4th section validation

        if (planHarvest.length == 0) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "5",
                field: "Please select atleast one harvest plan",
                type: "Empty Field"
            });
        } else if (ridersPlan.length == 0) {

            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "5",
                field: "Please select atleast one Rider",
                type: "Empty Field"
            });
        } else {
        }


        if (InitialAccountAllocationValidation() == false) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "6",
                field: "Initial Account Allocation Percentage should not be more than or less than 100%",
                type: "Incorrect Details"
            });
        }

        if (ProducerDeclarationsValidation() == false) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "9",
                field: "All fields are required",
                type: "Empty Field"
            });
        }
        if (BeneficiariesFieldValidation() == false) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "7",
                field: "All fields are required",
                type: "Empty Field"
            });
        }
        if (BeneficiariesValidation() == false) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "7",
                field: "Beneficiaries Percentage should not be more than or less than 100%",
                type: "Incorrect Details"
            });
        }

        if ((flagQualifiedOrNonQualifiedSelected !== "" &&
            selectedNonQualifiedOptions.length > 0 &&
            amountPaidWithApplication === ""
        )) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "8",
                field: "Amount Paid With Application",
                type: "Empty Field"
            });
        }

        if (
            (flagQualifiedOrNonQualifiedSelected !== "" &&
                selectedNonQualifiedOptions.length > 0 &&
                (selectedNonQualifiedOptions.indexOf("transfer") > -1 || selectedNonQualifiedOptions.indexOf("rollover") > -1 || selectedNonQualifiedOptions.indexOf("1035 exchange") > -1)
                // && selectedQualifiedOptionsGroup2 !== "" 
                && expectedPremiumField === "")
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "8",
                field: "expected Premium Field",
                type: "Empty Field"
            });
        }

        if (flagQualifiedOrNonQualifiedSelected === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "8",
                field: "Select atleast One checkBox out of two",
                type: "Empty Field"
            });
        }
        if (
            flagQualifiedOrNonQualifiedSelected !== "" &&
            selectedNonQualifiedOptions?.length == 0
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "8",
                field: "Select atleast one tax qualification status",
                type: "Empty Field"
            });
        }
        if (
            flagQualifiedOrNonQualifiedSelected !== "" &&
            (selectedNonQualifiedOptions?.length > 0 && selectedQualifiedOptionsGroup2 === "")
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Individual Fixed Index Annuity Application",
                section: "8",
                field: "Select atleast one Check type of plan",
                type: "Empty Field"
            });
        }

        if (
            suitabilityAnalysis.ownerFullName === "" ||
            ownerCurrentAge === "" ||
            suitabilityAnalysis.ownerGender === "" ||
            ownerEmploymentStatus === "" ||
            ownerCurrentOccupation === "" ||
            ownerDependents === ""
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Suitability Analysis",
                section: "1",
                field: "All information must be filled",
                type: "Empty Field"
            });
        }

        if (
            ownerAnnualHouseholdIncome === "" ||
            ownerSourceOfIncome === "" ||
            ownerTotalAssets === "" ||
            ownerTotalLiquidAssets === "" ||
            ownerTotalOutstandingDebt === "" ||
            ownerIncomeTaxBracket === ""
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Suitability Analysis",
                section: "2",
                field: "All fields of section 2 must be filled",
                type: "Empty Field"
            });
        }
        if (
            flagOwnerIncomeCoverLivingAndMedicalExpenses === "" ||
            flagOwnerExpectChangesInLivingExpenses === "" ||
            flagOwnerAnticipateChangesInMedicalExpenses === "" ||
            flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
            "" ||
            flagOwnerHaveEmergencyFundForUnexpectedExpenses === "" ||
            ownerOtherInvestments.length === 0 ||
            ownerRiskTolerance === ""
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Suitability Analysis",
                section: "3",
                field: "All fields of section 3 must be filled",
                type: "Empty Field"
            });
        }
        if (
            (flagOwnerIncomeCoverLivingAndMedicalExpenses === "no" &&
                explainOwnerIncomeCoverLivingAndMedicalExpenses === "") ||
            (flagOwnerExpectChangesInLivingExpenses === "yes" &&
                explainOwnerExpectChangesInLivingExpenses === "") ||
            (flagOwnerAnticipateChangesInMedicalExpenses === "yes" &&
                explainOwnerAnticipateChangesInMedicalExpenses === "") ||
            (flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
                "no" &&
                explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
                "") ||
            (flagOwnerHaveEmergencyFundForUnexpectedExpenses === "no" &&
                explainOwnerHaveEmergencyFundForUnexpectedExpenses === "")
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Suitability Analysis",
                section: "3",
                field: "Please fill all the fields of explain for section 3",
                type: "Empty Field"
            });
        }
        if (
            reasonForPurchasingProduct.length === 0 ||
            flagOwnerPlanToWithdrawFundInSurrenderPeriod === "" ||
            (flagOwnerPlanToWithdrawFundInSurrenderPeriod === "yes" &&
                explainOwnerPlanToWithdrawFundInSurrenderPeriod === "") ||
            ownerAnticipateNeedingAccessToFunds === "" ||
            sourceOfFundsToPurchaseThisProduct.length === 0
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Suitability Analysis",
                section: "4",
                field: "Please fill all the fields",
                type: "Empty Field"
            });
        }
        if (
            flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct === ""
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Suitability Analysis",
                section: "5",
                field: "Please fill all the fields",
                type: "Empty Field"
            });
        }
        if (
            flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct ===
            "yes" &&
            (howLongHasTheLifeInsuranceBeenInForce === "" ||
                flagSurrenderChargeOnExistingProduct === "" ||
                typeOfExistingProduct === "" ||
                interestRateForExistingProduct === "" ||
                flagTakingRequiredMinimumDistributionsFromExistingProduct === "" ||
                flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution === "")
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Suitability Analysis",
                section: "5",
                field: "Please fill all the fields",
                type: "Empty Field"
            });
        }
        if (
            flagSurrenderChargeOnExistingProduct === "yes" &&
            (currentSurrenderChargeOnExistingProduct === "" ||
                expirationOfCurrentSurrenderCharge === "") &&
            flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct ===
            "yes"
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Suitability Analysis",
                section: "5",
                field: "What is current charge and when it will expire fields are required",
                type: "Empty Field"
            });
        }
        if (
            flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution ===
            "yes" &&
            explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution === ""
        ) {
            returnObj["status"] = false;
            temp.push({
                page: "Suitability Analysis",
                section: "5",
                field: "For question 7, please describe",
                type: "Empty Field"
            });
        }
        if (agentAcknowledgment === "") {
            returnObj["status"] = false;
            temp.push({
                page: "Suitability Analysis",
                section: "7",
                field: "fill this section",
                type: "Empty Field"
            });
        }

        if (ConditionToShowReplacementForm() === true) {
            if (
                flagDiscontinuingPremiumPayment === "" ||
                flagPayForPremiumPolicy === ""
            ) {
                returnObj["status"] = false;
                temp.push({
                    page: "Notice of Replacement",
                    section: "1",
                    field: "Please fill all fields",
                    type: "Empty Field"
                });
            }
            if (
                (flagDiscontinuingPremiumPayment === "yes" ||
                    flagPayForPremiumPolicy === "yes") &&
                (CheckInsuredPolicyFieldArray() === false)
            ) {
                returnObj["status"] = false;
                temp.push({
                    page: "Notice of Replacement",
                    section: "1",
                    field: "Question C is required ",
                    type: "Empty Field"
                });
            }
            if (
                (flagDiscontinuingPremiumPayment === "yes" ||
                    flagPayForPremiumPolicy === "yes") &&
                (reasonForReplacement == "")
            ) {
                returnObj["status"] = false;
                temp.push({
                    page: "Notice of Replacement",
                    section: "1",
                    field: "Question D is required ",
                    type: "Empty Field"
                });
            }
        }

        if (transferExchangeVersion === 7) {
            if ((TransferExchangeBasicV2Validation() == "blank" && TransferExchangePlansV2Validation() == "blank")
                || (TransferExchangeBasicV2Validation() == "filled" && TransferExchangePlansV2Validation() == "filled")) {
                if (TransferExchangeBasicV2Validation() == "filled") {
                    if (isValidZip(rolloverTransferExchange?.transferV2Section1CompanyZip) === false &&
                        rolloverTransferExchange?.transferV2Section1CompanyZip.length > 0 &&
                        rolloverTransferExchange?.transferV2Section1CompanyZip !== "") {
                        returnObj["status"] = false;
                        temp.push({
                            page: "Transfer or Exchange Request",
                            section: "1",
                            field: "ZIP number should contains 5 digits",
                            type: "Incorrect Details"
                        });
                    }
                    if (
                        validatePhoneNumber(rolloverTransferExchange.transferV2Section1CompanyPhone) === false &&
                        rolloverTransferExchange.transferV2Section1CompanyPhone?.length > 0 &&
                        rolloverTransferExchange.transferV2Section1CompanyPhone !== "") {
                        returnObj["status"] = false;
                        temp.push({
                            page: "Transfer or Exchange Request",
                            section: "1",
                            field: "Phone Number is not valid",
                            type: "Incorrect Details"
                        });
                    }

                }

            } else {
                if ((TransferExchangeBasicV2Validation() === "partially" && TransferExchangePlansV2Validation() == "partially") ||
                    (TransferExchangeBasicV2Validation() === "blank" && TransferExchangePlansV2Validation() == "partially") ||
                    (TransferExchangeBasicV2Validation() === "partially" && TransferExchangePlansV2Validation() == "blank")
                ) {
                    returnObj["status"] = false;
                    temp.push({
                        page: "Transfer or Exchange Request",
                        section: "1",
                        field: "Please fill all fields",
                        type: "Empty Field"
                    });
                    temp.push({
                        page: "Transfer or Exchange Request",
                        section: "2",
                        field: "Please fill all fields",
                        type: "Empty Field"
                    });
                }
                if (TransferExchangePlansV2Validation() == "filled" && (TransferExchangeBasicV2Validation() == "blank" || TransferExchangeBasicV2Validation() == "partially")) {
                    returnObj["status"] = false;
                    temp.push({
                        page: "Transfer or Exchange Request",
                        section: "1",
                        field: "Please fill all fields",
                        type: "Empty Field"
                    });
                }
                if (TransferExchangeBasicV2Validation() == "filled" && (TransferExchangePlansV2Validation() == "blank" || TransferExchangePlansV2Validation() == "partially")) {
                    returnObj["status"] = false;
                    temp.push({
                        page: "Transfer or Exchange Request",
                        section: "2",
                        field: "Please fill all fields",
                        type: "Empty Field"
                    });
                }
            }
        } else {
            if (
                (BasicInformationValidation() == "blank" && PlansValidation() == "blank" && flagSignatureDigitalOrPhysical == "")
                ||
                (BasicInformationValidation() == "filled" && PlansValidation() == "filled" && flagSignatureDigitalOrPhysical !== "")
            ) {
                // return true
            } else {
                if ((BasicInformationValidation() === "filled" || flagSignatureDigitalOrPhysical !== "") &&
                    (PlansValidation() == "blank" || PlansValidation() == "partially")) {
                    returnObj["status"] = false;
                    temp.push({
                        page: "Transfer or Exchange Request",
                        section: "2",
                        field: "Please fill all fields",
                        type: "Empty Field"
                    });
                }
                if ((PlansValidation() === "filled" || flagSignatureDigitalOrPhysical !== "") &&
                    (BasicInformationValidation() == "blank" || BasicInformationValidation() == "partially")) {
                    returnObj["status"] = false;
                    temp.push({
                        page: "Transfer or Exchange Request",
                        section: "1",
                        field: "Please fill all fields",
                        type: "Empty Field"
                    });
                }
                if (
                    (BasicInformationValidation() === "partially" && PlansValidation() == "partially") ||
                    (BasicInformationValidation() === "blank" && PlansValidation() == "partially") ||
                    (BasicInformationValidation() === "partially" && PlansValidation() == "blank")
                ) {
                    returnObj["status"] = false;
                    temp.push({
                        page: "Transfer or Exchange Request",
                        section: "1",
                        field: "Please fill all fields",
                        type: "Empty Field"
                    });
                    temp.push({
                        page: "Transfer or Exchange Request",
                        section: "2",
                        field: "Please fill all fields",
                        type: "Empty Field"
                    });
                }
                if (flagSignatureDigitalOrPhysical === "") {
                    temp.push({
                        page: "Transfer or Exchange Request",
                        section: "3",
                        field: "Please fill all fields",
                        type: "Empty Field"
                    });
                }
            }
            if (
                isValidZip(transferOrExchangeRequest?.ownerZip) === false &&
                transferOrExchangeRequest?.ownerZip.length > 0 &&
                transferOrExchangeRequest?.ownerZip !== ""
            ) {
                returnObj["status"] = false;
                temp.push({
                    page: "Transfer or Exchange Request",
                    section: "1",
                    field: "ZIP number should contains 5 digits",
                    type: "Incorrect Details"
                });
            }
            if (
                isValidZip(currentInsuranceCompanyZip) === false &&
                currentInsuranceCompanyZip.length > 0 &&
                currentInsuranceCompanyZip !== ""
            ) {
                returnObj["status"] = false;
                temp.push({
                    page: "Transfer or Exchange Request",
                    section: "1",
                    field: "ZIP number should contains 5 digits",
                    type: "Incorrect Details"
                });
            }

            if (
                validatePhoneNumber(currentInsuranceCompanyPhone) === false &&
                currentInsuranceCompanyPhone !== "" &&
                currentInsuranceCompanyPhone.length > 0
            ) {
                returnObj["status"] = false;
                temp.push({
                    page: "Transfer or Exchange Request",
                    section: "1",
                    field: "Phone Number is not valid",
                    type: "Incorrect Details"
                });
            }
        }

        if (fiaDisclosureVersion == 8) {
            if (flagHarvestClosure == "") {
                returnObj["status"] = false;
                temp.push({
                    page: "Annuity Disclosure",
                    section: "6",
                    field: "fill this section",
                    type: "Empty Field"
                });
            }
        } else if (fiaDisclosureVersion == 10) {
            if (flagAnnuityClosureV2 == "") {
                returnObj["status"] = false;
                temp.push({
                    page: "Annuity Disclosure",
                    section: "6",
                    field: "Please check any one of the Harvest Fixed Index annuity disclosure",
                    type: "Empty Field"
                });
            }
            // if (flagAnnuityClosureBackupWithout == "no") {
            //     returnObj["status"] = false;
            //     temp.push({
            //         page: "Annuity Disclosure",
            //         section: "6",
            //         field: "Please confirm if you are subject to backup withholding",
            //         type: "Empty Field"
            //     });
            // }
        } else {
            if (flagAnnuityClosure == "") {
                returnObj["status"] = false;
                temp.push({
                    page: "Annuity Disclosure",
                    section: "6",
                    field: "fill this section",
                    type: "Empty Field"
                });
            }
        }
        // producer disclosure
        if (flagShowProducerDisclosure) {
            const ProducerInfoValidate = () => {
                if (
                    producerFirstName !== "" &&
                    producerLastName !== "" &&
                    producerBusinessName !== "" &&
                    // producerWebsite !== "" &&
                    producerBusinessMailingAddress !== "" &&
                    producerCity !== "" &&
                    producerState !== "" &&
                    producerZip !== "" &&
                    producerTelephone !== "" &&
                    producerEmail !== "" &&
                    producerNationalNumber !== "" &&
                    producerInStateOf !== ""
                ) {
                    return true
                } else {
                    return false
                }
            }
            const ProducerCustomerInfoValidate = () => {
                if (producerCustomerFirstName !== "" && producerCustomerLastName !== "") {
                    return true
                } else {
                    return false
                }
            }
            const producerOfferProductsValidate = () => {
                if (producerOfferedProducts.length > 0) {
                    return true
                } else {
                    return false
                }
            }
            const producerNonInsuranceProductsValidate = () => {
                if (producerNonInsuranceProducts.length > 0) {
                    return true
                } else {
                    return false
                }
            }
            const producerAuthorisedToSellValidate = () => {
                if (
                    producerAuthorizedToSell.length === 0
                ) {
                    return false
                } else if (
                    producerAuthorizedToSell.length !== 0
                ) {
                    if (producerAuthorizedToSell.indexOf("more insurer") < 0) {
                        return true
                    } else {
                        if (producerAuthorizedToSellDescription !== "") {
                            return true
                        } else {
                            return false
                        }
                    }
                } else {
                    return false
                }
            }
            const producerPaidForWorkValidate = () => {
                if (
                    producerPaidForWork.length === 0
                ) {
                    return false
                } else if (
                    producerPaidForWork.length !== 0
                ) {
                    if (producerPaidForWork.indexOf("commission") < 0 && producerPaidForWork.indexOf("other")) {
                        return true
                    } else {
                        // if (producerPaidForWork.includes("commission") && producerPaidForWorkCommissionDescription === "") {
                        //     return false
                        // } else if (producerPaidForWork.includes("other") > 0 && producerPaidForWorkOtherDescription === "") {
                        //     return false
                        // } else {
                        // }
                        return true
                    }
                } else {
                    return false
                }
            }
            if (ProducerInfoValidate() == false) {
                returnObj["status"] = false;
                temp.push({
                    page: "Producer Disclosure",
                    section: "1",
                    field: "Please fill all required fields",
                    type: "Empty Field"
                });
            }
            if (
                isValidEmail(producerEmail) === false &&
                producerEmail.length > 0 &&
                producerEmail !== ""
            ) {
                returnObj["status"] = false;
                temp.push({
                    page: "Producer Disclosure",
                    section: "1",
                    field: "Email Id is not valid",
                    type: "Incorrect Details"
                });
            }
            if (isValidZip(producerZip) === false && producerZip.length > 0) {
                returnObj["status"] = false;
                temp.push({
                    page: "Producer Disclosure",
                    section: "1",
                    field: "ZIP number only contains 5 digits",
                    type: "Incorrect Details"
                });
            }
            if (producerTelephone?.length > 0 && validatePhoneNumberLatest(producerTelephone) === false) {
                returnObj["status"] = false;
                temp.push({
                    page: "Producer Disclosure",
                    section: "1",
                    field: "Phone Number is not valid",
                    type: "Incorrect Details"
                });
            }
            if (isValidWebsite(producerWebsite) === false && producerWebsite.length > 0 && producerWebsite !== "") {
                returnObj["status"] = false;
                temp.push({
                    page: "Producer Disclosure",
                    section: "1",
                    field: "Website is not valid",
                    type: "Incorrect Details"
                });
            }
            if (isValidNPN(producerNationalNumber) === false && producerNationalNumber.length > 0 && producerNationalNumber !== "") {
                returnObj["status"] = false;
                temp.push({
                    page: "Producer Disclosure",
                    section: "1",
                    field: "National Number should not exceed 10 digits",
                    type: "Incorrect Details"
                });
            }
            if (ProducerCustomerInfoValidate() == false) {
                returnObj["status"] = false;
                temp.push({
                    page: "Producer Disclosure",
                    section: "2",
                    field: "Please fill all required fields",
                    type: "Empty Field"
                });
            }
            // if (producerOfferProductsValidate() == false || producerNonInsuranceProductsValidate() === false) {
            //     returnObj["status"] = false;
            //     temp.push({
            //         page: "Producer Disclosure",
            //         section: "3",
            //         field: "Please fill all required fields",
            //         type: "Empty Field"
            //     });
            // }
            // if (producerAuthorisedToSellValidate() == false) {
            //     returnObj["status"] = false;
            //     temp.push({
            //         page: "Producer Disclosure",
            //         section: "4",
            //         field: "Please fill all required fields",
            //         type: "Empty Field"
            //     });
            // }
            if (producerPaidForWorkValidate() === false) {
                returnObj["status"] = false;
                temp.push({
                    page: "Producer Disclosure",
                    section: "5",
                    field: "Please fill all required fields",
                    type: "Empty Field"
                });
            }
        }
        returnObj["data"] = temp;
        return returnObj;
    };

    useEffect(() => {
        if (stateToShowNoticeOfReplacement !== "Tennessee" &&
            stateToShowNoticeOfReplacement !== ""
        ) {
            if (ownerState === "") {
                SetWholeNoticeOfReplacement({
                    flagDiscontinuingPremiumPayment: "",
                    flagPayForPremiumPolicy: "",
                    insurerPolicyDetailFieldArray: [
                        {
                            id: 1,
                            insurerName: "",
                            contractOrPolicyNumber: "",
                            nameOfInsuredOrAnnuitant: "",
                            replacedOrFinancing: "",
                            effectiveDate: ""
                        },
                        {
                            id: 2,
                            insurerName: "",
                            contractOrPolicyNumber: "",
                            nameOfInsuredOrAnnuitant: "",
                            replacedOrFinancing: "",
                            effectiveDate: ""
                        },
                        {
                            id: 3,
                            insurerName: "",
                            contractOrPolicyNumber: "",
                            nameOfInsuredOrAnnuitant: "",
                            replacedOrFinancing: "",
                            effectiveDate: ""
                        },
                        {
                            id: 4,
                            insurerName: "",
                            contractOrPolicyNumber: "",
                            nameOfInsuredOrAnnuitant: "",
                            replacedOrFinancing: "",
                            effectiveDate: ""
                        },
                    ],
                    reasonForReplacement: ""
                });
            }
        }
    }, [
        stateToShowNoticeOfReplacement,
        flagQualifiedOrNonQualifiedSelected,
        selectedNonQualifiedOptions,
        selectedQualifiedOptionsGroup2,
        ownerState
    ]);

    useEffect(() => {
        if (state.transferOrExchangeRequestFlag === false) {
            SetWholeTransferExchangeRequest({
                ownerFullName: "",
                ownerSSN: "",
                ownerStreetAddress: "",
                ownerZip: "",
                ownerCity: "",
                ownerState: "",
                ownerMaritalStatus: "",
                annuitantOrInsured: "",
                currentInsuranceCompany: "",
                currentInsuranceCompanyPhone: "",
                currentInsuranceCompanyAccountNumber: "",
                currentInsuranceCompanyStreetAddress: "",
                currentInsuranceCompanyZip: "",
                currentInsuranceCompanyCity: "",
                currentInsuranceCompanyState: "",
                typeOfAccount: "",
                otherTypeOfAccount: "",
                ownerExistingAnnuity: "",
                plans: "",
                flagNonQualifiedOwnerWantWithdrawalToExchange: "",
                explainNonQualifiedOwnerWantWithdrawalToExchange: "",
                flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal: "",
                flagSignatureDigitalOrPhysical: "",
                qualifiedTransferOrExchangeExistingAccountAllOrPartial: "",
                qualifiedTransferOrExchangeExistingAccountPartialAmount: "",
                qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate:
                    "",
                qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField: "",
                qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy:
                    "",
                qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField:
                    "",
                qualifiedTypeOfTransfer: "",
                qualifiedQualifyingEventForDirectRolloverFunds: "",
                flagQualifiedOwnerReachedAge72OrMoreInThisYear: "",
                flagQualifiedOwnerSatisfiedRMDFromDistributingPlan: "",
                qualifiedInternalExchangeOfExistingAnnuityName: "",
                flagQualifiedOwnerWantRMDBeforeCompletingExchange: "",
                flagQualifiedOwnerWantToContinueExistingRMDSchedule: "",
            });
        }
    }, [state.transferOrExchangeRequestFlag]);

    const tempInsurerPolicyDetailFieldArray = () => {

        let temp = [];
        insurerPolicyDetailFieldArray.map((v, i) => {
            if (window.location.search.includes("id") === false) {
                temp.push({
                    nor_existing_policy_insurer_name: v.existingCarrierName ? v.existingCarrierName : "",
                    nor_existing_policy_insurer_name_address: v.existingCarrierAddress ? v.existingCarrierAddress : "",
                    nor_existing_policy_contract_number: v.contractOrPolicyNumber,
                    nor_existing_policy_name_of_insured: v.nameOfInsuredOrAnnuitant,
                    nor_existing_policy_face_amount: v.amount ? v.amount : "",
                    nor_existing_policy_effective_date:
                        v.effectiveDate ?
                            new Date(v.effectiveDate).toDateString() === "Invalid Date"
                                ? ""
                                : `${new Date(v.effectiveDate).getMonth() + 1}/${new Date(
                                    v.effectiveDate
                                ).getDate()}/${new Date(v.effectiveDate).getFullYear()}`
                            : ""
                });
            } else {
                temp.push({
                    existing_policy_id: v.id,
                    nor_existing_policy_insurer_name: v.existingCarrierName ? v.existingCarrierName : "",
                    nor_existing_policy_insurer_name_address: v.existingCarrierAddress ? v.existingCarrierAddress : "",
                    nor_existing_policy_contract_number: v.contractOrPolicyNumber,
                    nor_existing_policy_name_of_insured: v.nameOfInsuredOrAnnuitant,
                    nor_existing_policy_face_amount: v.amount ? v.amount : "",
                    nor_existing_policy_effective_date:
                        v.effectiveDate ?
                            new Date(v.effectiveDate).toDateString() === "Invalid Date"
                                ? ""
                                : `${new Date(v.effectiveDate).getMonth() + 1}/${new Date(v.effectiveDate).getDate()}/${new Date(v.effectiveDate).getFullYear()}`
                            : ""
                });
            }
        });
        return temp;
    };

    const data = {
        main_form_id: 2,
        fia_section1_owner_name: ownerFullName,
        fia_section1_owner_street_address: ownerStreetAddress,
        fia_section1_owner_city: ownerCity,
        fia_section1_owner_state: ownerState,
        fia_section1_owner_zip: ownerZip,
        fia_section1_owner_email: ownerEmail,
        fia_section1_owner_phone: GetPhoneNumberPayloadFormate(ownerPhone),
        fia_section1_owner_ssn: ownerSSN,
        fia_section1_owner_relationship_to_annuitant: ownerRelationToAnnuitant,
        fia_section1_owner_dob: new Date(ownerDOB).toDateString() === "Invalid Date" ? ""
            : `${new Date(ownerDOB).getMonth() + 1}/${new Date(
                ownerDOB
            ).getDate()}/${new Date(ownerDOB).getFullYear()}`,
        fia_section1_owner_gender: {
            value: ownerGender,
            extra_value: "",
        },

        fia_section1_join_owner_name: jointOwnerFullName,
        fia_section1_join_owner_street_address: jointOwnerStreetAddress,
        fia_section1_join_owner_city: jointOwnerCity,
        fia_section1_join_owner_state: jointOwnerState,
        fia_section1_join_owner_zip: jointOwnerZip,
        fia_section1_join_owner_email: jointOwnerEmail,
        fia_section1_join_owner_phone: GetPhoneNumberPayloadFormate(jointOwnerPhone),
        fia_section1_join_owner_ssn: jointOwnerSSN,
        fia_section1_join_owner_relationship_to_owner: "Spouse",
        fia_section1_join_owner_dob:
            new Date(jointOwnerDOB).toDateString() === "Invalid Date"
                ? ""
                : `${new Date(jointOwnerDOB).getMonth() + 1}/${new Date(
                    jointOwnerDOB
                ).getDate()}/${new Date(jointOwnerDOB).getFullYear()}`,
        fia_section1_join_owner_gender: {
            value: jointOwnerGender,
            extra_value: ""
        },

        fia_section2_annuitant_name: annuitantFullName,
        fia_section2_annuitant_street_address: annuitantStreetAddress,
        fia_section2_annuitant_city: annuitantCity,
        fia_section2_annuitant_state: annuitantState,
        fia_section2_annuitant_zip: annuitantZip,
        fia_section2_annuitant_email: annuitantEmail,
        fia_section2_annuitant_phone: GetPhoneNumberPayloadFormate(annuitantPhone),
        fia_section2_annuitant_ssn: annuitantSSN,
        fia_section2_annuitant_dob: new Date(annuitantDOB).toDateString() === "Invalid Date"
            ? ""
            : `${new Date(annuitantDOB).getMonth() + 1}/${new Date(
                annuitantDOB
            ).getDate()}/${new Date(annuitantDOB).getFullYear()}`,
        fia_section2_annuitant_gender: {
            value: annuitantGender,
            extra_value: ""
        },
        fia_section2_annuitant_relationship_to_owner: annuitantRelationToOwner,

        fia_section2_join_annuitant_name: jointAnnuitantFullName,
        fia_section2_join_annuitant_street_address: jointAnnuitantStreetAddress,
        fia_section2_join_annuitant_city: jointAnnuitantCity,
        fia_section2_join_annuitant_state: jointAnnuitantState,
        fia_section2_join_annuitant_zip: jointAnnuitantZip,
        fia_section2_join_annuitant_email: jointAnnuitantEmail,
        fia_section2_join_annuitant_phone: GetPhoneNumberPayloadFormate(jointAnnuitantPhone),
        fia_section2_join_annuitant_ssn: jointAnnuitantSSN,
        fia_section2_join_annuitant_dob:
            new Date(jointAnnuitantDOB).toDateString() === "Invalid Date"
                ? ""
                : `${new Date(jointAnnuitantDOB).getMonth() + 1}/${new Date(
                    jointAnnuitantDOB
                ).getDate()}/${new Date(jointAnnuitantDOB).getFullYear()}`,

        fia_section2_join_annuitant_gender: {
            value: jointAnnuitantGender,
            extra_value: ""
        },
        fia_section2_join_annuitant_relationship_to_annuitant: jointAnnuitantRelationToAnnuitant,

        fia_section3_plan_harvest: {
            value: planHarvest,
            extra_value: ""
        },
        fia_section3_riders_plan: {
            value: ridersPlan,
            extra_value: ""
        },

        fia_section4_initial_account_allocation_0_fixed_strategy:
            initialAccountAllocation.fixedStrategy,
        fia_section4_initial_account_allocation_1_sp_500_cap:
            initialAccountAllocation.pointWithCap,
        fia_section4_initial_account_allocation_2_sp_500_enhanced_cap:
            initialAccountAllocation.pointWithEnhancedCap,
        fia_section4_initial_account_allocation_3_sp_500_participation_rate:
            initialAccountAllocation.pointWithParticipationRate,
        fia_section4_initial_account_allocation_4_sp_500_enhanced_participation_rate:
            initialAccountAllocation.pointWithEnhancedParticipationRate,
        fia_section4_initial_account_allocation_5_sp_divident_growers_participation_rate:
            initialAccountAllocation.dividendGrowersParticipationRate,
        fia_section4_initial_account_allocation_6_sp_divident_growers_enhanced_participation_rate:
            initialAccountAllocation.dividendGrowersEnhancedParticipationRate,
        fia_section4_initial_account_allocation_7_nasdaq_100_participation_rate:
            initialAccountAllocation.nasdaqParticipationRate,
        fia_section4_initial_account_allocation_8_nasdaq_100_enhanced_participation_rate:
            initialAccountAllocation.nasdaqEnhancedParticipationRate,
        fia_section4_initial_account_allocation_9_bloomberg_global_participation_rate:
            initialAccountAllocation.bloombergGlobalParticipationRate,
        fia_section4_initial_account_allocation_10_bloomberg_global_enhanced_participation_rate:
            initialAccountAllocation.bloombergGlobalEnhancedParticipationRate,

        beneficiaries: tempBeneficiaries(),

        fia_section7_premium_tax_qualification_status: {
            value: flagQualifiedOrNonQualifiedSelected,
            extra_value: ""
        },


        fia_section7_premium_non_qualified: {
            value: flagQualifiedOrNonQualifiedSelected == "non - qualified" ? selectedNonQualifiedOptions?.join(",") : "",
            extra_value: ""
        },
        fia_section7_premium_qualified: {
            value: flagQualifiedOrNonQualifiedSelected == "qualified" ? selectedNonQualifiedOptions?.join(",") : "",
            extra_value: "",
        },
        fia_section7_type_of_plan: {
            value: selectedQualifiedOptionsGroup2,
            extra_value: otherSelectedQualifiedOptions
        },

        fia_state_to_show_notice_of_replacement: stateToShowNoticeOfReplacement,
        fia_section7_amount_paid: amountPaidWithApplication?.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        fia_section7_premium_amount: expectedPremiumField?.replace(/\B(?=(\d{3})+(?!\d))/g, ","),


        fia_insurance_producer_declaration_question_a_producer: {
            value: producerStateA,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_a_applicant: {
            value: applicantStateA,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_b_producer: {
            value: producerStateB,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_b_applicant: {
            value: applicantStateB,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_c_producer: {
            value: producerStateC,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_c_applicant: {
            value: applicantStateC,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_d_producer: {
            value: producerStateD,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_d_applicant: {
            value: applicantStateD,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_e_producer: {
            value: producerStateE,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_e_applicant: {
            value: applicantStateE,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_f_producer: {
            value: producerStateF,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_f_applicant: {
            value: applicantStateF,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_g_producer: {
            value: producerStateG,
            extra_value: ""
        },
        fia_insurance_producer_declaration_question_g_applicant: {
            value: applicantStateG,
            extra_value: ""
        },


        suitability_analysis_section1_owner_full_name: suitabilityAnalysis?.ownerFullName,
        suitability_analysis_section1_owner_age: ownerCurrentAge,
        suitability_analysis_section1_owner_gender: {
            value: suitabilityAnalysis?.ownerGender,
            extra_value: ""
        },
        suitability_analysis_section1_owner_emp_status: {
            value: ownerEmploymentStatus,
            extra_value: ""
        },
        suitability_analysis_section1_owner_occupation: ownerCurrentOccupation,
        suitability_analysis_section1_owner_depedents: ownerDependents,

        suitability_analysis_section1_spouse_full_name: spouseFullName,
        suitability_analysis_section1_spouse_age: spouseCurrentAge,
        suitability_analysis_section1_spouse_gender: { value: spouseGender, extra_value: "" },
        suitability_analysis_section1_spouse_emp_status: { value: spouseEmploymentStatus, extra_value: "" },
        suitability_analysis_section1_spouse_occupation: spouseCurrentOccupation,
        suitability_analysis_section1_spouse_depedents: spouseDependents,

        suitability_analysis_section2_annual_income: ownerAnnualHouseholdIncome.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        suitability_analysis_section2_source_income: ownerSourceOfIncome,
        suitability_analysis_section2_income_tax: ownerIncomeTaxBracket,
        suitability_analysis_section2_total_assets: ownerTotalAssets.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        suitability_analysis_section2_total_liquid_assets: ownerTotalLiquidAssets.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        suitability_analysis_section2_total_outstanding_debt: ownerTotalOutstandingDebt.replace(/\B(?=(\d{3})+(?!\d))/g, ","),

        suitability_analysis_section3_q1_medical_expenses: {
            value: flagOwnerIncomeCoverLivingAndMedicalExpenses,
            extra_value: explainOwnerIncomeCoverLivingAndMedicalExpenses,
        },
        suitability_analysis_section3_q2_living_expenses: {
            value: flagOwnerExpectChangesInLivingExpenses,
            extra_value: explainOwnerExpectChangesInLivingExpenses,
        },
        suitability_analysis_section3_q3_pocket_expenses: {
            value: flagOwnerAnticipateChangesInMedicalExpenses,
            extra_value: explainOwnerAnticipateChangesInMedicalExpenses,
        },
        suitability_analysis_section3_q4_surrender_charge_expenses: {
            value:
                flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
            extra_value:
                explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
        },
        suitability_analysis_section3_q5_emergency_fund: {
            value: flagOwnerHaveEmergencyFundForUnexpectedExpenses,
            extra_value: explainOwnerHaveEmergencyFundForUnexpectedExpenses,
        },
        suitability_analysis_section3_q6_own_investment:
        {
            value: ownerOtherInvestments.length > 0 ? ownerOtherInvestments?.join(",") : "",
            extra_value: otherOwnerInvestmentField,
        },
        suitability_analysis_section3_q7_risk_tolerance: {
            value: ownerRiskTolerance,
            extra_value: ""
        },
        suitability_analysis_section4_q_reason_for_purchase_product: {
            value:
                reasonForPurchasingProduct.length > 0
                    ? reasonForPurchasingProduct.join(",")
                    : "",
            extra_value: otherRasonForPurchasingThisProduct,
        },
        suitability_analysis_section4_q1_surrender_charge_period: {
            value: flagOwnerPlanToWithdrawFundInSurrenderPeriod,
            extra_value: explainOwnerPlanToWithdrawFundInSurrenderPeriod,
        },
        suitability_analysis_section4_q2_anticipate_need: {
            value: ownerAnticipateNeedingAccessToFunds,
            extra_value: ""
        },
        suitability_analysis_section4_q3_source_of_fund_purchase_product:
        {
            value: sourceOfFundsToPurchaseThisProduct.length > 0 ? sourceOfFundsToPurchaseThisProduct.join(",") : "",
            extra_value: otherSourceOfFundsToPurchaseThisProduct,
        },
        suitability_analysis_section5_q1_existing_account_information: {
            value: flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct,
            extra_value: ""
        },

        suitability_analysis_section5_q2_life_insurance_or_annuity_force_year: howLongHasTheLifeInsuranceBeenInForce,
        suitability_analysis_section5_q3_surrender_charge_product: {
            value: flagSurrenderChargeOnExistingProduct,
            extra_value: currentSurrenderChargeOnExistingProduct + "," + expirationOfCurrentSurrenderCharge
        },
        suitability_analysis_section5_q4_type_of_existed_product: {
            value: typeOfExistingProduct,
            extra_value: ""
        },
        suitability_analysis_section5_q5_interest_rate_existing_product: interestRateForExistingProduct,
        suitability_analysis_section5_q6_tax_qualified_annuity:
        {
            value: flagTakingRequiredMinimumDistributionsFromExistingProduct,
            extra_value: ""
        },
        suitability_analysis_section5_q7_tax_periodic_distributions: {
            value: flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
            extra_value: explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
        },
        suitability_analysis_section6_assist_purchase_product:
            personsThatAssistedOwnerToPurchaseThisProduct.length > 0
                ? {
                    value: personsThatAssistedOwnerToPurchaseThisProduct.join(","),
                    extra_value: otherPersonsThatAssistedOwnerToPurchaseThisProduct,
                }
                : {
                    value: "",
                    extra_value: "",
                },

        suitability_analysis_section6_relevant_information:
            additionalRelaventInformationForSuitabilityAnalysis,
        suitability_analysis_section7_agent_acknowledgement: {
            value: suitabilityAnalysis?.agentAcknowledgment,
            extra_value: ""
        },
        nor_question1a_terminating_your_existing_policy: {
            value: flagDiscontinuingPremiumPayment,
            extra_value: ""
        },
        nor_question1b_fund_from_new_policy: {
            value: flagPayForPremiumPolicy,
            extra_value: ""
        },
        nor_question1d_reason_for_replacement: reasonForReplacement,
        existing_policy: tempInsurerPolicyDetailFieldArray(),
    };

    const AddDisclosureData = (finalData) => {
        if (fiaDisclosureVersion == 10) {
            finalData["harvest_v2_plus_disclosure_annuity"] = {
                value: flagAnnuityClosureV2,
                extra_value: ""
            }
            finalData["harvest_v2_plus_disclosure_backup_withholding"] = {
                value: flagAnnuityClosureBackupWithout,
                extra_value: ""
            }
        } else if (fiaDisclosureVersion == 8) {
            finalData["harvest_plus_disclosure_annuity"] = {
                value: flagHarvestClosure,
                extra_value: ""
            }
        } else {
            finalData["safeguard_plus_disclosure_annuity"] = {
                value: flagAnnuityClosure,
                extra_value: ""
            }
        }
        return finalData;
    }

    const AddTransferExchangeData = (finalData) => {
        if (transferExchangeVersion === 7) {
            finalData["transfer_v2_section1_company_name"] = rolloverTransferExchange.transferV2Section1CompanyName
            finalData["transfer_v2_section1_company_phone"] = rolloverTransferExchange.transferV2Section1CompanyPhone
            finalData["transfer_v2_section1_company_policy_number"] = rolloverTransferExchange.transferV2Section1CompanyPolicyNumber
            finalData["transfer_v2_section1_company_address"] = rolloverTransferExchange.transferV2Section1CompanyAddress
            finalData["transfer_v2_section1_company_city"] = rolloverTransferExchange.transferV2Section1CompanyCity
            finalData["transfer_v2_section1_company_state"] = rolloverTransferExchange.transferV2Section1CompanyState
            finalData["transfer_v2_section1_company_zip"] = rolloverTransferExchange.transferV2Section1CompanyZip
            finalData["transfer_v2_section1_owner_name"] = rolloverTransferExchange.transferV2Section1OwnerName
            finalData["transfer_v2_section1_owner_ssn"] = rolloverTransferExchange.transferV2Section1OwnerSsn
            finalData["transfer_v2_section1_join_owner_name"] = rolloverTransferExchange.transferV2Section1JoinOwnerName
            finalData["transfer_v2_section1_join_owner_ssn"] = rolloverTransferExchange.transferV2Section1JoinOwnerSsn
            finalData["transfer_v2_section1_annuitant_name"] = rolloverTransferExchange.transferV2Section1AnnuitantName
            finalData["transfer_v2_section1_annuitant_ssn"] = rolloverTransferExchange.transferV2Section1AnnuitantSsn
            finalData["transfer_v2_section1_join_annuitant_name"] = rolloverTransferExchange.transferV2Section1JoinAnnuitantName
            finalData["transfer_v2_section1_join_annuitant_ssn"] = rolloverTransferExchange.transferV2Section1JoinAnnuitantSsn
            finalData["transfer_v2_section1_beneficiary_name"] = rolloverTransferExchange.transferV2Section1BeneficiaryName
            finalData["transfer_v2_section1_beneficiary_ssn"] = rolloverTransferExchange.transferV2Section1BeneficiarySsn
            finalData["transfer_v2_section1_investment_vehicle"] = {
                value: rolloverTransferExchange.transferV2Section1InvestmentVehicle,
                extra_value: rolloverTransferExchange.transferV2Section1InvestmentVehicleOther
            }

            finalData["transfer_v2_section1_existing_certificate"] = {
                value: rolloverTransferExchange.transferV2Section1ExistingCertificate,
                extra_value: ""
            }
            finalData["transfer_v2_section2_plan"] = rolloverTransferExchange.transferV2Section2Plan
            finalData["transfer_v2_section2_non_qualified_1035_exchange"] = {
                value: rolloverTransferExchange.transferV2Section2NonQualified1035Exchange,
                extra_value: rolloverTransferExchange.transferV2Section2NonQualified1035ExchangeOtherPercentage + "," + rolloverTransferExchange.transferV2Section2NonQualified1035ExchangeOtherAmount?.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                )
            }

            finalData["transfer_v2_section2_qualified_age_rmd_transfer"] = {
                value: rolloverTransferExchange.transferV2Section2QualifiedAgeRmdTransfer,
                extra_value: ""
            }
            finalData["transfer_v2_section2_qualified_type_of_account"] = {
                value: rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccount,
                extra_value: rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccountOther
            }

            finalData["transfer_v2_section2_qualified_liquidate_transfer_all"] = {
                value: rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer == "all" ?
                    rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer : "",
                extra_value: ""
            }

            finalData["transfer_v2_section2_qualified_liquidate_transfer_partial_amount"] = {
                value: rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferAmount?.length ? "partial amount" : "",
                extra_value: rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferAmount?.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                )
            }
            finalData["transfer_v2_section2_qualified_liquidate_transfer_partial_percentage"] = {
                value: rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferPercentage?.length ? "partial percentage" : "",
                extra_value: rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferPercentage
            }

            finalData["transfer_v2_section2_qualified_withdraw_funds"] = {
                value: rolloverTransferExchange.transferV2Section2QualifiedWithdrawFunds,
                extra_value: rolloverTransferExchange.transferV2Section2QualifiedWithdrawFundsOther
            }

            finalData["transfer_v2_section2_qualified_funds_transfered"] = {
                value: rolloverTransferExchange.transferV2Section2QualifiedFundsTransfered,
                extra_value: ""
            }

        } else {
            finalData["transfer_section1_item_owner_full_name"] = transferOrExchangeRequest.ownerFullName;
            finalData["transfer_section1_item_owner_address"] = transferOrExchangeRequest.ownerStreetAddress;
            finalData["transfer_section1_item_owner_city"] = transferOrExchangeRequest.ownerCity;
            finalData["transfer_section1_item_owner_state"] = transferOrExchangeRequest.ownerState;
            finalData["transfer_section1_item_owner_marital_status"] = transferOrExchangeRequest.ownerMaritalStatus;
            finalData["transfer_section1_item_owner_zip"] = transferOrExchangeRequest.ownerZip;
            finalData["transfer_section1_item_owner_annuitant"] = annuitantOrInsured;
            finalData["transfer_section1_item_owner_ssn_or_tax_id"] = transferOrExchangeRequest.ownerSSN;
            finalData["transfer_section1_item_owner_current_insurance_company"] = currentInsuranceCompany;
            finalData["transfer_section1_item_owner_phone"] = currentInsuranceCompanyPhone;

            finalData["transfer_section1_item_annuitant_account_number"] = currentInsuranceCompanyAccountNumber;
            finalData["transfer_section1_item_annuitant_address"] = currentInsuranceCompanyStreetAddress;
            finalData["transfer_section1_item_annuitant_city"] = currentInsuranceCompanyCity;
            finalData["transfer_section1_item_annuitant_state"] = currentInsuranceCompanyState;
            finalData["transfer_section1_item_annuitant_zip"] = currentInsuranceCompanyZip;
            finalData["transfer_section1_type_of_account"] = {
                value: typeOfAccount,
                extra_value: otherTypeOfAccount
            };
            finalData["transfer_section1_existing_contract_or_certificate"] = {
                value: ownerExistingAnnuity,
                extra_value: ""
            }

            finalData["transfer_section2_plan_existing_account_requested_question_1"] =
            {
                value: qualifiedTransferOrExchangeExistingAccountAllOrPartial,
                extra_value: qualifiedTransferOrExchangeExistingAccountPartialAmount
            }

            finalData["transfer_section2_plan_existing_account_requested_question_2"] =
            {
                value: qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate,
                extra_value: qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField
            }
            finalData["transfer_section2_plan_existing_account_requested_question_3"] =
            {
                value: qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy,
                extra_value: qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField
            }

            finalData["transfer_section2_plans_transfer_or_rollover"] = {
                value: qualifiedTypeOfTransfer,
                extra_value: ""
            }
            finalData["transfer_section2_direct_rollover_fund"] = {
                value: qualifiedQualifyingEventForDirectRolloverFunds,
                extra_value: ""
            }

            finalData["transfer_section2_qualified_plan_rmd_reach_age_72"] = {
                value: flagQualifiedOwnerReachedAge72OrMoreInThisYear,
                extra_value: ""
            }
            finalData["transfer_section2_qualified_plan_rmd_satisfied_distributing_plan"] = {
                value: flagQualifiedOwnerSatisfiedRMDFromDistributingPlan,
                extra_value: ""
            }
            finalData["transfer_section2_qualified_plan_internal_exchnage_annuity"] = qualifiedInternalExchangeOfExistingAnnuityName
            finalData["transfer_section2_qualified_plan_get_rmd_before_completing"] = {
                value: flagQualifiedOwnerWantRMDBeforeCompletingExchange,
                extra_value: ""
            }
            finalData["transfer_section2_qualified_plan_continue_exiting_rmd_schedule"] = {
                value: flagQualifiedOwnerWantToContinueExistingRMDSchedule,
                extra_value: ""
            }

            // Non-qualified 
            finalData["transfer_section2_non_qualified_plan_prior_exchange"] = {
                value: flagNonQualifiedOwnerWantWithdrawalToExchange,
                extra_value: explainNonQualifiedOwnerWantWithdrawalToExchange
            }
            finalData["transfer_section2_non_qualified_plan_continue_exiting_rmd_schedule"] = {
                value: flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal,
                extra_value: ""
            }
            finalData["trans_exchange_req_signature_digital_or_physical"] = {
                value: flagSignatureDigitalOrPhysical,
                extra_value: ""
            }


        }
        return finalData;
    }
    const AddProducerDisclosureData = (finalData) => {
        if (flagShowProducerDisclosure) {
            finalData["producer_disclosure_section1_first_name"] = producerDisclosureForm.producerFirstName
            finalData["producer_disclosure_section1_last_name"] = producerDisclosureForm.producerLastName
            finalData["producer_disclosure_section1_bussiness_name"] = producerDisclosureForm.producerBusinessName
            finalData["producer_disclosure_section1_website"] = producerDisclosureForm.producerWebsite
            finalData["producer_disclosure_section1_bussiness_mailing_address"] = producerDisclosureForm.producerBusinessMailingAddress
            finalData["producer_disclosure_section1_city"] = producerDisclosureForm.producerCity
            finalData["producer_disclosure_section1_state"] = producerDisclosureForm.producerState
            finalData["producer_disclosure_section1_zip"] = producerDisclosureForm.producerZip
            finalData["producer_disclosure_section1_bussiness_telephone_number"] = producerDisclosureForm.producerTelephone
            finalData["producer_disclosure_section1_email"] = producerDisclosureForm.producerEmail
            finalData["producer_disclosure_section1_national_producer_name"] = producerDisclosureForm.producerNationalNumber
            finalData["producer_disclosure_section1_state_of"] = producerDisclosureForm.producerInStateOf
            finalData["producer_disclosure_section2_first_name"] = producerDisclosureForm.producerCustomerFirstName
            finalData["producer_disclosure_section2_last_name"] = producerDisclosureForm.producerCustomerLastName
            finalData["producer_disclosure_section3_offered_products"] = {
                value: producerDisclosureForm.producerOfferedProducts.length > 0 ? producerDisclosureForm.producerOfferedProducts.join(',') : "",
                extra_value: ""
            }
            finalData['producer_disclosure_section3_non_insurance_products'] = {
                value: producerDisclosureForm.producerNonInsuranceProducts.length > 0 ? producerDisclosureForm.producerNonInsuranceProducts.join(',') : "",
                extra_value: ""
            }
            finalData['producer_disclosure_section4_annuities_authorize_to_sell'] = {
                value: producerDisclosureForm.producerAuthorizedToSell.length > 0 ? producerDisclosureForm.producerAuthorizedToSell.join(',') : "",
                extra_value: producerDisclosureForm.producerAuthorizedToSellDescription
            }
            finalData['producer_disclosure_section4_cash_compensations'] = {
                value: producerDisclosureForm.producerPaidForWork.length > 0 ? (producerDisclosureForm.producerPaidForWork.filter((value) => value == "commission" || value == "fees").length == 1 ? producerDisclosureForm.producerPaidForWork.filter((value) => value == "commission" || value == "fees") + "," : producerDisclosureForm.producerPaidForWork.filter((value) => value == "commission" || value == "fees").join(",")) : "",
                extra_value: producerDisclosureForm.producerPaidForWorkCommissionDescription
            }
            finalData['producer_disclosure_section4_cash_compensations_other'] = {
                value: producerDisclosureForm.producerPaidForWork.length > 0 ? (producerDisclosureForm.producerPaidForWork.includes('other') ? "other" : "") : "",
                extra_value: producerDisclosureForm.producerPaidForWorkOtherDescription
            }
        }
        return finalData;
    }
    const SendDataToServer = (type, redirect) => {
        SetFlagLoader(true);
        let temp = { ...data };
        temp = AddTransferExchangeData(temp)
        temp = AddDisclosureData(temp)
        temp = AddProducerDisclosureData(temp)

        if (window.location.search.includes("id") === true) {
            temp["application_status_id"] = type === "draft"
                ? saveAsDraftApplicationStatusId.id
                : submitApplicationStatusId.id;
        } else {
            temp["application_status_id"] = type === "draft" ? 1 : 2;
        }

        if (applicationId) {
            temp["applications_id"] = applicationId;
        }
        SubmitSaveDraftFiaApplication(
            temp,
            (res) => {
                SetFlagLoader(false);
                let domain = window.location.host;
                let protocol = window.location.protocol;

                if (res.data.status === true) {
                    if (type !== "draft") {
                        setFlagSuccessDialog(true);
                    } else {
                        SetApplicationId(res.data.data.applications_id);
                        if (redirect === true) {
                            window.location.replace(
                                `${protocol}//${domain}/fia_application_history`
                            );
                        } else {
                            let data = res.data;
                            return data;
                        }
                    }
                } else {
                    setStatusFalse(true);
                }
            },
            (err) => {
                SetFlagLoader(false);
            }
        );
    };

    const SubmitOnClick = () => {
        const res = Validation();
        if (res.status === false && res.data.length > 0) {
            setFlagErrorValidationDialog({
                status: true,
                tableData: res.data,
            });
        }
        else {
            if (user?.user_phone_is_verified) {
                SendDataToServer("submit", true);
            } else {
                setFlagVerifyPhonePopup(true)
            }
        }
    }

    return (
        <Fragment>
            {flagVerifyPhonePopup && <VerifyPhoneDialog setFlagVerifyPhonePopup={setFlagVerifyPhonePopup} SendDataToServer={SendDataToServer} />}
            {flagSuccessDialog === true && (
                <SuccessDdialog
                    firstText="Application Submitted Successfully!"
                    secondText="DocuSign request to sign the envelop will be sent to applicant in 5 minutes."
                    redirect="/fia_application_history"
                    closeSuccessDialog={() => {
                        setFlagSuccessDialog(false);
                        let domain = window.location.host;
                        let protocol = window.location.protocol;
                        window.location.replace(`${protocol}//${domain}/fia_application_history`);
                    }}
                />
            )}
            {statusFalse === true && <SomethingWentWrongDialog popupState={statusFalse} setClosePopupState={setStatusFalse} />}
            {flagErrorValidtionDialog.status === true && (
                <ErrorValidationSubmitDialog
                    tableData={flagErrorValidtionDialog.tableData}
                    closeDialog={() => {
                        setFlagErrorValidationDialog(false);
                    }}
                />
            )}
            <Box className="new_application_form">
                <Box className="page_title">
                    <Typography
                        variant=""
                        style={{ marginBottom: "0px" }}
                        paragraph={true}
                    >
                        Application Form
                    </Typography>
                </Box>
                <Box className="application_form_main">
                    {applicationStatusName === "Submitted for Signature" && (
                        <Button
                            className="editable_button"
                            sx={{
                                position: "absolute",
                                top: "calc(100px + 55px + 25px)",
                                right: "25%",
                                color: "#fff",
                                background: "#074a6e",
                                minWidth: "160px",
                                textTransform: "capitalize",
                                zInex: 1,
                            }}
                            onClick={() => {
                                if (applicationEditable === true) {
                                    window.location.reload();
                                }
                                if (applicationEditable === false) {
                                    SetPageIndex(1);
                                }
                                setApplicationEditable(!applicationEditable);
                            }}
                        >
                            {applicationEditable === true
                                ? "View Application"
                                : "Edit Application"}
                        </Button>
                    )}
                    {pageIndex === 1 && (
                        <FixedIndexAnnuityApplication
                            changePageIndex={(pagenumber) => {
                                SetPageIndex(pageIndex + pagenumber);
                            }}
                            beneficiariesPercentageTotal={(value) => {
                                setBeneficiariesPercentageTotal(value);
                            }}
                            errorFields={errorFieldsArray}
                        />
                    )}

                    {pageIndex === 3 && (
                        <SuitabilityAnalysis
                            changePageIndex={(pagenumber) => {
                                SetPageIndex(pageIndex - pagenumber);
                            }}
                            nextPage={() => {
                                SetPageIndex(pageIndex + 1);
                            }}
                            eftPage={() => {
                                SetPageIndex(pageIndex + 3);
                            }}
                            errorFields={errorFieldsArray}
                        />
                    )}

                    {pageIndex === 2 && (
                        <ReplacementForm
                            previousPage={() => {
                                SetPageIndex(pageIndex - 1);
                            }}
                            nextPage={() => {
                                SetPageIndex(pageIndex + 1);
                            }}
                            errorFields={errorFieldsArray}
                        />
                    )}
                    {pageIndex === 4 && (
                        <div className="individual_form">
                            {transferExchangeVersion === 7 ?
                                <RolloverTransferExchangeRequest
                                    previousPage={() => {
                                        SetPageIndex(pageIndex - 1);
                                    }}
                                    nextPage={() => {
                                        SetPageIndex(pageIndex + 2);
                                    }}
                                    errorFields={errorFieldsArray}
                                />
                                :
                                <TransferExchangeRequest
                                    previousPage={() => {
                                        SetPageIndex(pageIndex - 1);
                                    }}
                                    nextPage={() => {
                                        SetPageIndex(pageIndex + 2);
                                    }}
                                    errorFields={errorFieldsArray}
                                />
                            }
                        </div>
                    )}
                    {pageIndex === 6 && (
                        fiaDisclosureVersion == 8
                            ?
                            <HarvestDisclosureForm
                                suitabilityAnalysisPage={() => {
                                    SetPageIndex(pageIndex - 3);
                                }}
                                previousPage={() => {
                                    SetPageIndex(pageIndex - 2);
                                }}
                                nextPage={() => {
                                    SetPageIndex((pageIndex + 1))
                                }}
                                errorFields={errorFieldsArray}
                            />
                            : fiaDisclosureVersion == 10 ?
                                <AnnuityDisclosureFormV2
                                    suitabilityAnalysisPage={() => {
                                        SetPageIndex(pageIndex - 3);
                                    }}
                                    previousPage={() => {
                                        SetPageIndex(pageIndex - 2);
                                    }}
                                    nextPage={() => {
                                        SetPageIndex((pageIndex + 1))
                                    }}
                                    errorFields={errorFieldsArray}
                                />
                                : <AnnuityDisclosureForm
                                    suitabilityAnalysisPage={() => {
                                        SetPageIndex(pageIndex - 3);
                                    }}
                                    previousPage={() => {
                                        SetPageIndex(pageIndex - 2);
                                    }}
                                    nextPage={() => {
                                        SetPageIndex((pageIndex + 1))
                                    }}
                                    errorFields={errorFieldsArray}
                                />

                    )}
                    {(pageIndex === 7 && flagShowProducerDisclosure) && (
                        <div className="individual_form">
                            <ProducerDisclosureForm
                                previousPage={() => {
                                    SetPageIndex(pageIndex - 1);
                                }}

                                errorFields={errorFieldsArray}
                            />
                        </div>
                    )}
                    <div className="formsitemap">
                        <FixedIndexSiteMap
                            pageIndex={pageIndex}
                            setPage={(dataid) => {
                                SetPageIndex(dataid);
                            }}
                            applicationId={applicationId}
                            saveAsDraft={async (onGetData) => {
                                let res = await SendDataToServer("draft", false);
                            }}
                        />
                    </div>
                </Box>
                {(applicationEditable === true
                    ? true
                    : applicationStatusName !== "Submitted for Signature"
                        ? true
                        : false) && (
                        <Box
                            className="btn_group"
                            style={{
                                margin: "10px 0",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    SubmitOnClick()

                                }}
                                style={{
                                    background: "#074a6e",
                                    color: "white",
                                    textTransform: "capitalize",
                                    margin: "0 10px",
                                }}
                            >
                                {applicationEditable === true &&
                                    applicationStatusName === "Submitted for Signature"
                                    ? "Re-Submit"
                                    : "Submit"}
                            </Button>
                            {applicationEditable === true ? null : (
                                <Button
                                    onClick={() => {
                                        SendDataToServer("draft", true);
                                    }}
                                    style={{
                                        background: "#074a6e",
                                        color: "white",
                                        textTransform: "capitalize",
                                        margin: "0 10px",
                                    }}
                                >
                                    Save As Draft
                                </Button>
                            )}
                        </Box>
                    )}
            </Box>
        </Fragment>
    );
};

export default FiaApplication;
